import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import _ from 'lodash'
import type {
  ReportRolesBySchoolQuery,
  ReportRolesBySchoolQueryVariables,
} from '@graphql-hooks'
import { useUpdateReportRoleSetMutation as gqlUseUpdateReportRoleSetMutation } from '@graphql-hooks'

export type QueryReportSet = NonNullable<
  NonNullable<ReportRolesBySchoolQuery['reports']>['nodes']
>[number]

export function useUpdateReportRoleSetMutation({
  endpoint,
  queryInput,
}: {
  endpoint: string
  queryInput: ReportRolesBySchoolQueryVariables
}): ReturnType<
  typeof gqlUseUpdateReportRoleSetMutation<
    unknown,
    {
      originalReports: ReportRolesBySchoolQuery | undefined
    }
  >
> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()
  const updateReports = gqlUseUpdateReportRoleSetMutation<
    unknown,
    {
      originalReports: ReportRolesBySchoolQuery | undefined
    }
  >(client, {
    onMutate: async ({ input }) => {
      await queryClient.cancelQueries(['ReportRolesBySchool', queryInput])
      await queryClient.cancelQueries(['Reports'])
      const arrayedInput = Array.isArray(input) ? input : [input]
      await Promise.all(
        _.uniqWith(
          arrayedInput,
          (a, b) => a.reportId === b.reportId && a.schoolId === b.schoolId,
        ).map(async (reportRole) => {
          await queryClient.cancelQueries([
            'Report',
            {
              id: reportRole.reportId,
              schoolId: reportRole.schoolId,
            },
          ])
        }),
      )

      const reportsQueryData =
        queryClient.getQueryData<ReportRolesBySchoolQuery>([
          'ReportRolesBySchool',
          queryInput,
        ])
      arrayedInput.forEach((role) => {
        if (!reportsQueryData?.reports?.nodes) return
        const report = reportsQueryData.reports.nodes.find(
          (r) => r.id === role.reportId,
        )
        //Check if updated role record is at selected school.
        const reportRole = report?.roles?.nodes?.find(
          (rr) => rr.role.id === role.roleId && rr.school.id === role.schoolId,
        )
        //Update Selected School Role Status.
        if (reportRole) {
          if (role.favorite !== undefined) reportRole.favorite = role.favorite
          reportRole.permitted = role.permitted
        }
        //Check if updated role record is at district.
        const districtReportRole = report?.districtRoles?.nodes?.find(
          (rr) => rr.role.id === role.roleId && rr.school.id === role.schoolId,
        )
        //Update District Role Status.
        if (districtReportRole) {
          if (role.favorite !== undefined)
            districtReportRole.favorite = role.favorite
          districtReportRole.permitted = role.permitted
        }
      })

      //Verify that this works.

      return {
        originalReports: reportsQueryData,
      }
    },
    onError: (__, ___, context) => {
      queryClient.setQueryData(
        ['ReportRolesBySchool', queryInput],
        context?.originalReports,
      )
    },
    onSettled: async (__, ___, { input }) => {
      //Reset All ReportRolesBySchool queries.
      await queryClient.invalidateQueries(['ReportRolesBySchool'])
      await queryClient.invalidateQueries(['Reports'])
      const arrayedInput = Array.isArray(input) ? input : [input]
      //Reset All Report queries
      _.uniqWith(arrayedInput, (a, b) => a.reportId === b.reportId).map(
        async (reportRole) => {
          await queryClient.invalidateQueries([
            'Report',
            {
              id: reportRole.reportId,
            },
          ])
        },
      )
    },
  })

  return updateReports
}
