/* eslint-disable tsdoc/syntax -- Examples can contain code */
'use client'

import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'
import { useKey } from './use-key'
import { useLocalStorage } from './use-local-storage'

const EnableDeveloperToolsContext = createContext<
  [boolean, (enable: boolean) => void] | null
>(null)

/**
 * `EnableDeveloperToolsProvider` is a [Context.Provider](https://reactjs.org/docs/context.html#contextprovider) component.
 */
export function EnableDeveloperToolsProvider({
  children,
}: {
  children?: ReactNode | undefined
}): React.ReactElement {
  const [enable = false, setEnable] = useLocalStorage<boolean>(
    'enableDevtools',
    false,
  )

  useKey('d', () => {
    setEnable(!enable)
  })

  return (
    <EnableDeveloperToolsContext.Provider value={[enable, setEnable]}>
      {children}
    </EnableDeveloperToolsContext.Provider>
  )
}

/**
 * `useEnableDeveloperTools` exposes a hook to check if developer tools are enabled.
 * It returns a getter and setter for enabling developer tools.
 *
 * @example
 * import { useEnableDeveloperTools } from '@hooks'
 *
 * const [enabled, setEnabled] = useEnableDeveloperTools()
 */
export function useEnableDeveloperTools(): [
  boolean,
  (enable: boolean) => void,
] {
  const context = useContext(EnableDeveloperToolsContext)

  if (context === null) {
    throw new Error(
      'useEnableDeveloperTools must be used within an EnableDeveloperToolsProvider',
    )
  }
  return context
}
