import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import type {
  UserQuery,
  RolesQuery,
  SchoolsQuery,
  UserFieldsFragment,
} from '@graphql-hooks'
import {
  UserStatus,
  useUpdateUserMutation as gqlUseUpdateUserMutation,
} from '@graphql-hooks'
import { nodesToEdgesWithOffset } from '@/api/graphql/utils/pagination'

export function useUpdateUserMutation({
  endpoint,
}: {
  endpoint: string
}): ReturnType<
  typeof gqlUseUpdateUserMutation<
    unknown,
    {
      originalUser: UserQuery['user'] | undefined
      updatedUser: UserQuery['user'] | undefined
    }
  >
> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()
  const updateUser = gqlUseUpdateUserMutation<
    unknown,
    {
      originalUser: UserQuery['user'] | undefined
      updatedUser: UserQuery['user'] | undefined
    }
  >(client, {
    onMutate: async ({ input }) => {
      await queryClient.cancelQueries([
        'user',
        { id: input.id, statuses: [UserStatus.Active, UserStatus.Inactive] },
      ])
      await queryClient.cancelQueries(['Users'])

      const roleList = queryClient.getQueryData<RolesQuery>(['roles'])?.roles
      const schoolList = queryClient.getQueryData<SchoolsQuery>([
        'schools',
      ])?.schools

      const getUpdatedUser = (user: UserFieldsFragment): UserFieldsFragment => {
        const trimmedUpdateValues = JSON.parse(
          JSON.stringify(input),
        ) as typeof input

        const roles = trimmedUpdateValues.roles
          ?.map((role: { roleId: string; schoolId: string }, index: number) => {
            const foundRole = roleList?.find((item) => item.id === role.roleId)
            const foundSchool = schoolList?.find(
              (item) => item.id === role.schoolId,
            )

            if (!foundRole || !foundSchool) return

            return {
              id: index.toString(),
              role: {
                ...foundRole,
              },
              school: {
                ...foundSchool,
              },
            }
          })
          .filter(Boolean)

        const updatedUser: UserFieldsFragment = {
          ...user,
          ...trimmedUpdateValues,
          name: `${trimmedUpdateValues.firstName ?? user.firstName} ${
            trimmedUpdateValues.lastName ?? user.lastName
          }`,
          roles: roles
            ? {
                edges: nodesToEdgesWithOffset(roles, 0),
                nodes: roles,
              }
            : user.roles,
        }

        return updatedUser
      }

      const userQueryData = queryClient.getQueryData<UserQuery>([
        'user',
        { id: input.id, statuses: [UserStatus.Active, UserStatus.Inactive] },
      ])

      if (!userQueryData?.user) return

      const updatedUser = getUpdatedUser(userQueryData.user)

      queryClient.setQueryData<UserQuery>(
        [
          'user',
          { id: input.id, statuses: [UserStatus.Active, UserStatus.Inactive] },
        ],
        { user: updatedUser },
      )
      return { originalUser: userQueryData.user, updatedUser }
    },
    onError: (_, { input }, context) => {
      queryClient.setQueryData(
        [
          'user',
          { id: input.id, statuses: [UserStatus.Active, UserStatus.Inactive] },
        ],
        context?.originalUser,
      )
    },
    onSettled: async (updatedUser) => {
      await queryClient.invalidateQueries([
        'user',
        {
          id: updatedUser?.updateUser.id,
          statuses: [UserStatus.Active, UserStatus.Inactive],
        },
      ])
      await queryClient.invalidateQueries(['Users'])
    },
  })

  return updateUser
}
