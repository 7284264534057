// @ts-nocheck
            import { GraphQLError } from 'graphql'
import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An ISO-8601 encoded UTC date string */
  DateTime: { input: Date; output: Date; }
};

export enum AbsenceDayFraction {
  FullDay = 'FULL_DAY',
  HalfDay = 'HALF_DAY',
  LessThanHalfDay = 'LESS_THAN_HALF_DAY'
}

export type AbsenceSummaryType = {
  __typename?: 'AbsenceSummaryType';
  /** Identifies the date and time when the object was last updated. */
  dateChanged: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was created. */
  dateCreated: Scalars['DateTime']['output'];
  /** Identifies the primary key from the database. */
  id: Scalars['ID']['output'];
  /** Whether or not the absence type is a tardy. */
  isTardy: Scalars['Boolean']['output'];
  /** The absence type's displayed name. */
  name: Scalars['String']['output'];
};

export type AbsenceType = {
  __typename?: 'AbsenceType';
  /** Identifies the date and time when the object was last updated. */
  dateChanged: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was created. */
  dateCreated: Scalars['DateTime']['output'];
  /** Identifies the primary key from the database. */
  id: Scalars['ID']['output'];
  /** The absence type's displayed name. */
  name: Scalars['String']['output'];
  /** The summary grouping type for the absence type. */
  summaryType?: Maybe<AbsenceSummaryType>;
};

export type Activity = {
  __typename?: 'Activity';
  /** Referential ID for the activity */
  id: Scalars['Int']['output'];
  /** Name of the activity */
  name: Scalars['String']['output'];
};

/** Fields for adding a role to a user */
export type AddUserRoleInput = {
  /** The role and school ids to add */
  input: UserRoleInput;
  /** The id of the user the role is being added to */
  userId: Scalars['ID']['input'];
};

export type AdvancedPopulationFilter = Node & {
  __typename?: 'AdvancedPopulationFilter';
  /**
   * Returns a list of district-level roles and their configurations for this report detail section.
   * Exclusive to system administrators.
   */
  districtRoles?: Maybe<AdvancedPopulationFilterRoleConnection>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /**
   * Returns a list of roles and their configurations for this report detail section.
   * Exclusive to system administrators.
   */
  roles?: Maybe<AdvancedPopulationFilterRoleConnection>;
};


export type AdvancedPopulationFilterDistrictRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AdvancedPopulationFilterRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdvancedPopulationFilterConnection = Connection & {
  __typename?: 'AdvancedPopulationFilterConnection';
  edges?: Maybe<Array<Maybe<AdvancedPopulationFilterEdge>>>;
  nodes?: Maybe<Array<Maybe<AdvancedPopulationFilter>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AdvancedPopulationFilterEdge = Edge & {
  __typename?: 'AdvancedPopulationFilterEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AdvancedPopulationFilter>;
};

/** Fields for ordering a list of reports */
export type AdvancedPopulationFilterOrder = {
  direction: OrderDirection;
  field: AdvancedPopulationFilterOrderField;
};

/** Properties that can be ordered/filtered for a report */
export enum AdvancedPopulationFilterOrderField {
  Id = 'id',
  Name = 'name'
}

export type AdvancedPopulationFilterRole = Node & {
  __typename?: 'AdvancedPopulationFilterRole';
  filter: AdvancedPopulationFilter;
  id: Scalars['ID']['output'];
  permitted: Scalars['Boolean']['output'];
  role: Role;
  school: School;
};

export type AdvancedPopulationFilterRoleConnection = Connection & {
  __typename?: 'AdvancedPopulationFilterRoleConnection';
  edges?: Maybe<Array<Maybe<AdvancedPopulationFilterRoleEdge>>>;
  nodes?: Maybe<Array<Maybe<AdvancedPopulationFilterRole>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AdvancedPopulationFilterRoleEdge = Edge & {
  __typename?: 'AdvancedPopulationFilterRoleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AdvancedPopulationFilterRole>;
};

export type AdvancedPopulationFilterRoleInput = {
  filterId: Scalars['ID']['input'];
  permitted: Scalars['Boolean']['input'];
  roleId: Scalars['ID']['input'];
  schoolId: Scalars['ID']['input'];
};

export type Attribute = Node & {
  __typename?: 'Attribute';
  /**
   * Returns list of roles and configurations for this attribute.
   * Exclusive to system administrators.
   */
  districtRoles: AttributeRoleConnection;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /**
   * Returns list of roles and configurations for this attribute.
   * Exclusive to system administrators.
   */
  roles: AttributeRoleConnection;
  tableType?: Maybe<Scalars['String']['output']>;
};


export type AttributeDistrictRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AttributeRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
};

export type AttributeConnection = Connection & {
  __typename?: 'AttributeConnection';
  edges?: Maybe<Array<Maybe<AttributeEdge>>>;
  nodes?: Maybe<Array<Maybe<Attribute>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AttributeEdge = Edge & {
  __typename?: 'AttributeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Attribute>;
};

/** Fields for ordering a list of reports */
export type AttributeOrder = {
  direction: OrderDirection;
  field: AttributeOrderField;
};

/** Properties that can be ordered/filtered for a report */
export enum AttributeOrderField {
  Id = 'id',
  Name = 'name',
  TableType = 'tableType'
}

export type AttributeRole = Node & {
  __typename?: 'AttributeRole';
  attribute?: Maybe<Attribute>;
  id: Scalars['ID']['output'];
  permitted?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<Role>;
  school?: Maybe<School>;
};

export type AttributeRoleConnection = Connection & {
  __typename?: 'AttributeRoleConnection';
  edges?: Maybe<Array<Maybe<AttributeRoleEdge>>>;
  nodes?: Maybe<Array<Maybe<AttributeRole>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AttributeRoleEdge = Edge & {
  __typename?: 'AttributeRoleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AttributeRole>;
};

export type AttributeRoleInput = {
  attributeId: Scalars['ID']['input'];
  permitted: Scalars['Boolean']['input'];
  roleId: Scalars['ID']['input'];
  schoolId: Scalars['ID']['input'];
};

export type Color = {
  __typename?: 'Color';
  /** This is a hexadecimal triplet representing the color for this parameter value. */
  hexColorCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Configuration = Node & {
  __typename?: 'Configuration';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ConfigurationConnection = Connection & {
  __typename?: 'ConfigurationConnection';
  edges?: Maybe<Array<Maybe<ConfigurationEdge>>>;
  nodes?: Maybe<Array<Maybe<Configuration>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ConfigurationEdge = Edge & {
  __typename?: 'ConfigurationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Configuration>;
};

export enum ConfigurationType {
  Authentication = 'AUTHENTICATION',
  Invalid = 'INVALID',
  System = 'SYSTEM'
}

export type Connection = {
  edges?: Maybe<Array<Maybe<Edge>>>;
  nodes?: Maybe<Array<Maybe<Node>>>;
  pageInfo: PageInfo;
};

export type Course = {
  __typename?: 'Course';
  /** District Identifier of the Course. */
  code: Scalars['String']['output'];
  /** Course Group of the Course. */
  courseGroup?: Maybe<CourseGroup>;
  /** Department/Subject Area of the Course. */
  department?: Maybe<Department>;
  /** Course identifier of the Course. */
  id: Scalars['Int']['output'];
  /** District Name of the Course. */
  name: Scalars['String']['output'];
  /** District Course Number of the Course. */
  number: Scalars['String']['output'];
  /** State Identifier of the Course. */
  stateCode?: Maybe<Scalars['String']['output']>;
  /** State Name of the Course. */
  stateName?: Maybe<Scalars['String']['output']>;
};

export type CourseGroup = {
  __typename?: 'CourseGroup';
  /** Reference identifier of the Course Group. */
  id: Scalars['Int']['output'];
  /** Name of the Course Group. */
  name: Scalars['String']['output'];
};

export type CourseSection = {
  __typename?: 'CourseSection';
  /** The course this course section is for. */
  course: Course;
  /** Identifier for the Course Section */
  id: Scalars['Int']['output'];
  /** List of instructors for this course section. */
  instructors: Array<Instructor>;
  /** The period during which this course section occupies. */
  periodName?: Maybe<Scalars['String']['output']>;
  /** The location identifer that this course section occupies. */
  room?: Maybe<Scalars['String']['output']>;
  /** The school year in which this course section occuplies. */
  schoolYear: SchoolYear;
  /** The term in which this course section occupies. */
  term: Term;
};

export type CreateReferenceParameterInput = {
  chartColorId: Scalars['ID']['input'];
  displayOrder: Scalars['Int']['input'];
  displayed: Scalars['Boolean']['input'];
  extraData?: InputMaybe<Scalars['Int']['input']>;
  parentGroupId?: InputMaybe<Scalars['ID']['input']>;
  precedentOrder: Scalars['Int']['input'];
  shortName?: InputMaybe<Scalars['String']['input']>;
  typeId: Scalars['ID']['input'];
  userDefined1?: InputMaybe<Scalars['String']['input']>;
  userDefined2?: InputMaybe<Scalars['String']['input']>;
  userDefined3?: InputMaybe<Scalars['String']['input']>;
  userDefined4?: InputMaybe<Scalars['String']['input']>;
  userDefined5?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type CreateReferenceParameterSourceInput = {
  code: Scalars['String']['input'];
  referenceParameterId: Scalars['ID']['input'];
  referenceTypeId: Scalars['ID']['input'];
};

export type CreateReferenceTypeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parentTypeId?: InputMaybe<Scalars['Int']['input']>;
};

/** Fields for creating a user */
export type CreateUserInput = {
  /** The user's email address */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The external employee code to link the user to an external system. Usually an employee code */
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  /** The user's first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The user's last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The user's login method type (local(password), network(LDAP, SSO)) */
  loginMethod?: InputMaybe<LoginMethod>;
  /** The user's password if using local authentication */
  password?: InputMaybe<Scalars['String']['input']>;
  /** A list of roles to apply to the user */
  roles?: InputMaybe<Array<UserRoleInput>>;
  /** The user's status (Active, inactive) */
  status: UserStatus;
  /** The username used to login */
  username: Scalars['String']['input'];
};

export type CreateUserRosterDashboardAssessmentInput = {
  outlineId: Scalars['Int']['input'];
  reportId: Scalars['Int']['input'];
  scoreTypeId: Scalars['Int']['input'];
  seasonId: Scalars['Int']['input'];
  testId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
  variantId?: InputMaybe<Scalars['Int']['input']>;
};

export enum DatabaseStatus {
  Active = 'active',
  Error = 'error',
  Inactive = 'inactive'
}

export type DefaultTestPerformanceScalesByScoreTypeInput = {
  /** The season/grade/variant for the score */
  instanceId?: InputMaybe<Scalars['Int']['input']>;
  /** The category being tested */
  outlineId: Scalars['Int']['input'];
  /** The score type containing a default set for which score ranges are being requested */
  scoreTypeId: Scalars['Int']['input'];
  /** The season for the score */
  seasonId: Scalars['Int']['input'];
};

/** Fields for removing a role from a user */
export type DeleteUserRoleInput = {
  /** The role and school ids to add */
  input: UserRoleInput;
  /** The id of the user the role is being added to */
  userId: Scalars['ID']['input'];
};

export type DemographicMapping = Node & {
  __typename?: 'DemographicMapping';
  demographic?: Maybe<ReferenceParameter>;
  groupMember?: Maybe<DemographicMappingMember>;
  id: Scalars['ID']['output'];
};

export type DemographicMappingConnection = Connection & {
  __typename?: 'DemographicMappingConnection';
  edges?: Maybe<Array<Maybe<DemographicMappingEdge>>>;
  nodes?: Maybe<Array<Maybe<DemographicMapping>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DemographicMappingEdge = Edge & {
  __typename?: 'DemographicMappingEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<DemographicMapping>;
};

export type DemographicMappingGroup = {
  __typename?: 'DemographicMappingGroup';
  groupId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DemographicMappingInput = {
  demographicId: Scalars['ID']['input'];
  groupMemberId: Scalars['ID']['input'];
};

export type DemographicMappingMember = {
  __typename?: 'DemographicMappingMember';
  group?: Maybe<DemographicMappingGroup>;
  mappings: DemographicMappingConnection;
  memberId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};


export type DemographicMappingMemberMappingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Department = {
  __typename?: 'Department';
  /** Reference identifier of the Department. */
  id: Scalars['Int']['output'];
  /** Name of the department. */
  name: Scalars['String']['output'];
};

export type DisciplinaryAction = {
  __typename?: 'DisciplinaryAction';
  /** Referential ID of the Disciplinary Action */
  id: Scalars['ID']['output'];
  /** Name of the Disciplinary Action */
  name: Scalars['String']['output'];
};

export type DisciplinaryIncident = {
  __typename?: 'DisciplinaryIncident';
  /** Date that this record was changed. */
  dateChanged: Scalars['DateTime']['output'];
  /** Date that this record was created. */
  dateCreated: Scalars['DateTime']['output'];
  /** If the responsive action was a suspension, the number of days the student was suspended in connection to this incident. */
  daysSuspended?: Maybe<Scalars['Int']['output']>;
  /** Verbose description of the incident */
  description: Scalars['String']['output'];
  /** Action in response to the incident. */
  disciplinaryAction?: Maybe<DisciplinaryAction>;
  /** Date of the incident. */
  incidentDate: Scalars['DateTime']['output'];
  /** Location of the incident. */
  incidentLocation?: Maybe<DisciplinaryIncidentLocation>;
  /** Time of the day of the incident. */
  incidentTime?: Maybe<Scalars['DateTime']['output']>;
  /** Type of incident. */
  incidentType: DisciplinaryIncidentType;
  /** School where the incident took place. */
  school: School;
  /** School Year of the incident. */
  schoolYear: SchoolYear;
  /** Student that committed the disciplinary incident */
  student: Student;
  /** Term of the incident. */
  term: Term;
};

export type DisciplinaryIncidentLocation = {
  __typename?: 'DisciplinaryIncidentLocation';
  /** Referential ID of the Disciplinary Incident Location */
  id: Scalars['ID']['output'];
  /** Name of the Disciplinary Incident Location */
  name: Scalars['String']['output'];
};

export type DisciplinaryIncidentType = {
  __typename?: 'DisciplinaryIncidentType';
  /** Referential ID of the Disciplinary Incident Type */
  id: Scalars['ID']['output'];
  /** Name of the Disciplinary Incident Type */
  name: Scalars['String']['output'];
};

export type Document = {
  __typename?: 'Document';
  dateChanged?: Maybe<Scalars['DateTime']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  documentUrl: Scalars['String']['output'];
  implementationDate?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  typeName: Scalars['String']['output'];
};

/**
 * Using "Free & Reduced Lunch" Indicator.
 * https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38741
 */
export enum EconomicDisadvantageStatus {
  DataNotAvailable = 'DATA_NOT_AVAILABLE',
  No = 'NO',
  Yes = 'YES'
}

export type EconomicDisadvantageStatusFilter = {
  /** In */
  contains?: InputMaybe<Array<EconomicDisadvantageStatus>>;
  /** Not in */
  doesNotContain?: InputMaybe<Array<EconomicDisadvantageStatus>>;
};

export type Edge = {
  cursor: Scalars['String']['output'];
  node?: Maybe<Node>;
};

/** An employee links a user to a course/roster */
export type Employee = {
  __typename?: 'Employee';
  /** The unique code identifying the employee in the district's student information system */
  employeeCode: Scalars['String']['output'];
  /** The employee's first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Identifies the primary key from the database */
  id: Scalars['Int']['output'];
  /** The employee's last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The employee's full name */
  name?: Maybe<Scalars['String']['output']>;
};

/** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38783 */
export enum EnglishLearnerStatus {
  DataNotAvailable = 'DATA_NOT_AVAILABLE',
  Monitor = 'MONITOR',
  No = 'NO',
  Yes = 'YES'
}

export type EnglishLearnerStatusFilter = {
  /** In */
  contains?: InputMaybe<Array<EnglishLearnerStatus>>;
  /** Not in */
  doesNotContain?: InputMaybe<Array<EnglishLearnerStatus>>;
};

export type EnumFilter = {
  /** Contains */
  contains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain */
  doesNotContain?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum Ethnicity {
  /** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38907 */
  AmericanIndianOrAlaskaNative = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  /** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38908 */
  Asian = 'ASIAN',
  /** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38909 */
  BlackOrAfricanAmerican = 'BLACK_OR_AFRICAN_AMERICAN',
  DataNotAvailable = 'DATA_NOT_AVAILABLE',
  /** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38765 */
  HispanicOrLatinoEthnicity = 'HISPANIC_OR_LATINO_ETHNICITY',
  /** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38910 */
  NativeHawaiianOrOtherPacificIslander = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  /** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38425 */
  TwoOrMoreRaces = 'TWO_OR_MORE_RACES',
  /** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38911 */
  White = 'WHITE'
}

export type EthnicityFilter = {
  /** In */
  contains?: InputMaybe<Array<Ethnicity>>;
  /** Not in */
  doesNotContain?: InputMaybe<Array<Ethnicity>>;
};

export type Gender = {
  __typename?: 'Gender';
  /** Identifies the primary key from the database. */
  id: Scalars['Int']['output'];
  /** The gender's displayed name. */
  name: Scalars['String']['output'];
};

/** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38811 */
export enum GenderType {
  Female = 'FEMALE',
  Male = 'MALE',
  NotSelected = 'NOT_SELECTED'
}

export type GenderTypeFilter = {
  /** In */
  contains?: InputMaybe<Array<GenderType>>;
  /** Not in */
  doesNotContain?: InputMaybe<Array<GenderType>>;
};

/** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38756 */
export enum GiftedAndTalentedIndicator {
  No = 'NO',
  Unknown = 'UNKNOWN',
  Yes = 'YES'
}

export type GiftedAndTalentedIndicatorFilter = {
  /** In */
  contains?: InputMaybe<Array<GiftedAndTalentedIndicator>>;
  /** Not in */
  doesNotContain?: InputMaybe<Array<GiftedAndTalentedIndicator>>;
};

export type GradeLevel = {
  __typename?: 'GradeLevel';
  /** Identifies the primary key from the database. */
  id: Scalars['Int']['output'];
  /** The grade level's displayed name. */
  name: Scalars['String']['output'];
};

/** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38749 */
export enum GradeLevelIdentifier {
  EighthGrade = 'EIGHTH_GRADE',
  EleventhGrade = 'ELEVENTH_GRADE',
  FifthGrade = 'FIFTH_GRADE',
  FirstGrade = 'FIRST_GRADE',
  FourthGrade = 'FOURTH_GRADE',
  Grade_13 = 'GRADE_13',
  InfantOrToddler = 'INFANT_OR_TODDLER',
  Kindergarten = 'KINDERGARTEN',
  NinthGrade = 'NINTH_GRADE',
  Other = 'OTHER',
  Postsecondary = 'POSTSECONDARY',
  Prekindergarten = 'PREKINDERGARTEN',
  Preschool = 'PRESCHOOL',
  SecondGrade = 'SECOND_GRADE',
  SeventhGrade = 'SEVENTH_GRADE',
  SixthGrade = 'SIXTH_GRADE',
  TenthGrade = 'TENTH_GRADE',
  ThirdGrade = 'THIRD_GRADE',
  TransitionalKindergarten = 'TRANSITIONAL_KINDERGARTEN',
  TwelfthGrade = 'TWELFTH_GRADE',
  Ungraded = 'UNGRADED'
}

export type GradeLevelIdentifierFilter = {
  /** In */
  contains?: InputMaybe<Array<GradeLevelIdentifier>>;
  /** Not in */
  doesNotContain?: InputMaybe<Array<GradeLevelIdentifier>>;
};

export type GradeMark = Node & {
  __typename?: 'GradeMark';
  /** Course Name attached to grade mark. */
  altCourseName: Scalars['String']['output'];
  /** Course for this Grade Mark, if correctly attached. */
  course?: Maybe<Course>;
  /** Statistically allocated Letter Grade Option for this Grade Mark. */
  courseGradeMark?: Maybe<GradeMarkOption>;
  /** Course Section for this Grade Mark, if correctly attached. */
  courseSection?: Maybe<CourseSection>;
  /** Number of credits attempted for this Grade Mark. */
  creditsAttempted?: Maybe<Scalars['Float']['output']>;
  /** Number of credits earned with this Grade Mark. */
  creditsEarned?: Maybe<Scalars['Float']['output']>;
  /** Time this record was last changed. */
  dateChanged: Scalars['DateTime']['output'];
  /** Time this record was created. */
  dateCreated: Scalars['DateTime']['output'];
  /** Department/Subject Area of Grade Mark. */
  department?: Maybe<Department>;
  /** Official final Grade Mark. */
  finalGrade: Scalars['String']['output'];
  /** Grade Level for the student at the time of taking the course of the Grade Mark. */
  gradeLevel: GradeLevel;
  id: Scalars['ID']['output'];
  /** School for this Grade Mark. */
  school: School;
  /** School Year of Grade Mark. */
  schoolYear: SchoolYear;
  /** Student for this Grade Mark. */
  student: Student;
  /** Term of Grade Mark. */
  term?: Maybe<Term>;
};

export type GradeMarkConnection = Connection & {
  __typename?: 'GradeMarkConnection';
  edges?: Maybe<Array<Maybe<GradeMarkEdge>>>;
  nodes?: Maybe<Array<Maybe<GradeMark>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GradeMarkEdge = Edge & {
  __typename?: 'GradeMarkEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<GradeMark>;
};

export type GradeMarkOption = {
  __typename?: 'GradeMarkOption';
  /** Referential ID for a Letter Grade Mark Option. */
  id: Scalars['Int']['output'];
  /** Letter Grade Mark for the Grade Mark Option. */
  letterGrade: Scalars['String']['output'];
};

export type HealthConcern = {
  __typename?: 'HealthConcern';
  concern: Scalars['String']['output'];
  dateCreated: Scalars['DateTime']['output'];
};

/** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38769 */
export enum HomelessnessStatus {
  DataNotAvailable = 'DATA_NOT_AVAILABLE',
  No = 'NO',
  Yes = 'YES'
}

export type HomelessnessStatusFilter = {
  /** In */
  contains?: InputMaybe<Array<HomelessnessStatus>>;
  /** Not in */
  doesNotContain?: InputMaybe<Array<HomelessnessStatus>>;
};

/**
 * Special Education Indicator
 * https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38771
 */
export enum IdeaIndicator {
  DataNotAvailable = 'DATA_NOT_AVAILABLE',
  No = 'NO',
  Yes = 'YES'
}

export type IdeaIndicatorFilter = {
  /** In */
  contains?: InputMaybe<Array<IdeaIndicator>>;
  /** Not in */
  doesNotContain?: InputMaybe<Array<IdeaIndicator>>;
};

export type Immunization = {
  __typename?: 'Immunization';
  dates: Array<Maybe<Scalars['DateTime']['output']>>;
  immunizationComplianceFlag?: Maybe<ImmunizationComplianceFlag>;
  vaccination: Vaccination;
  vaccinationExemption?: Maybe<VaccinationExemption>;
};

export type ImmunizationComplianceFlag = {
  __typename?: 'ImmunizationComplianceFlag';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Instructor = {
  __typename?: 'Instructor';
  /** Course Section reference. */
  courseSection: CourseSection;
  /** Employee reference. */
  employee: Employee;
  /** Instructor level of the employee. */
  instructorLevel?: Maybe<InstructorLevel>;
};

export enum InstructorLevel {
  /** Non-primary Instructor or Support Staff for the Course Section. */
  NonPrimary = 'NON_PRIMARY',
  /** Primary Instructor of the Course Section. */
  Primary = 'PRIMARY'
}

export type InterdistrictExchangeConfiguration = {
  __typename?: 'InterdistrictExchangeConfiguration';
  incomingAllTests?: Maybe<Scalars['Boolean']['output']>;
  incomingPermitted?: Maybe<Scalars['Boolean']['output']>;
  incomingTestList?: Maybe<Array<Maybe<Test>>>;
  lastChangedBy?: Maybe<User>;
  lastChangedDate?: Maybe<Scalars['DateTime']['output']>;
  outgoingAllTests?: Maybe<Scalars['Boolean']['output']>;
  outgoingPermitted?: Maybe<Scalars['Boolean']['output']>;
  outgoingTestList?: Maybe<Array<Maybe<Test>>>;
};

export type Intervention = {
  __typename?: 'Intervention';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: InterventionType;
};

export type InterventionCategory = {
  __typename?: 'InterventionCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type InterventionReferralFlag = {
  __typename?: 'InterventionReferralFlag';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type InterventionStateReportedFlag = {
  __typename?: 'InterventionStateReportedFlag';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type InterventionStatus = {
  __typename?: 'InterventionStatus';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type InterventionType = {
  __typename?: 'InterventionType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** A user's login method */
export enum LoginMethod {
  /** A local database/password user */
  Local = 'local',
  /** A user authenticated outside of Viewpoint */
  Network = 'network'
}

export type LoginMethodFilter = {
  contains?: InputMaybe<Array<LoginMethod>>;
  doesNotContain?: InputMaybe<Array<LoginMethod>>;
};

export type MarssEnrollment = Node & {
  __typename?: 'MARSSEnrollment';
  attendancePercentage?: Maybe<Scalars['String']['output']>;
  elIndicator?: Maybe<Scalars['String']['output']>;
  giftedAndTalentedIndicator?: Maybe<Scalars['String']['output']>;
  homeBoundServiceIndicator?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  postSecondaryEducationalOpportunityHighSchoolPartHours?: Maybe<Scalars['Int']['output']>;
  primaryDisability?: Maybe<Scalars['String']['output']>;
  school?: Maybe<School>;
  schoolYear?: Maybe<SchoolYear>;
  specialEducationEvaluationStatus?: Maybe<Scalars['String']['output']>;
  statusBeginDate?: Maybe<Scalars['DateTime']['output']>;
  statusEndDate?: Maybe<Scalars['DateTime']['output']>;
  title1Indicator?: Maybe<Scalars['String']['output']>;
};

export type MarssEnrollmentConnection = Connection & {
  __typename?: 'MARSSEnrollmentConnection';
  edges?: Maybe<Array<Maybe<MarssEnrollmentEdge>>>;
  nodes?: Maybe<Array<Maybe<MarssEnrollment>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MarssEnrollmentEdge = Edge & {
  __typename?: 'MARSSEnrollmentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<MarssEnrollment>;
};

export type ManualTestScoreInput = {
  /** The school year for the test taken. */
  schoolYearId?: InputMaybe<Scalars['ID']['input']>;
  /** The student's unique internal identifier */
  studentId: Scalars['ID']['input'];
  /** The test to input. */
  testId?: InputMaybe<Scalars['ID']['input']>;
};

/** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38789 */
export enum MigrantStatus {
  DataNotAvailable = 'DATA_NOT_AVAILABLE',
  No = 'NO',
  Yes = 'YES'
}

export type MigrantStatusFilter = {
  /** In */
  contains?: InputMaybe<Array<MigrantStatus>>;
  /** Not in */
  doesNotContain?: InputMaybe<Array<MigrantStatus>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Store a new valid one time authentication code for a given email address. */
  addOtp?: Maybe<Otp>;
  /** Adds a role with optional permissions list. */
  addRole: Role;
  /**
   * Add a role to a user
   * Exclusive to system administrators.
   */
  addUserRole?: Maybe<UserRole>;
  /**
   * Creates a new reference parameter.
   * Exclusive to system administrators.
   */
  createReferenceParameter: ReferenceParameter;
  /**
   * Creates a new reference type.
   * Exclusive to system administrators.
   */
  createReferenceParameterSource: ReferenceParameterSource;
  /**
   * Creates a new reference type.
   * Exclusive to system administrators.
   */
  createReferenceType: ReferenceType;
  /**
   * Create a user
   * Exclusive to system administrators.
   */
  createUser?: Maybe<User>;
  createUserRosterDashboardAssessment: UserRosterDashboardAssessment;
  /** Deletes the given role id. */
  deleteRole: Scalars['Boolean']['output'];
  deleteTestPerformanceScales: Array<Maybe<TestPerformanceScale>>;
  /**
   * Remove a role from a user
   * Exclusive to system administrators.
   */
  deleteUserRole: Scalars['Boolean']['output'];
  deleteUserRosterDashboardAssessment: Scalars['Boolean']['output'];
  /** Duplicates source role id with target role name. */
  duplicateRole?: Maybe<Role>;
  /** Edits role of given role id with optional permissions list. */
  editRole: Role;
  /** Invalidate an OTP code. */
  invalidateOtp?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Link a user to an employee
   * Exclusive to system administrators.
   */
  linkUserToEmployee: User;
  /** Login a user with their local credentials */
  login?: Maybe<User>;
  reorderUserRosterDashboardAssessments: Array<UserRosterDashboardAssessment>;
  setDefaultBandingSet: Array<Maybe<PerformanceBandingSet>>;
  setDefaultBandingSetBySet: Array<Maybe<PerformanceBandingSet>>;
  toggleActiveBandingSet: Array<Maybe<PerformanceBandingSet>>;
  toggleActiveBandingSetBySet: Array<Maybe<PerformanceBandingSet>>;
  /**
   * Unlink a user from an employee
   * Exclusive to system administrators.
   */
  unlinkEmployeeFromUser: User;
  /**
   * Adds or updates report detail section role configuration for specified report detail section and role combination.
   * Exclusive to system administrators.
   */
  updateAdvancedPopulationFilterRole: AdvancedPopulationFilterRole;
  updateAdvancedPopulationFilterRoleSet: Array<Maybe<AdvancedPopulationFilterRole>>;
  /**
   * Adds or updates attribute role configuration for specified attribute and role combination.
   * Exclusive to system administrators.
   */
  updateAttributeRole: AttributeRole;
  updateAttributeRoleSet: Array<Maybe<AttributeRole>>;
  /**
   * Updates a set of existing site-level configuration values.
   * Exclusive to system administrators.
   */
  updateConfigurationSet: Array<Configuration>;
  /**
   * Updates an existing site-level configuration value.
   * Exclusive to system administrators.
   */
  updateConfigurationValue: Configuration;
  updateDemographicMapping?: Maybe<DemographicMapping>;
  updateDemographicMappingByGroup?: Maybe<Array<Maybe<DemographicMapping>>>;
  /**
   * Updates an existing district-level interdistrict exchange configuration.
   * Exclusive to system administrators.
   */
  updateInterdistrictExchangeConfiguration: InterdistrictExchangeConfiguration;
  /**
   * Updates an existing reference parameter.
   * Exclusive to system administrators.
   */
  updateReferenceParameter: ReferenceParameter;
  /**
   * Updates an existing reference type.
   * Exclusive to system administrators.
   */
  updateReferenceParameterSource: ReferenceParameterSource;
  /**
   * Updates an existing reference type.
   * Exclusive to system administrators.
   */
  updateReferenceType: ReferenceType;
  /**
   * Adds or updates report detail section role configuration for specified report detail section and role combination.
   * Exclusive to system administrators.
   */
  updateReportDetailSectionRole: ReportDetailSectionRole;
  updateReportDetailSectionRoleSet: Array<Maybe<ReportDetailSectionRole>>;
  /**
   * Adds or updates report role configuration for specified report and role combination.
   * Exclusive to system administrators.
   */
  updateReportRole: ReportRole;
  updateReportRoleSet: Array<Maybe<ReportRole>>;
  /**
   * Adds or updates report rule category role configuration for specified report rule category and role combination.
   * Exclusive to system administrators.
   */
  updateReportRuleCategoryRole: ReportRuleCategoryRole;
  updateReportRuleCategoryRoleSet: Array<Maybe<ReportRuleCategoryRole>>;
  /**
   * Takes in a role id and a list of permission ids, and swaps the permissions applied to the role.
   * Exclusive to system administrators.
   */
  updateRolePermissions: Array<Maybe<RolePermission>>;
  /**
   * Updates a set of existing district-level configuration values.
   * Exclusive to system administrators.
   */
  updateSchoolConfigurationSet: Array<SchoolConfiguration>;
  /**
   * Updates an existing district-level configuration value.
   * Exclusive to system administrators.
   */
  updateSchoolConfigurationValue: SchoolConfiguration;
  /**
   * Adds or updates report detail section role configuration for specified report detail section and role combination.
   * Exclusive to system administrators.
   */
  updateStudentFilterTabRole: StudentFilterTabRole;
  updateTestPerformanceScales: Array<Maybe<TestPerformanceScale>>;
  /**
   * Update a user's credentials
   * Exclusive to system administrators.
   */
  updateUser: User;
  /**
   * Update a user's password
   * Requires a specific valid password reset token
   */
  updateUserPassword: User;
  updateUserRosterDashboardAssessment?: Maybe<UserRosterDashboardAssessment>;
};


export type MutationAddOtpArgs = {
  code: Scalars['String']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};


export type MutationAddRoleArgs = {
  role: RoleInput;
};


export type MutationAddUserRoleArgs = {
  input: AddUserRoleInput;
};


export type MutationCreateReferenceParameterArgs = {
  input: CreateReferenceParameterInput;
};


export type MutationCreateReferenceParameterSourceArgs = {
  input: CreateReferenceParameterSourceInput;
};


export type MutationCreateReferenceTypeArgs = {
  input: CreateReferenceTypeInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserRosterDashboardAssessmentArgs = {
  input: CreateUserRosterDashboardAssessmentInput;
};


export type MutationDeleteRoleArgs = {
  roleId: Scalars['ID']['input'];
};


export type MutationDeleteTestPerformanceScalesArgs = {
  input: Array<TestPerformanceScaleDeleteInput>;
};


export type MutationDeleteUserRoleArgs = {
  input: DeleteUserRoleInput;
};


export type MutationDeleteUserRosterDashboardAssessmentArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDuplicateRoleArgs = {
  sourceRoleId: Scalars['ID']['input'];
  targetRoleName: Scalars['String']['input'];
};


export type MutationEditRoleArgs = {
  role: RoleInput;
};


export type MutationInvalidateOtpArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type MutationLinkUserToEmployeeArgs = {
  employeeCode: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationLoginArgs = {
  isPasswordHashed?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationReorderUserRosterDashboardAssessmentsArgs = {
  input: Array<InputMaybe<ReorderUserRosterDashboardAssessmentsInput>>;
};


export type MutationSetDefaultBandingSetArgs = {
  defaultScaleState: Scalars['Boolean']['input'];
  performanceBandingSetIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationSetDefaultBandingSetBySetArgs = {
  setStates: Array<InputMaybe<PerformanceBandingSetStateInput>>;
};


export type MutationToggleActiveBandingSetArgs = {
  activeState: Scalars['Boolean']['input'];
  performanceBandingSetIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationToggleActiveBandingSetBySetArgs = {
  setStates: Array<InputMaybe<PerformanceBandingSetStateInput>>;
};


export type MutationUnlinkEmployeeFromUserArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationUpdateAdvancedPopulationFilterRoleArgs = {
  input: AdvancedPopulationFilterRoleInput;
};


export type MutationUpdateAdvancedPopulationFilterRoleSetArgs = {
  input: Array<InputMaybe<AdvancedPopulationFilterRoleInput>>;
};


export type MutationUpdateAttributeRoleArgs = {
  input: AttributeRoleInput;
};


export type MutationUpdateAttributeRoleSetArgs = {
  input: Array<InputMaybe<AttributeRoleInput>>;
};


export type MutationUpdateConfigurationSetArgs = {
  input: Array<UpdateConfigurationValueInput>;
};


export type MutationUpdateConfigurationValueArgs = {
  input: UpdateConfigurationValueInput;
};


export type MutationUpdateDemographicMappingArgs = {
  input: DemographicMappingInput;
  mapped: Scalars['Boolean']['input'];
};


export type MutationUpdateDemographicMappingByGroupArgs = {
  demographicTypeId: Scalars['ID']['input'];
  groupId: Scalars['ID']['input'];
  members: Array<InputMaybe<DemographicMappingInput>>;
};


export type MutationUpdateInterdistrictExchangeConfigurationArgs = {
  input: UpdateInterdistrictExchangeConfigurationInput;
};


export type MutationUpdateReferenceParameterArgs = {
  input: UpdateReferenceParameterInput;
};


export type MutationUpdateReferenceParameterSourceArgs = {
  input: UpdateReferenceParameterSourceInput;
};


export type MutationUpdateReferenceTypeArgs = {
  input: UpdateReferenceTypeInput;
};


export type MutationUpdateReportDetailSectionRoleArgs = {
  input: ReportDetailSectionRoleInput;
};


export type MutationUpdateReportDetailSectionRoleSetArgs = {
  input: Array<InputMaybe<ReportDetailSectionRoleInput>>;
};


export type MutationUpdateReportRoleArgs = {
  input: ReportRoleInput;
};


export type MutationUpdateReportRoleSetArgs = {
  input: Array<InputMaybe<ReportRoleSetItemInput>>;
};


export type MutationUpdateReportRuleCategoryRoleArgs = {
  input: ReportRuleCategoryRoleInput;
};


export type MutationUpdateReportRuleCategoryRoleSetArgs = {
  input: Array<InputMaybe<ReportRuleCategoryRoleInput>>;
};


export type MutationUpdateRolePermissionsArgs = {
  permissions: Array<InputMaybe<PermissionInput>>;
  roleId: Scalars['ID']['input'];
};


export type MutationUpdateSchoolConfigurationSetArgs = {
  input: Array<UpdateSchoolConfigurationValueInput>;
};


export type MutationUpdateSchoolConfigurationValueArgs = {
  input: UpdateSchoolConfigurationValueInput;
};


export type MutationUpdateStudentFilterTabRoleArgs = {
  input: StudentFilterTabRoleInput;
};


export type MutationUpdateTestPerformanceScalesArgs = {
  input: Array<TestPerformanceScaleRangeInput>;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationUpdateUserRosterDashboardAssessmentArgs = {
  input: UpdateUserRosterDashboardAssessmentInput;
};

export type Node = {
  /** Identifies the primary key from the database */
  id: Scalars['ID']['output'];
};

export type NumberFilter = {
  /** Equal to */
  eq?: InputMaybe<Scalars['Float']['input']>;
  /** Greater than */
  gt?: InputMaybe<Scalars['Float']['input']>;
  /** Greater than or equal to */
  gte?: InputMaybe<Scalars['Float']['input']>;
  /** Less than */
  lt?: InputMaybe<Scalars['Float']['input']>;
  /** Less than or equal to */
  lte?: InputMaybe<Scalars['Float']['input']>;
  /** Not equal to */
  neq?: InputMaybe<Scalars['Float']['input']>;
};

export type Order = {
  direction: OrderDirection;
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Otp = {
  __typename?: 'Otp';
  /** The hashed OTP code */
  code: Scalars['String']['output'];
  /** The user's email address */
  email: Scalars['String']['output'];
  /** When the OTP expires. This will be 10 minutes after the OTP is generated. */
  expiresAt: Scalars['DateTime']['output'];
};

export enum OutlineType {
  Invalid = 'INVALID',
  Strand = 'STRAND',
  Subject = 'SUBJECT',
  Substrand = 'SUBSTRAND',
  Test = 'TEST'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaginationVariables = {
  __typename?: 'PaginationVariables';
  after?: Maybe<Scalars['String']['output']>;
  before?: Maybe<Scalars['String']['output']>;
  first?: Maybe<Scalars['Int']['output']>;
  last?: Maybe<Scalars['Int']['output']>;
};

export type PerformanceBandingSet = Node & {
  __typename?: 'PerformanceBandingSet';
  active: Scalars['Boolean']['output'];
  bandingScales: Array<Maybe<PerformanceScale>>;
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  local: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  scoreType: TestScoreType;
};

export type PerformanceBandingSetConnection = Connection & {
  __typename?: 'PerformanceBandingSetConnection';
  edges?: Maybe<Array<Maybe<PerformanceBandingSetEdge>>>;
  nodes?: Maybe<Array<Maybe<PerformanceBandingSet>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PerformanceBandingSetEdge = Edge & {
  __typename?: 'PerformanceBandingSetEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PerformanceBandingSet>;
};

export type PerformanceBandingSetStateInput = {
  performanceBandingSetId: Scalars['ID']['input'];
  targetState: Scalars['Boolean']['input'];
};

export type PerformanceScale = {
  __typename?: 'PerformanceScale';
  /** The scale's banding collection. */
  bandingSet: PerformanceBandingSet;
  /** The hex color code associated with this scale */
  color: Scalars['String']['output'];
  /** Identifies the date and time when the object was last updated. */
  dateChanged: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was created. */
  dateCreated: Scalars['DateTime']['output'];
  /** Identifies the primary key from the database. */
  id: Scalars['Int']['output'];
  /** Identifies if the given scale is passing or not */
  isPassing?: Maybe<Scalars['Boolean']['output']>;
  /** The scale's displayed name */
  name: Scalars['String']['output'];
};

/** An application permission */
export type Permission = {
  __typename?: 'Permission';
  /** Identifies the primary key from the database */
  id: Scalars['Int']['output'];
  /** The descriptor for the permission */
  name: Scalars['String']['output'];
  /** The application the permission is for */
  type: PermissionType;
};

export type PermissionInput = {
  defaultTab?: InputMaybe<Scalars['Boolean']['input']>;
  permissionId: Scalars['ID']['input'];
};

/** The application associated with the permission */
export enum PermissionType {
  /** A permission for the ASP.NET Viewpoint application */
  Aspvp = 'ASPVP',
  /** A permission for any other application */
  Other = 'OTHER',
  /** A permission for ViewpointNext */
  Vpnext = 'VPNEXT'
}

export type Query = {
  __typename?: 'Query';
  absenceCountByDate: Scalars['Int']['output'];
  absenceCountByTerm: Scalars['Int']['output'];
  accessibleReferenceTypeColumns: Array<Maybe<ReferenceTypeColumn>>;
  accessibleStudentDemographics: StudentDemographicAccess;
  /**
   * Retrieves individual detail section.
   * Exclusive to system administrators.
   */
  advancedPopulationFilter: AdvancedPopulationFilter;
  /**
   * Retrieves full list of report detail sections.
   * Exclusive to system administrators.
   */
  advancedPopulationFilters: AdvancedPopulationFilterConnection;
  attribute: Attribute;
  /**
   * Retrieves a list of supplemental data points.
   * Exclusive to system administrators.
   */
  attributes: AttributeConnection;
  /**
   * Retrieves a website configuration value.
   * Exclusive to system administrators.
   */
  configuration?: Maybe<Configuration>;
  /**
   * Retrieves a list of website configuration values.
   * Exclusive to system administrators.
   */
  configurations: ConfigurationConnection;
  /**
   * Retrieves a list of website configuration values for a set of given keys.
   * Exclusive to system administrators.
   */
  configurationsByKeys: Array<Maybe<Configuration>>;
  /** Fetches course by course id. */
  course?: Maybe<Course>;
  /** Fetches course group by course group id. */
  courseGroup?: Maybe<CourseGroup>;
  /** Obtains the course section for this course section id. */
  courseSectionById?: Maybe<CourseSection>;
  /** Fetches the list of course sections with instructor levels for the given Employee. */
  courseSectionInstructorByEmployee: Array<Instructor>;
  /**
   * Gets a list of currently in progress grade marks by a given student id, term, course and, if exists, course section.
   * This requires that the current grades module is active. Currently ND and NE only.
   */
  currentGradeMarksByStudentId: GradeMarkConnection;
  currentSchoolYear?: Maybe<SchoolYear>;
  defaultTestPerformanceScalesByScoreType?: Maybe<Array<TestPerformanceScale>>;
  /** Fetches department by department id. */
  department?: Maybe<Department>;
  /** Collects the disciplinary incidents attached to a student. */
  disciplinaryIncidentsByStudentId: Array<Maybe<DisciplinaryIncident>>;
  documentsByStudentId: Array<Maybe<Document>>;
  /** Get an employee by its unique identifier */
  employee?: Maybe<Employee>;
  gender?: Maybe<Gender>;
  getActivitiesByStudent: Array<StudentActivity>;
  gradeLevel?: Maybe<GradeLevel>;
  /** Gets a list of grade marks by a given student id, school year, term, course and, if exists, course section. */
  gradeMarksByStudentId: GradeMarkConnection;
  healthConcernsByStudent: Array<Maybe<HealthConcern>>;
  immunizationsByStudent: Array<Maybe<Immunization>>;
  /** Fetches the list of instructors for the given Course Section. */
  instructorByCourseSectionId: Array<Instructor>;
  /**
   * Retrieves a list of interdistrict exchange configuration values.
   * Exclusive to system administrators.
   */
  interdistrictExchangeConfiguration?: Maybe<InterdistrictExchangeConfiguration>;
  listOfAbsenceSummaryTypes: Array<Maybe<AbsenceSummaryType>>;
  listOfAbsenceTypes: Array<Maybe<AbsenceType>>;
  /** Get list of activities. */
  listOfActivities: Array<Activity>;
  listOfDemographicMappingGroups: Array<Maybe<DemographicMappingGroup>>;
  listOfDemographicMappingMembers: Array<Maybe<DemographicMappingMember>>;
  /** Supplies a list of all possible Disciplinary Actions */
  listOfDisciplinaryActions: Array<Maybe<DisciplinaryAction>>;
  /** Supplies a list of all possible Disciplinary Incident Locations */
  listOfDisciplinaryIncidentLocations: Array<Maybe<DisciplinaryIncidentLocation>>;
  /** Supplies a list of all possible Disciplinary Incident Types */
  listOfDisciplinaryIncidentTypes: Array<Maybe<DisciplinaryIncidentType>>;
  listOfDocumentTypeNames: Array<Maybe<Scalars['String']['output']>>;
  /** Gets the full list of letter grade mark options. */
  listOfGradeMarkOptions: Array<GradeMarkOption>;
  listOfImmunizationComplianceFlags: Array<Maybe<ImmunizationComplianceFlag>>;
  listOfInterventionCategories: Array<Maybe<InterventionCategory>>;
  listOfInterventionReferralFlags: Array<Maybe<InterventionReferralFlag>>;
  listOfInterventionStateReportedFlags: Array<Maybe<InterventionStateReportedFlag>>;
  listOfInterventionStatuses: Array<Maybe<InterventionStatus>>;
  listOfInterventions: Array<Maybe<Intervention>>;
  listOfPerformanceBandingSets: PerformanceBandingSetConnection;
  listOfPermissions: Array<Maybe<Permission>>;
  listOfTestOutlines: TestOutlineConnection;
  listOfTestSubjectGroups: Array<Maybe<TestSubjectGroup>>;
  listOfVaccinationExemptions: Array<Maybe<VaccinationExemption>>;
  listOfVaccinations: Array<Maybe<Vaccination>>;
  manualTestScoreEntries: TestScoreConnection;
  /**
   * Provides MARSS B Enrollment data.
   * Exclusive to MN Only.
   */
  marssEnrollmentsByStudent: MarssEnrollmentConnection;
  /** Fetch an OTP by email. Gets the latest valid (not expired) OTP. */
  otp?: Maybe<Otp>;
  performanceScale?: Maybe<PerformanceScale>;
  /**
   * Retrieves a list of all reference parameter value mappings.
   * Exclusive to system administrators.
   */
  referenceParameterSources: ReferenceParameterSourceConnection;
  referenceParameters: ReferenceParameterConnection;
  referenceTypes: ReferenceTypeConnection;
  /** Retrieves a report by its ID. */
  report?: Maybe<Report>;
  /** Retrieves a list of all report categories. */
  reportCategories?: Maybe<Array<Maybe<ReportCategory>>>;
  /**
   * Retrieves individual detail section.
   * Exclusive to system administrators.
   */
  reportDetailSection: ReportDetailSection;
  /**
   * Retrieves full list of report detail sections.
   * Exclusive to system administrators.
   */
  reportDetailSections: ReportDetailSectionConnection;
  /**
   * Retrieves a list of all report rule categories.
   * Exclusive to system administrators.
   */
  reportRuleCategories: ReportRuleCategoryConnection;
  /**
   * Retrieves specific report rule category.
   * Exclusive to system administrators.
   */
  reportRuleCategory: ReportRuleCategory;
  /** Retrieves a list of all report types. */
  reportTypes?: Maybe<Array<Maybe<ReportType>>>;
  /**
   * Retrieves a list of all reports.
   * Exclusive to system administrators.
   */
  reports?: Maybe<ReportConnection>;
  /** Get a role by its unique identifier */
  role?: Maybe<Role>;
  /** Get all roles */
  roles: Array<Role>;
  /** Get a school by its unique identifier */
  school?: Maybe<School>;
  /**
   * Retrieves a district-specific configuration value.
   * Exclusive to system administrators.
   */
  schoolConfiguration?: Maybe<SchoolConfiguration>;
  /**
   * Retrieves a list of district-specific configuration values.
   * Exclusive to system administrators.
   */
  schoolConfigurations: SchoolConfigurationConnection;
  schoolYear?: Maybe<SchoolYear>;
  /** Get all schools */
  schools: Array<School>;
  /** Looks up a student's schedule. Limits by given school year id and term id, if given. */
  sectionRosterByStudent: SectionRosterConnection;
  serverStatus?: Maybe<ServerStatus>;
  student?: Maybe<Student>;
  studentEnrollmentsNotInExtract: Array<Maybe<StudentEnrollment>>;
  /**
   * Returns list of tabs containing filters for various criteria of data.
   * Exclusive to system administrators.
   */
  studentFilterTabs?: Maybe<StudentFilterTabConnection>;
  studentInterventionsByStudentId: Array<Maybe<StudentIntervention>>;
  studentProgramsByStudentId: Array<Maybe<StudentProgram>>;
  studentServicesByStudentId: Array<Maybe<StudentService>>;
  studentTestPerformance: StudentReport;
  students?: Maybe<Array<Student>>;
  /** Looks up a course section's list of students. */
  studentsByCourseSection: SectionRosterConnection;
  studentsByPopulation?: Maybe<Array<Student>>;
  termByTermId?: Maybe<Term>;
  test?: Maybe<Test>;
  testPerformanceScale?: Maybe<TestPerformanceScale>;
  testPerformanceScalesBySet?: Maybe<Array<TestPerformanceScale>>;
  testScore?: Maybe<TestScore>;
  /** Look up assessments */
  tests: TestConnection;
  testsWithLoadedScores?: Maybe<Array<Test>>;
  /** Look up an individual user by user id */
  user?: Maybe<User>;
  /** Look up an individual user by email */
  userByEmail?: Maybe<User>;
  /** Look up an individual user by username */
  userByUsername?: Maybe<User>;
  userRosterDashboardAssessments: Array<UserRosterDashboardAssessment>;
  /** Look up users */
  users: UserConnection;
};


export type QueryAbsenceCountByDateArgs = {
  absenceSummaryTypeId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  isTardy: Scalars['Boolean']['input'];
  maximumDayPercentage?: InputMaybe<Scalars['Float']['input']>;
  minimumDayPercentage?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  studentId: Scalars['Int']['input'];
  units?: InputMaybe<AbsenceDayFraction>;
};


export type QueryAbsenceCountByTermArgs = {
  absenceSummaryTypeId?: InputMaybe<Scalars['Int']['input']>;
  isTardy: Scalars['Boolean']['input'];
  maximumDayPercentage?: InputMaybe<Scalars['Float']['input']>;
  minimumDayPercentage?: InputMaybe<Scalars['Float']['input']>;
  schoolYearId: Scalars['Int']['input'];
  studentId: Scalars['Int']['input'];
  termId?: InputMaybe<Scalars['Int']['input']>;
  units?: InputMaybe<AbsenceDayFraction>;
};


export type QueryAccessibleReferenceTypeColumnsArgs = {
  schoolId: Scalars['ID']['input'];
};


export type QueryAccessibleStudentDemographicsArgs = {
  schoolId: Scalars['ID']['input'];
};


export type QueryAdvancedPopulationFilterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdvancedPopulationFiltersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AdvancedPopulationFilterOrder>;
  permittedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAttributeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAttributesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AttributeOrder>;
  permittedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryConfigurationArgs = {
  key: Scalars['String']['input'];
};


export type QueryConfigurationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<ConfigurationType>;
};


export type QueryConfigurationsByKeysArgs = {
  keys: Array<Scalars['String']['input']>;
};


export type QueryCourseArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCourseGroupArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCourseSectionByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCourseSectionInstructorByEmployeeArgs = {
  employeeId: Scalars['Int']['input'];
};


export type QueryCurrentGradeMarksByStudentIdArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  courseSectionId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  studentId: Scalars['ID']['input'];
  termId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDefaultTestPerformanceScalesByScoreTypeArgs = {
  input: DefaultTestPerformanceScalesByScoreTypeInput;
};


export type QueryDepartmentArgs = {
  id: Scalars['Int']['input'];
};


export type QueryDisciplinaryIncidentsByStudentIdArgs = {
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  schoolYearId?: InputMaybe<Scalars['ID']['input']>;
  studentId: Scalars['ID']['input'];
  termId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDocumentsByStudentIdArgs = {
  studentId: Scalars['ID']['input'];
};


export type QueryEmployeeArgs = {
  employeeCode: Scalars['String']['input'];
};


export type QueryGenderArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetActivitiesByStudentArgs = {
  studentEnrollmentId?: InputMaybe<Scalars['Int']['input']>;
  studentId: Scalars['Int']['input'];
};


export type QueryGradeLevelArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGradeMarksByStudentIdArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  courseSectionId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolYearId?: InputMaybe<Scalars['ID']['input']>;
  studentId: Scalars['ID']['input'];
  termId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryHealthConcernsByStudentArgs = {
  studentId: Scalars['ID']['input'];
};


export type QueryImmunizationsByStudentArgs = {
  studentId: Scalars['ID']['input'];
};


export type QueryInstructorByCourseSectionIdArgs = {
  courseSectionId: Scalars['Int']['input'];
};


export type QueryInterdistrictExchangeConfigurationArgs = {
  schoolId: Scalars['ID']['input'];
};


export type QueryListOfDemographicMappingMembersArgs = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryListOfPerformanceBandingSetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scaleBandingIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  scoreTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  testIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryListOfTestOutlinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  instanceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  outlineIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  schoolYearIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  seasonIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  testIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  types?: InputMaybe<Array<InputMaybe<OutlineType>>>;
};


export type QueryManualTestScoreEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  entryTypes?: InputMaybe<Array<InputMaybe<TestScoreEntryType>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input: ManualTestScoreInput;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMarssEnrollmentsByStudentArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  schoolYearId?: InputMaybe<Scalars['ID']['input']>;
  studentId: Scalars['ID']['input'];
};


export type QueryOtpArgs = {
  email: Scalars['String']['input'];
};


export type QueryPerformanceScaleArgs = {
  id: Scalars['Int']['input'];
};


export type QueryReferenceParameterSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  referenceParameterId?: InputMaybe<Scalars['ID']['input']>;
  referenceTypeId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryReferenceParametersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  referenceTypeId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryReferenceTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReportDetailSectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReportDetailSectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportDetailSectionOrder>;
  permittedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  reportId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryReportRuleCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportRuleCategoryOrder>;
  permittedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryReportRuleCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<ReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportOrder>;
  permittedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySchoolArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySchoolConfigurationArgs = {
  key: Scalars['String']['input'];
  schoolId: Scalars['ID']['input'];
};


export type QuerySchoolConfigurationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolId: Scalars['ID']['input'];
  type?: InputMaybe<SchoolConfigurationType>;
};


export type QuerySchoolYearArgs = {
  id: Scalars['Int']['input'];
  visibility?: InputMaybe<Visibility>;
};


export type QuerySectionRosterByStudentArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolYearId?: InputMaybe<Scalars['ID']['input']>;
  studentId: Scalars['ID']['input'];
  termId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryStudentArgs = {
  id: Scalars['Int']['input'];
};


export type QueryStudentEnrollmentsNotInExtractArgs = {
  extractDate: Scalars['DateTime']['input'];
  schoolId: Scalars['ID']['input'];
  schoolYearId: Scalars['ID']['input'];
};


export type QueryStudentFilterTabsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStudentInterventionsByStudentIdArgs = {
  studentId: Scalars['ID']['input'];
};


export type QueryStudentProgramsByStudentIdArgs = {
  studentId: Scalars['ID']['input'];
};


export type QueryStudentServicesByStudentIdArgs = {
  studentId: Scalars['ID']['input'];
};


export type QueryStudentTestPerformanceArgs = {
  filterBy?: InputMaybe<StudentFilter>;
  studentPopulation?: InputMaybe<StudentPopulationInput>;
  testScore: ReportTestScoreInput;
};


export type QueryStudentsArgs = {
  filterBy?: InputMaybe<StudentFilter>;
  orderBy?: InputMaybe<StudentOrder>;
  studentPopulation?: InputMaybe<StudentPopulationInput>;
};


export type QueryStudentsByCourseSectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  courseSectionId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStudentsByPopulationArgs = {
  filterBy?: InputMaybe<StudentFilter>;
  orderBy?: InputMaybe<StudentOrder>;
  studentPopulation?: InputMaybe<StudentPopulationInput>;
};


export type QueryTermByTermIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTestArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTestPerformanceScaleArgs = {
  input: TestPerformanceScaleInput;
};


export type QueryTestPerformanceScalesBySetArgs = {
  input: TestPerformanceScalesBySetInput;
};


export type QueryTestScoreArgs = {
  input: TestScoreInput;
};


export type QueryTestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<TestFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TestOrder>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
  statuses?: InputMaybe<Array<UserStatus>>;
};


export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryUserByUsernameArgs = {
  username: Scalars['ID']['input'];
};


export type QueryUserRosterDashboardAssessmentsArgs = {
  reportId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrder>;
  status?: InputMaybe<UserStatus>;
};

export type ReferenceParameter = Node & {
  __typename?: 'ReferenceParameter';
  chartColorCode: Scalars['String']['output'];
  chartColorId: Scalars['ID']['output'];
  displayOrder: Scalars['Int']['output'];
  displayed: Scalars['Boolean']['output'];
  extraData?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  parentGroup?: Maybe<ReferenceParameter>;
  precedentOrder: Scalars['Int']['output'];
  shortName?: Maybe<Scalars['String']['output']>;
  /**
   * Retrieves a list of all value mappings for this reference parameter.
   * Exclusive to system administrators.
   */
  sources: ReferenceParameterSourceConnection;
  type: ReferenceType;
  updateable: Scalars['Boolean']['output'];
  userDefined1?: Maybe<Scalars['String']['output']>;
  userDefined2?: Maybe<Scalars['String']['output']>;
  userDefined3?: Maybe<Scalars['String']['output']>;
  userDefined4?: Maybe<Scalars['String']['output']>;
  userDefined5?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};


export type ReferenceParameterSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ReferenceParameterConnection = Connection & {
  __typename?: 'ReferenceParameterConnection';
  edges?: Maybe<Array<Maybe<ReferenceParameterEdge>>>;
  nodes?: Maybe<Array<Maybe<ReferenceParameter>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReferenceParameterEdge = Edge & {
  __typename?: 'ReferenceParameterEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ReferenceParameter>;
};

export type ReferenceParameterSource = Node & {
  __typename?: 'ReferenceParameterSource';
  id: Scalars['ID']['output'];
  parameter: ReferenceParameter;
  sourceCode?: Maybe<Scalars['String']['output']>;
  type: ReferenceType;
};

export type ReferenceParameterSourceConnection = Connection & {
  __typename?: 'ReferenceParameterSourceConnection';
  edges?: Maybe<Array<Maybe<ReferenceParameterSourceEdge>>>;
  nodes?: Maybe<Array<Maybe<ReferenceParameterSource>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReferenceParameterSourceEdge = Edge & {
  __typename?: 'ReferenceParameterSourceEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ReferenceParameterSource>;
};

export type ReferenceType = Node & {
  __typename?: 'ReferenceType';
  code?: Maybe<Scalars['String']['output']>;
  defaultParameter?: Maybe<ReferenceParameter>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parameters: ReferenceParameterConnection;
  parentType?: Maybe<ReferenceType>;
};


export type ReferenceTypeParametersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ReferenceTypeColumn = {
  __typename?: 'ReferenceTypeColumn';
  columnName: Scalars['String']['output'];
  referenceType?: Maybe<ReferenceType>;
};

export type ReferenceTypeConnection = Connection & {
  __typename?: 'ReferenceTypeConnection';
  edges?: Maybe<Array<Maybe<ReferenceTypeEdge>>>;
  nodes?: Maybe<Array<Maybe<ReferenceType>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReferenceTypeEdge = Edge & {
  __typename?: 'ReferenceTypeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ReferenceType>;
};

export type ReorderUserRosterDashboardAssessmentsInput = {
  id: Scalars['Int']['input'];
  newDisplayOrder: Scalars['Int']['input'];
  reportId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type Report = Node & {
  __typename?: 'Report';
  caption: Scalars['String']['output'];
  category: ReportCategory;
  /**
   * Retrieves a list of all roles configured for this report at the district level.
   * Exclusive to system administrators.
   */
  districtRoles?: Maybe<ReportRoleConnection>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /**
   * Retrieves a list of all roles and role configurations for this report.
   * Exclusive to system administrators.
   */
  roles?: Maybe<ReportRoleConnection>;
  type: ReportType;
  url: Scalars['String']['output'];
};


export type ReportDistrictRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ReportRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
};

export type ReportCategory = {
  __typename?: 'ReportCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ReportConnection = Connection & {
  __typename?: 'ReportConnection';
  edges?: Maybe<Array<Maybe<ReportEdge>>>;
  nodes?: Maybe<Array<Maybe<Report>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReportDetailSection = Node & {
  __typename?: 'ReportDetailSection';
  /**
   * Returns a list of district-level roles and their configurations for this report detail section.
   * Exclusive to system administrators.
   */
  districtRoles?: Maybe<ReportDetailSectionRoleConnection>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  report?: Maybe<Report>;
  /**
   * Returns a list of roles and their configurations for this report detail section.
   * Exclusive to system administrators.
   */
  roles?: Maybe<ReportDetailSectionRoleConnection>;
};


export type ReportDetailSectionDistrictRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ReportDetailSectionRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
};

export type ReportDetailSectionConnection = Connection & {
  __typename?: 'ReportDetailSectionConnection';
  edges?: Maybe<Array<Maybe<ReportDetailSectionEdge>>>;
  nodes?: Maybe<Array<Maybe<ReportDetailSection>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReportDetailSectionEdge = Edge & {
  __typename?: 'ReportDetailSectionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ReportDetailSection>;
};

/** Fields for ordering a list of reports */
export type ReportDetailSectionOrder = {
  direction: OrderDirection;
  field: ReportDetailSectionOrderField;
};

/** Properties that can be ordered/filtered for a report */
export enum ReportDetailSectionOrderField {
  Id = 'id',
  Name = 'name',
  Report = 'report'
}

export type ReportDetailSectionRole = Node & {
  __typename?: 'ReportDetailSectionRole';
  id: Scalars['ID']['output'];
  permitted: Scalars['Boolean']['output'];
  role: Role;
  school: School;
  section: ReportDetailSection;
};

export type ReportDetailSectionRoleConnection = Connection & {
  __typename?: 'ReportDetailSectionRoleConnection';
  edges?: Maybe<Array<Maybe<ReportDetailSectionRoleEdge>>>;
  nodes?: Maybe<Array<Maybe<ReportDetailSectionRole>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReportDetailSectionRoleEdge = Edge & {
  __typename?: 'ReportDetailSectionRoleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ReportDetailSectionRole>;
};

export type ReportDetailSectionRoleInput = {
  permitted: Scalars['Boolean']['input'];
  roleId: Scalars['ID']['input'];
  schoolId: Scalars['ID']['input'];
  sectionId: Scalars['ID']['input'];
};

export type ReportEdge = Edge & {
  __typename?: 'ReportEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Report>;
};

/** Fields for filtering a list of reports */
export type ReportFilter = {
  /** Filter by matching text against name */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Fields for ordering a list of reports */
export type ReportOrder = {
  direction: OrderDirection;
  field: ReportOrderField;
};

/** Properties that can be ordered/filtered for a report */
export enum ReportOrderField {
  Category = 'category',
  Id = 'id',
  Name = 'name'
}

export type ReportRole = Node & {
  __typename?: 'ReportRole';
  favorite?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  permitted: Scalars['Boolean']['output'];
  report: Report;
  role: Role;
  school: School;
};

export type ReportRoleConnection = Connection & {
  __typename?: 'ReportRoleConnection';
  edges?: Maybe<Array<Maybe<ReportRoleEdge>>>;
  nodes?: Maybe<Array<Maybe<ReportRole>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReportRoleEdge = Edge & {
  __typename?: 'ReportRoleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ReportRole>;
};

export type ReportRoleInput = {
  favorite: Scalars['Boolean']['input'];
  permitted: Scalars['Boolean']['input'];
  reportId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  schoolId: Scalars['ID']['input'];
};

export type ReportRoleSetItemInput = {
  favorite?: InputMaybe<Scalars['Boolean']['input']>;
  permitted: Scalars['Boolean']['input'];
  reportId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  schoolId: Scalars['ID']['input'];
};

export type ReportRuleCategory = Node & {
  __typename?: 'ReportRuleCategory';
  /**
   * Returns list of roles and configurations at the district level for the report rule categories.
   * Exclusive to system administrators.
   */
  districtRoles: ReportRuleCategoryRoleConnection;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /**
   * Returns list of roles and configurations for the report rule categories for the specified school id.
   * Exclusive to system administrators.
   */
  roles: ReportRuleCategoryRoleConnection;
};


export type ReportRuleCategoryDistrictRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ReportRuleCategoryRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
};

export type ReportRuleCategoryConnection = Connection & {
  __typename?: 'ReportRuleCategoryConnection';
  edges?: Maybe<Array<Maybe<ReportRuleCategoryEdge>>>;
  nodes?: Maybe<Array<Maybe<ReportRuleCategory>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReportRuleCategoryEdge = Edge & {
  __typename?: 'ReportRuleCategoryEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ReportRuleCategory>;
};

/** Fields for ordering a list of report rule categories */
export type ReportRuleCategoryOrder = {
  direction: OrderDirection;
  field: ReportRuleCategoryOrderField;
};

/** Properties that can be ordered/filtered for a report rule categories */
export enum ReportRuleCategoryOrderField {
  Id = 'id',
  Name = 'name'
}

export type ReportRuleCategoryRole = Node & {
  __typename?: 'ReportRuleCategoryRole';
  id: Scalars['ID']['output'];
  permitted: Scalars['Boolean']['output'];
  role: Role;
  ruleCategory: ReportRuleCategory;
  school: School;
};

export type ReportRuleCategoryRoleConnection = Connection & {
  __typename?: 'ReportRuleCategoryRoleConnection';
  edges?: Maybe<Array<Maybe<ReportRuleCategoryRoleEdge>>>;
  nodes?: Maybe<Array<Maybe<ReportRuleCategoryRole>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReportRuleCategoryRoleEdge = Edge & {
  __typename?: 'ReportRuleCategoryRoleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ReportRuleCategoryRole>;
};

export type ReportRuleCategoryRoleInput = {
  permitted: Scalars['Boolean']['input'];
  roleId: Scalars['ID']['input'];
  ruleCategoryId: Scalars['ID']['input'];
  schoolId: Scalars['ID']['input'];
};

export type ReportSeries = {
  __typename?: 'ReportSeries';
  data: Array<Maybe<Scalars['Float']['output']>>;
  drilldown?: Maybe<Array<Maybe<ReportSeries>>>;
  name: Scalars['String']['output'];
};

export type ReportTestScoreInput = {
  /** The grouping method to aggregate the scores */
  groupBy: TestScoreGroupingMethod;
  /** The category being tested */
  outlineId?: InputMaybe<Scalars['Int']['input']>;
  /** The performance scale banding set. */
  performanceSetId?: InputMaybe<Scalars['Int']['input']>;
  /** The school year when the test was taken */
  schoolYearId?: InputMaybe<Scalars['Int']['input']>;
  /** The type of score */
  scoreTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** When the test was taken */
  seasonId?: InputMaybe<Scalars['Int']['input']>;
  /** The version of test */
  variantId?: InputMaybe<Scalars['Int']['input']>;
};

export type ReportType = {
  __typename?: 'ReportType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** A group of permissions */
export type Role = {
  __typename?: 'Role';
  /** Identifies the primary key from the database */
  id: Scalars['ID']['output'];
  /** The descriptor for the role */
  name: Scalars['String']['output'];
  /** The list of permissions for the role */
  permissions: Array<Permission>;
  /** Collection of Permissions and their default tab designation. */
  rolePermissions: Array<Maybe<RolePermission>>;
};

/** Role ids to filter */
export type RoleFilter = {
  contains?: InputMaybe<Array<Scalars['ID']['input']>>;
  doesNotContain?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type RoleInput = {
  /**
   * Identifier for the role.
   * Do not include if adding.
   * Required for editing.
   */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Name of the role */
  name: Scalars['String']['input'];
  /**
   * Permissions for the role.
   * Only include if the permissions are actually changing.
   */
  permissions?: InputMaybe<Array<InputMaybe<PermissionInput>>>;
};

export type RolePermission = {
  __typename?: 'RolePermission';
  /** If the permission is for a tab, is it a default tab for the given role id? */
  defaultTab?: Maybe<Scalars['Boolean']['output']>;
  /** The connected Permission */
  permission: Permission;
  /** The connected Role */
  role: Role;
};

/** A Viewpoint primary education school. Can be an entire area of districts, a district of schools, or a single school */
export type School = {
  __typename?: 'School';
  /** Identifies the date and time when the object was last updated */
  dateChanged: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was created */
  dateCreated: Scalars['DateTime']['output'];
  /** Enrollments at the school, by school year. */
  enrollments: StudentEnrollmentConnection;
  /** Identifies the primary key from the database */
  id: Scalars['ID']['output'];
  /** The school's level within the heirarchy (a school, district, etc.) */
  level: Scalars['String']['output'];
  /** The school's displayed name */
  name: Scalars['String']['output'];
  /** Identifies the type of school */
  type?: Maybe<Scalars['String']['output']>;
};


/** A Viewpoint primary education school. Can be an entire area of districts, a district of schools, or a single school */
export type SchoolEnrollmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  enrollmentStatuses?: InputMaybe<Array<InputMaybe<StudentEnrollmentStatus>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  gradeLevelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolYearIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type SchoolConfiguration = Node & {
  __typename?: 'SchoolConfiguration';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  school: School;
  value: Scalars['String']['output'];
};

export type SchoolConfigurationConnection = Connection & {
  __typename?: 'SchoolConfigurationConnection';
  edges?: Maybe<Array<Maybe<SchoolConfigurationEdge>>>;
  nodes?: Maybe<Array<Maybe<SchoolConfiguration>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SchoolConfigurationEdge = Edge & {
  __typename?: 'SchoolConfigurationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<SchoolConfiguration>;
};

export enum SchoolConfigurationType {
  District = 'DISTRICT',
  Invalid = 'INVALID',
  MessageCenter = 'MESSAGE_CENTER'
}

/** School ids to filter */
export type SchoolFilter = {
  contains?: InputMaybe<Array<Scalars['ID']['input']>>;
  doesNotContain?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** The category of a Viewpoint primary education school. Either an area (group of districts), a district (group of schools), or a school */
export enum SchoolLevel {
  /** Defines a group of one or more districts */
  Area = 'AREA',
  /** Defines a group of one or more schools */
  District = 'DISTRICT',
  /** An individual school */
  School = 'SCHOOL'
}

export type SchoolPeriod = {
  __typename?: 'SchoolPeriod';
  /** Identifies the date and time when the object was last updated. */
  dateChanged: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was created. */
  dateCreated: Scalars['DateTime']['output'];
  /** Identifies the primary key from the database. */
  id: Scalars['ID']['output'];
  /** The period's displayed name. */
  name: Scalars['String']['output'];
};

export type SchoolTerm = {
  __typename?: 'SchoolTerm';
  /** Identifies the date and time when the object was last updated. */
  dateChanged: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was created. */
  dateCreated: Scalars['DateTime']['output'];
  /** Identifies the primary key from the database. */
  id: Scalars['Int']['output'];
  /** The term's displayed name. */
  name: Scalars['String']['output'];
};

export type SchoolYear = {
  __typename?: 'SchoolYear';
  /** Identifies the date and time when the object was last updated. */
  dateChanged: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was created. */
  dateCreated: Scalars['DateTime']['output'];
  /** The end year of the school year. */
  endYear: Scalars['Int']['output'];
  /** Identifies the primary key from the database. */
  id: Scalars['Int']['output'];
  /** The school year's displayed name. */
  name: Scalars['String']['output'];
  /** The start year of the school year. */
  startYear: Scalars['Int']['output'];
};

export enum ScoreType {
  Other = 'OTHER',
  Raw = 'RAW'
}

export enum ScoreTypePriority {
  Other = 'OTHER',
  Primary = 'PRIMARY'
}

/** https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38810 */
export enum Section504Status {
  DataNotAvailable = 'DATA_NOT_AVAILABLE',
  No = 'NO',
  Yes = 'YES'
}

export type Section504StatusFilter = {
  /** In */
  contains?: InputMaybe<Array<Section504Status>>;
  /** Not in */
  doesNotContain?: InputMaybe<Array<Section504Status>>;
};

export type SectionRoster = Node & {
  __typename?: 'SectionRoster';
  /** Course Section rostered for this student */
  courseSection: CourseSection;
  /** Date that this student has been rostered into this course section. */
  entryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Referential ID for this section roster record. */
  id: Scalars['ID']['output'];
  /** Rostering Status for this student for this course section. */
  rosterStatus: SectionRosterStatus;
  /** Student rostered for this class. */
  student: Student;
  /** Date that the student withdrew. */
  withdrawalDate?: Maybe<Scalars['DateTime']['output']>;
  /** Reference ID of the Withdrawal Type. */
  withdrawalTypeId?: Maybe<Scalars['Int']['output']>;
  /** Withdrawal Type Name. */
  withdrawalTypeName?: Maybe<Scalars['String']['output']>;
};

export type SectionRosterConnection = Connection & {
  __typename?: 'SectionRosterConnection';
  edges?: Maybe<Array<Maybe<SectionRosterEdge>>>;
  nodes?: Maybe<Array<Maybe<SectionRoster>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SectionRosterEdge = Edge & {
  __typename?: 'SectionRosterEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<SectionRoster>;
};

export enum SectionRosterStatus {
  Completed = 'COMPLETED',
  Invalid = 'INVALID',
  InProcess = 'IN_PROCESS',
  Withdrawn = 'WITHDRAWN'
}

export type ServerStatus = {
  __typename?: 'ServerStatus';
  database?: Maybe<DatabaseStatus>;
};

export type StringFilter = {
  /** Contains */
  contains?: InputMaybe<Scalars['String']['input']>;
  /** Does not contain */
  doesNotContain?: InputMaybe<Scalars['String']['input']>;
};

export type Student = {
  __typename?: 'Student';
  /** Student's Absences by Type and Period. */
  absenceDetails: StudentAbsenceDetailConnection;
  /** Student's Absences. */
  absences: StudentAbsenceConnection;
  /** The student's birth date */
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  /** Identifies the date and time when the object was last updated. */
  dateChanged: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was created. */
  dateCreated: Scalars['DateTime']['output'];
  /** Disciplinary Incidences */
  discipline?: Maybe<Array<Maybe<DisciplinaryIncident>>>;
  /** The Student's District-specific unique identifier */
  districtStudentNumber?: Maybe<Scalars['String']['output']>;
  /** Documents attached to the student. */
  documents: Array<Maybe<Document>>;
  /** Enrollments For This Student */
  enrollments?: Maybe<Array<StudentEnrollment>>;
  /** The student's first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The student's identified gender */
  gender?: Maybe<Gender>;
  /** The student's grade marks. */
  gradeMarks: GradeMarkConnection;
  /** The student's health concerns. */
  healthConcerns?: Maybe<Array<Maybe<HealthConcern>>>;
  /** Identifies the primary key from the database. */
  id: Scalars['Int']['output'];
  /** The student's immunization history. */
  immunizations?: Maybe<Array<Maybe<Immunization>>>;
  /** Interventions attached to the student. */
  interventions: Array<Maybe<StudentIntervention>>;
  /** The student's last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** MARSS B file enrollment data attached to the student (MN Only!) */
  marssEnrollments: MarssEnrollmentConnection;
  /** The student's middle name */
  middleName?: Maybe<Scalars['String']['output']>;
  /** The student's full name */
  name?: Maybe<Scalars['String']['output']>;
  /** The student's name suffix */
  nameSuffix?: Maybe<Scalars['String']['output']>;
  /** Student's Primary Enrollment - Defaults to latest year's, most active, primary school enrollment. */
  primaryEnrollment?: Maybe<StudentEnrollment>;
  /** Programs that the student uses. */
  programs: Array<Maybe<StudentProgram>>;
  /** The student's course schedule. */
  schedule: SectionRosterConnection;
  /** Services that the student uses. */
  services: Array<Maybe<StudentService>>;
  /** The Student's State-provided unique identifier */
  stateStudentNumber?: Maybe<Scalars['String']['output']>;
  /** A student's specific test score */
  testScore?: Maybe<TestScore>;
  /** A student's list of test scores. */
  testScores: TestScoreConnection;
};


export type StudentAbsenceDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolYearId?: InputMaybe<Scalars['Int']['input']>;
  termId?: InputMaybe<Scalars['Int']['input']>;
};


export type StudentAbsencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolYearId?: InputMaybe<Scalars['Int']['input']>;
  termId?: InputMaybe<Scalars['Int']['input']>;
};


export type StudentDisciplineArgs = {
  schoolYearId?: InputMaybe<Scalars['Int']['input']>;
  termId?: InputMaybe<Scalars['Int']['input']>;
};


export type StudentEnrollmentsArgs = {
  schoolYearIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type StudentGradeMarksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  courseSectionId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolYearId?: InputMaybe<Scalars['ID']['input']>;
  termId?: InputMaybe<Scalars['ID']['input']>;
};


export type StudentMarssEnrollmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  schoolYearId?: InputMaybe<Scalars['ID']['input']>;
};


export type StudentPrimaryEnrollmentArgs = {
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  schoolYearId?: InputMaybe<Scalars['Int']['input']>;
};


export type StudentScheduleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolYearId?: InputMaybe<Scalars['ID']['input']>;
  termId?: InputMaybe<Scalars['ID']['input']>;
};


export type StudentTestScoreArgs = {
  input: StudentTestScoreInput;
};


export type StudentTestScoresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolYearIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  testIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  testRegions?: InputMaybe<Array<InputMaybe<TestRegion>>>;
  testSubjectGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type StudentAbsence = Node & {
  __typename?: 'StudentAbsence';
  /** Identifies the portion of the day that the student was absent */
  absenceDayFraction?: Maybe<AbsenceDayFraction>;
  /** The date of the absence. */
  date: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was last updated. */
  dateChanged: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was created. */
  dateCreated: Scalars['DateTime']['output'];
  /** The period(s) when the absence(s) took place. */
  details: Array<StudentAbsenceDetail>;
  /** Identifies the primary key from the database. */
  id: Scalars['ID']['output'];
  /** The term when the absence(s) took place. */
  term: Term;
};

export type StudentAbsenceConnection = Connection & {
  __typename?: 'StudentAbsenceConnection';
  edges?: Maybe<Array<Maybe<StudentAbsenceEdge>>>;
  nodes?: Maybe<Array<Maybe<StudentAbsence>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StudentAbsenceDetail = Node & {
  __typename?: 'StudentAbsenceDetail';
  /** Specifies the date, term, and absence fraction of the absence. */
  absenceDateInformation: StudentAbsence;
  /** Identifies the date and time when the object was last updated. */
  dateChanged: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was created. */
  dateCreated: Scalars['DateTime']['output'];
  /** Identifies the primary key from the database. */
  id: Scalars['ID']['output'];
  /** Whether or not the absence is a tardy. */
  isTardy?: Maybe<Scalars['Boolean']['output']>;
  /** The period when the absence took place. */
  period?: Maybe<SchoolPeriod>;
  /** The type of absence that took place. */
  type: AbsenceType;
};

export type StudentAbsenceDetailConnection = Connection & {
  __typename?: 'StudentAbsenceDetailConnection';
  edges?: Maybe<Array<Maybe<StudentAbsenceDetailEdge>>>;
  nodes?: Maybe<Array<Maybe<StudentAbsenceDetail>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StudentAbsenceDetailEdge = Edge & {
  __typename?: 'StudentAbsenceDetailEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<StudentAbsenceDetail>;
};

export type StudentAbsenceEdge = Edge & {
  __typename?: 'StudentAbsenceEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<StudentAbsence>;
};

export type StudentActivity = {
  __typename?: 'StudentActivity';
  /** The activity */
  activity: Activity;
  /** Beginning date of the activity */
  beginDate?: Maybe<Scalars['DateTime']['output']>;
  /** Date that the activity was most recently changed */
  dateChanged: Scalars['DateTime']['output'];
  /** Date that the activity was created */
  dateCreated: Scalars['DateTime']['output'];
  /** Ending date of the activity */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** Referential ID for the activity */
  id: Scalars['Int']['output'];
  /** Student that did this activity */
  student: Student;
};

export type StudentDemographic = {
  __typename?: 'StudentDemographic';
  economicDisadvantage?: Maybe<EconomicDisadvantageStatus>;
  el?: Maybe<EnglishLearnerStatus>;
  ethnicity?: Maybe<Ethnicity>;
  gender?: Maybe<GenderType>;
  gifted?: Maybe<GiftedAndTalentedIndicator>;
  grade?: Maybe<GradeLevelIdentifier>;
  homeless?: Maybe<HomelessnessStatus>;
  ideaIndicator?: Maybe<IdeaIndicator>;
  migrant?: Maybe<MigrantStatus>;
  s504?: Maybe<Section504Status>;
  title1?: Maybe<Title1Indicator>;
  title1Summary?: Maybe<Title1SummaryStatus>;
};

export type StudentDemographicAccess = {
  __typename?: 'StudentDemographicAccess';
  economicDisadvantage: Scalars['Boolean']['output'];
  el: Scalars['Boolean']['output'];
  ethnicity: Scalars['Boolean']['output'];
  gender: Scalars['Boolean']['output'];
  gifted: Scalars['Boolean']['output'];
  grade: Scalars['Boolean']['output'];
  homeless: Scalars['Boolean']['output'];
  ideaIndicator: Scalars['Boolean']['output'];
  migrant: Scalars['Boolean']['output'];
  s504: Scalars['Boolean']['output'];
  title1: Scalars['Boolean']['output'];
  title1Summary: Scalars['Boolean']['output'];
};

export type StudentEnrollment = Node & {
  __typename?: 'StudentEnrollment';
  /** Student's Absences by Type and Period attached to this enrollment record. */
  absenceDetails: StudentAbsenceDetailConnection;
  /** Student's Absences attached to this enrollment record. */
  absences: StudentAbsenceConnection;
  /** Student's Absence Count, year to date. */
  absencesYTD: Scalars['Int']['output'];
  /** Activities participated in during this enrollment. */
  activities: Array<StudentActivity>;
  /** Entry and withdrawal dates and types for periods of the student enrollment record. */
  dates: Array<Maybe<StudentEnrollmentPeriod>>;
  /** Demographics */
  demographic?: Maybe<StudentDemographic>;
  /** Disciplinary Incidences */
  discipline?: Maybe<Array<Maybe<DisciplinaryIncident>>>;
  /** Status of Enrollment */
  enrollmentStatus?: Maybe<StudentEnrollmentStatus>;
  /** Grade Level of Enrollment */
  gradeLevel?: Maybe<GradeLevel>;
  /** Unique ID for the student enrollment record. */
  id: Scalars['ID']['output'];
  /** MARSS B file enrollment data attached to the student at this enrollment record's school and school year (MN Only!) */
  marssEnrollments: MarssEnrollmentConnection;
  /** School of Enrollment */
  school: School;
  /** School Year of Enrollment */
  schoolYear: SchoolYear;
  /** The student attached to the Enrollment. */
  student: Student;
};


export type StudentEnrollmentAbsenceDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  termId?: InputMaybe<Scalars['Int']['input']>;
};


export type StudentEnrollmentAbsencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  termId?: InputMaybe<Scalars['Int']['input']>;
};


export type StudentEnrollmentDisciplineArgs = {
  termId?: InputMaybe<Scalars['Int']['input']>;
};


export type StudentEnrollmentMarssEnrollmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type StudentEnrollmentConnection = Connection & {
  __typename?: 'StudentEnrollmentConnection';
  edges?: Maybe<Array<Maybe<StudentEnrollmentEdge>>>;
  nodes?: Maybe<Array<Maybe<StudentEnrollment>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StudentEnrollmentEdge = Edge & {
  __typename?: 'StudentEnrollmentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<StudentEnrollment>;
};

export type StudentEnrollmentEntryType = {
  __typename?: 'StudentEnrollmentEntryType';
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type StudentEnrollmentPeriod = {
  __typename?: 'StudentEnrollmentPeriod';
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  entryDate: Scalars['DateTime']['output'];
  entryType?: Maybe<StudentEnrollmentEntryType>;
  withdrawalDate?: Maybe<Scalars['DateTime']['output']>;
  withdrawalType?: Maybe<StudentEnrollmentWithdrawalType>;
};

export enum StudentEnrollmentStatus {
  Active = 'ACTIVE',
  All = 'ALL',
  Completed = 'COMPLETED',
  Invalid = 'INVALID',
  InProcess = 'IN_PROCESS',
  Withdrawn = 'WITHDRAWN'
}

export type StudentEnrollmentWithdrawalType = {
  __typename?: 'StudentEnrollmentWithdrawalType';
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type StudentFilter = {
  districtStudentNumber?: InputMaybe<StringFilter>;
  economicDisadvantage?: InputMaybe<EconomicDisadvantageStatusFilter>;
  el?: InputMaybe<EnglishLearnerStatusFilter>;
  ethnicity?: InputMaybe<EthnicityFilter>;
  gender?: InputMaybe<GenderTypeFilter>;
  gifted?: InputMaybe<GiftedAndTalentedIndicatorFilter>;
  grade?: InputMaybe<GradeLevelIdentifierFilter>;
  homeless?: InputMaybe<HomelessnessStatusFilter>;
  ideaIndicator?: InputMaybe<IdeaIndicatorFilter>;
  migrant?: InputMaybe<MigrantStatusFilter>;
  name?: InputMaybe<StringFilter>;
  s504?: InputMaybe<Section504StatusFilter>;
  stateStudentNumber?: InputMaybe<StringFilter>;
  testScores?: InputMaybe<Array<TestScoreFilter>>;
  title1?: InputMaybe<Title1IndicatorFilter>;
  title1Summary?: InputMaybe<Title1SummaryStatusFilter>;
};

export type StudentFilterTab = Node & {
  __typename?: 'StudentFilterTab';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /**
   * Returns a list of roles and configurations for this filter tab.
   * Exclusive to system administrators.
   */
  roles?: Maybe<StudentFilterTabRoleConnection>;
};


export type StudentFilterTabRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type StudentFilterTabConnection = Connection & {
  __typename?: 'StudentFilterTabConnection';
  edges?: Maybe<Array<Maybe<StudentFilterTabEdge>>>;
  nodes?: Maybe<Array<Maybe<StudentFilterTab>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StudentFilterTabEdge = Edge & {
  __typename?: 'StudentFilterTabEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<StudentFilterTab>;
};

export type StudentFilterTabRole = Node & {
  __typename?: 'StudentFilterTabRole';
  id: Scalars['ID']['output'];
  permitted: Scalars['Boolean']['output'];
  role: Role;
  school: School;
  studentFilterTab: StudentFilterTab;
};

export type StudentFilterTabRoleConnection = Connection & {
  __typename?: 'StudentFilterTabRoleConnection';
  edges?: Maybe<Array<Maybe<StudentFilterTabRoleEdge>>>;
  nodes?: Maybe<Array<Maybe<StudentFilterTabRole>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StudentFilterTabRoleEdge = Edge & {
  __typename?: 'StudentFilterTabRoleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<StudentFilterTabRole>;
};

export type StudentFilterTabRoleInput = {
  filterId: Scalars['ID']['input'];
  permitted: Scalars['Boolean']['input'];
  roleId: Scalars['ID']['input'];
  schoolId: Scalars['ID']['input'];
};

export type StudentIntervention = {
  __typename?: 'StudentIntervention';
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  detail: Array<Maybe<StudentInterventionDetail>>;
  intervention: Intervention;
  referralFlag?: Maybe<InterventionReferralFlag>;
  schoolYear?: Maybe<SchoolYear>;
  stateReportedFlag?: Maybe<InterventionStateReportedFlag>;
};

export type StudentInterventionDetail = {
  __typename?: 'StudentInterventionDetail';
  attendanceUnits?: Maybe<Scalars['Int']['output']>;
  beginDate: Scalars['DateTime']['output'];
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  interventionCategory?: Maybe<InterventionCategory>;
  interventionContact?: Maybe<Scalars['String']['output']>;
  interventionStatus?: Maybe<InterventionStatus>;
  isPrimary: Scalars['Boolean']['output'];
  membershipUnits?: Maybe<Scalars['Int']['output']>;
  unitType?: Maybe<Scalars['String']['output']>;
};

export type StudentOrder = {
  direction: OrderDirection;
  field: StudentOrderField;
  /** Choose how to filter scores if field = SCORE */
  scoreFilterInput?: InputMaybe<StudentTestScoreInput>;
};

/** Properties that can be ordered/filtered for a student */
export enum StudentOrderField {
  BirthDate = 'BIRTH_DATE',
  DistrictStudentNumber = 'DISTRICT_STUDENT_NUMBER',
  El = 'EL',
  FirstName = 'FIRST_NAME',
  Gender = 'GENDER',
  Id = 'ID',
  IdeaIndicator = 'IDEA_INDICATOR',
  LastName = 'LAST_NAME',
  Name = 'NAME',
  Score = 'SCORE',
  StateStudentNumber = 'STATE_STUDENT_NUMBER'
}

export type StudentPopulationInput = {
  /** Population's course ids */
  courseIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Population's course section ids */
  courseSectionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Population's teacher employee ids */
  employeeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Population's enrollment status */
  enrollmentStatus: StudentEnrollmentStatus;
  /** Population's grade level ids */
  gradeLevelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Population's group id */
  groupId?: InputMaybe<Scalars['Int']['input']>;
  /** Population's school ids */
  schoolIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Population's school year ids */
  schoolYearIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Population's student filter, see dbo.fntStudents2 stored procedure */
  studentFilter?: InputMaybe<Scalars['String']['input']>;
};

export type StudentProgram = {
  __typename?: 'StudentProgram';
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  detail: Array<Maybe<StudentProgramDetail>>;
  intervention: Intervention;
  referralFlag?: Maybe<InterventionReferralFlag>;
  schoolYear?: Maybe<SchoolYear>;
  stateReportedFlag?: Maybe<InterventionStateReportedFlag>;
};

export type StudentProgramDetail = {
  __typename?: 'StudentProgramDetail';
  attendanceUnits?: Maybe<Scalars['Int']['output']>;
  beginDate: Scalars['DateTime']['output'];
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  interventionContact?: Maybe<Scalars['String']['output']>;
  isPrimary: Scalars['Boolean']['output'];
  membershipUnits?: Maybe<Scalars['Int']['output']>;
  unitType?: Maybe<Scalars['String']['output']>;
};

export type StudentReport = {
  __typename?: 'StudentReport';
  categories: Array<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  series: Array<ReportSeries>;
};

export type StudentService = {
  __typename?: 'StudentService';
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  detail: Array<Maybe<StudentServiceDetail>>;
  intervention: Intervention;
  referralFlag?: Maybe<InterventionReferralFlag>;
  schoolYear?: Maybe<SchoolYear>;
  stateReportedFlag?: Maybe<InterventionStateReportedFlag>;
};

export type StudentServiceDetail = {
  __typename?: 'StudentServiceDetail';
  attendanceUnits?: Maybe<Scalars['Int']['output']>;
  beginDate: Scalars['DateTime']['output'];
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  interventionCategory?: Maybe<InterventionCategory>;
  interventionContact?: Maybe<Scalars['String']['output']>;
  interventionStatus?: Maybe<InterventionStatus>;
  isPrimary: Scalars['Boolean']['output'];
  membershipUnits?: Maybe<Scalars['Int']['output']>;
  unitType?: Maybe<Scalars['String']['output']>;
};

export type StudentTestScoreInput = {
  /** The category being tested */
  outlineId: Scalars['Int']['input'];
  /** The type of score */
  scoreTypeId: Scalars['Int']['input'];
  /** When the test was taken */
  seasonId: Scalars['Int']['input'];
  /** The version of test */
  variantId?: InputMaybe<Scalars['Int']['input']>;
};

export type Term = {
  __typename?: 'Term';
  /** Identifies the date and time when the object was last updated. */
  dateChanged: Scalars['DateTime']['output'];
  /** Identifies the date and time when the object was created. */
  dateCreated: Scalars['DateTime']['output'];
  /** Identifies the primary key from the database. */
  id: Scalars['Int']['output'];
  /** The term's displayed name. */
  name: Scalars['String']['output'];
};

export type Test = Node & {
  __typename?: 'Test';
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  instance?: Maybe<TestInstance>;
  instances?: Maybe<Array<TestInstance>>;
  instancesWithScores?: Maybe<Array<TestInstance>>;
  name: Scalars['String']['output'];
  outline?: Maybe<TestOutline>;
  outlines?: Maybe<Array<TestOutline>>;
  outlinesWithScores?: Maybe<Array<TestOutline>>;
  primaryTestMethod: TestMethod;
  scoreType?: Maybe<TestScoreType>;
  scoreTypes: Array<TestScoreType>;
  scoreTypesWithScores: Array<TestScoreType>;
  season?: Maybe<TestSeason>;
  seasonsWithScores: Array<TestSeason>;
  shortName: Scalars['String']['output'];
  testRegion: TestRegion;
  variant?: Maybe<TestVariant>;
  variants: Array<TestVariant>;
  variantsWithScores: Array<TestVariant>;
};


export type TestInstanceArgs = {
  id: Scalars['ID']['input'];
};


export type TestInstancesArgs = {
  schoolYearIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  seasonIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  variantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type TestInstancesWithScoresArgs = {
  schoolYearIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  scoreTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  seasonIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  variantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type TestOutlineArgs = {
  id: Scalars['Int']['input'];
};


export type TestOutlinesWithScoresArgs = {
  seasonId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<OutlineType>;
};


export type TestScoreTypeArgs = {
  id: Scalars['Int']['input'];
};


export type TestScoreTypesWithScoresArgs = {
  outlineId?: InputMaybe<Scalars['Int']['input']>;
  seasonId?: InputMaybe<Scalars['Int']['input']>;
};


export type TestSeasonArgs = {
  id: Scalars['Int']['input'];
};


export type TestVariantArgs = {
  id: Scalars['Int']['input'];
};

export type TestConnection = Connection & {
  __typename?: 'TestConnection';
  edges?: Maybe<Array<Maybe<TestEdge>>>;
  nodes?: Maybe<Array<Maybe<Test>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TestEdge = Edge & {
  __typename?: 'TestEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Test>;
};

export type TestFilter = {
  text?: InputMaybe<Scalars['String']['input']>;
};

export type TestInstance = {
  __typename?: 'TestInstance';
  gradeLevel?: Maybe<GradeLevel>;
  season: TestSeason;
  test: Test;
  variant?: Maybe<TestVariant>;
};

export enum TestMethod {
  Highest = 'HIGHEST',
  Invalid = 'INVALID',
  MostRecent = 'MOST_RECENT',
  MostRecentByOutline = 'MOST_RECENT_BY_OUTLINE'
}

/** Fields for ordering a list of tests */
export type TestOrder = {
  direction: OrderDirection;
  field: TestOrderField;
};

/** Properties that can be ordered/filtered for an assessment */
export enum TestOrderField {
  Id = 'id',
  Name = 'name',
  PrimaryTestMethod = 'primaryTestMethod',
  TestRegion = 'testRegion'
}

export type TestOutline = Node & {
  __typename?: 'TestOutline';
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  descriptiveName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  scoreTypes: TestScoreTypeConnection;
  subjectGroup?: Maybe<TestSubjectGroup>;
  type: OutlineType;
};


export type TestOutlineScoreTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  instanceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schoolYearIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  seasonIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type TestOutlineConnection = Connection & {
  __typename?: 'TestOutlineConnection';
  edges?: Maybe<Array<Maybe<TestOutlineEdge>>>;
  nodes?: Maybe<Array<Maybe<TestOutline>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TestOutlineEdge = Edge & {
  __typename?: 'TestOutlineEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TestOutline>;
};

export type TestPerformanceScale = {
  __typename?: 'TestPerformanceScale';
  /** The test instance against which this scale is applied. */
  instance: TestInstance;
  /** The maximum score for the given scale */
  max: Scalars['Int']['output'];
  /** The minimum score for the given scale */
  min: Scalars['Int']['output'];
  /** The test outline against which this scale is applied. */
  outline: TestOutline;
  /** The measurement scale that has the given range for the instance/outline/scoretype combination */
  performanceScale: PerformanceScale;
  /** The score type against which this scale is applied. */
  scoreType: TestScoreType;
};

export type TestPerformanceScaleDeleteInput = {
  /** The scale for which score ranges are being requested */
  performanceScaleId: Scalars['Int']['input'];
  /** The season/grade/variant for the score */
  testInstanceId: Scalars['Int']['input'];
  /** The category being tested */
  testOutlineId: Scalars['Int']['input'];
};

export type TestPerformanceScaleInput = {
  /** The scale for which score ranges are being requested */
  performanceScaleId: Scalars['Int']['input'];
  /** The season/grade/variant for the score */
  testInstanceId: Scalars['Int']['input'];
  /** The category being tested */
  testOutlineId: Scalars['Int']['input'];
};

export type TestPerformanceScaleRangeInput = {
  /** The maximum score for the given scale */
  max: Scalars['Int']['input'];
  /** The minimum score for the given scale */
  min: Scalars['Int']['input'];
  /** The scale for which score ranges are being requested */
  performanceScaleId: Scalars['Int']['input'];
  /** The season/grade/variant for the score */
  testInstanceId: Scalars['Int']['input'];
  /** The category being tested */
  testOutlineId: Scalars['Int']['input'];
};

export type TestPerformanceScalesBySetInput = {
  /** The set of scales for which score ranges are being requested */
  performanceSetId: Scalars['Int']['input'];
  /** The season/grade/variant for the score */
  testInstanceId?: InputMaybe<Scalars['Int']['input']>;
  /** The category being tested */
  testOutlineId: Scalars['Int']['input'];
  /** The season for the score */
  testSeasonId: Scalars['Int']['input'];
};

export enum TestRegion {
  District = 'DISTRICT',
  Invalid = 'INVALID',
  National = 'NATIONAL',
  Region = 'REGION',
  School = 'SCHOOL',
  State = 'STATE'
}

export type TestScore = Node & {
  __typename?: 'TestScore';
  /** An alternate or alphanumeric assessment score */
  altScore?: Maybe<Scalars['String']['output']>;
  /** The date that the test was taken. */
  dateTaken: Scalars['DateTime']['output'];
  /** The scale banding associated with the default performance set for this score */
  defaultPerformanceScale?: Maybe<PerformanceScale>;
  /** The manner that the test score was added to Viewpoint. */
  entryType?: Maybe<TestScoreEntryType>;
  /** ID of the test score. */
  id: Scalars['ID']['output'];
  /** The outline associated with this score. */
  outline: TestOutline;
  /** The assessment score */
  score?: Maybe<Scalars['String']['output']>;
  /** The score type associated with the score */
  scoreType: ScoreType;
  /** The season associated with this score. */
  season: TestSeason;
  /** The student with the given test score */
  student?: Maybe<Student>;
  /** The test associated with this score. */
  test: Test;
  /** The assessment score type associated with the score */
  testScoreType: TestScoreType;
  /** The test variant associated with this score. */
  variant?: Maybe<TestVariant>;
};

export type TestScoreConnection = Connection & {
  __typename?: 'TestScoreConnection';
  edges?: Maybe<Array<Maybe<TestScoreEdge>>>;
  nodes?: Maybe<Array<Maybe<TestScore>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TestScoreEdge = Edge & {
  __typename?: 'TestScoreEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TestScore>;
};

export enum TestScoreEntryType {
  DataLoad = 'DATA_LOAD',
  InterdistrictExchange = 'INTERDISTRICT_EXCHANGE',
  InDistrictEntry = 'IN_DISTRICT_ENTRY',
  Transfer = 'TRANSFER'
}

export type TestScoreFilter = {
  /** Scale filter */
  scaleFilter?: InputMaybe<EnumFilter>;
  /** Score filter */
  scoreFilter?: InputMaybe<NumberFilter>;
  /** Test score input */
  testScoreInput: StudentTestScoreInput;
};

export enum TestScoreGroupingMethod {
  /** Divides the test scores by scale's proficiency flag. */
  ByProficiency = 'BY_PROFICIENCY',
  /** Divides the test scores by scale. */
  ByScale = 'BY_SCALE',
  /** Divides the test scores by individual score */
  ByScore = 'BY_SCORE'
}

export type TestScoreInput = {
  /** The category being tested */
  outlineId: Scalars['Int']['input'];
  /** The type of score */
  scoreTypeId: Scalars['Int']['input'];
  /** When the test was taken */
  seasonId: Scalars['Int']['input'];
  /** The student's unique internal identifier */
  studentId: Scalars['Int']['input'];
  /** The version of test */
  variantId?: InputMaybe<Scalars['Int']['input']>;
};

export type TestScoreType = Node & {
  __typename?: 'TestScoreType';
  bandingSets?: Maybe<Array<Maybe<PerformanceBandingSet>>>;
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  primaryBandingSet?: Maybe<PerformanceBandingSet>;
  priority: ScoreTypePriority;
  type: ScoreType;
};

export type TestScoreTypeConnection = Connection & {
  __typename?: 'TestScoreTypeConnection';
  edges?: Maybe<Array<Maybe<TestScoreTypeEdge>>>;
  nodes?: Maybe<Array<Maybe<TestScoreType>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TestScoreTypeEdge = Edge & {
  __typename?: 'TestScoreTypeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TestScoreType>;
};

export type TestSeason = {
  __typename?: 'TestSeason';
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  schoolYear: SchoolYear;
};

export type TestSubjectGroup = {
  __typename?: 'TestSubjectGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TestVariant = {
  __typename?: 'TestVariant';
  dateChanged: Scalars['DateTime']['output'];
  dateCreated: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/**
 * An indication that the student is participating in and served by programs under Title I, Part A of ESEA as amended.
 * https://ceds.ed.gov/CEDSElementDetails.aspx?TermxTopicId=38821
 */
export enum Title1Indicator {
  DataNotAvailable = 'DATA_NOT_AVAILABLE',
  LocalNeglectedProgram = 'LOCAL_NEGLECTED_PROGRAM',
  PrivateSchoolStudentsParticipating = 'PRIVATE_SCHOOL_STUDENTS_PARTICIPATING',
  PublicSchoolwideProgram = 'PUBLIC_SCHOOLWIDE_PROGRAM',
  PublicTargetedAssistanceProgram = 'PUBLIC_TARGETED_ASSISTANCE_PROGRAM',
  WasNotServed = 'WAS_NOT_SERVED'
}

export type Title1IndicatorFilter = {
  /** In */
  contains?: InputMaybe<Array<Title1Indicator>>;
  /** Not in */
  doesNotContain?: InputMaybe<Array<Title1Indicator>>;
};

export enum Title1SummaryStatus {
  DataNotAvailable = 'DATA_NOT_AVAILABLE',
  No = 'NO',
  Yes = 'YES'
}

export type Title1SummaryStatusFilter = {
  /** In */
  contains?: InputMaybe<Array<Title1SummaryStatus>>;
  /** Not in */
  doesNotContain?: InputMaybe<Array<Title1SummaryStatus>>;
};

export type UpdateConfigurationValueInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UpdateInterdistrictExchangeConfigurationInput = {
  incomingAllTests: Scalars['Boolean']['input'];
  incomingPermitted: Scalars['Boolean']['input'];
  incomingTestIdList?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  outgoingAllTests: Scalars['Boolean']['input'];
  outgoingPermitted: Scalars['Boolean']['input'];
  outgoingTestIdList?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  schoolId: Scalars['ID']['input'];
};

export type UpdateReferenceParameterInput = {
  chartColorId: Scalars['ID']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  displayed: Scalars['Boolean']['input'];
  extraData?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  parentGroupId?: InputMaybe<Scalars['ID']['input']>;
  precedentOrder: Scalars['Int']['input'];
  shortName?: InputMaybe<Scalars['String']['input']>;
  typeId: Scalars['ID']['input'];
  userDefined1?: InputMaybe<Scalars['String']['input']>;
  userDefined2?: InputMaybe<Scalars['String']['input']>;
  userDefined3?: InputMaybe<Scalars['String']['input']>;
  userDefined4?: InputMaybe<Scalars['String']['input']>;
  userDefined5?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type UpdateReferenceParameterSourceInput = {
  code: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  referenceParameterId: Scalars['ID']['input'];
  referenceTypeId: Scalars['ID']['input'];
};

export type UpdateReferenceTypeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  defaultParameterId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  parentTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSchoolConfigurationValueInput = {
  key: Scalars['String']['input'];
  schoolId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

/** Fields for updating a user */
export type UpdateUserInput = {
  dateConfidentialityAccepted?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  loginMethod?: InputMaybe<LoginMethod>;
  roles?: InputMaybe<Array<UserRoleInput>>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserRosterDashboardAssessmentInput = {
  id: Scalars['Int']['input'];
  outlineId?: InputMaybe<Scalars['Int']['input']>;
  reportId: Scalars['Int']['input'];
  scoreTypeId?: InputMaybe<Scalars['Int']['input']>;
  seasonId?: InputMaybe<Scalars['Int']['input']>;
  testId?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
  variantId?: InputMaybe<Scalars['Int']['input']>;
};

/** A user of the Viewpoint application */
export type User = Node & {
  __typename?: 'User';
  /** The user's email address */
  email?: Maybe<Scalars['String']['output']>;
  /** The external employee code to link the user to a student information system (SIS) */
  employeeCode?: Maybe<Scalars['String']['output']>;
  /** The user's first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Identifies the primary key from the database */
  id: Scalars['ID']['output'];
  /** Identifies if the user has signed the confidentiality agreement and if it is still valid */
  isConfidentialityAgreementValid?: Maybe<Scalars['Boolean']['output']>;
  /** An indicator that the user's password is invalid and must be reset */
  isPasswordValid?: Maybe<Scalars['Boolean']['output']>;
  /** Is the user a System Administrator at any school? */
  isSystemAdmin?: Maybe<Scalars['Boolean']['output']>;
  /** The user's last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** An employee account linked to this user */
  linkedEmployee?: Maybe<Employee>;
  /** The user's login method type (local(password), network(LDAP, SSO)) */
  loginMethod: LoginMethod;
  /** The user's full name */
  name?: Maybe<Scalars['String']['output']>;
  /** The roles associated with the user */
  roles?: Maybe<UserRoleConnection>;
  /** The user's status (active, inactive) */
  status: UserStatus;
  /** The username used to login */
  username: Scalars['String']['output'];
};


/** A user of the Viewpoint application */
export type UserRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserConnection = Connection & {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  nodes?: Maybe<Array<Maybe<User>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserEdge = Edge & {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<User>;
};

export type UserFilter = {
  email?: InputMaybe<StringFilter>;
  employeeCode?: InputMaybe<StringFilter>;
  loginMethod?: InputMaybe<LoginMethodFilter>;
  name?: InputMaybe<StringFilter>;
  role?: InputMaybe<RoleFilter>;
  school?: InputMaybe<SchoolFilter>;
  status?: InputMaybe<UserStatusFilter>;
  text?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<StringFilter>;
};

/** Fields for ordering a list of users */
export type UserOrder = {
  direction: OrderDirection;
  field: UserOrderField;
};

/** Properties that can be ordered/filtered for a user */
export enum UserOrderField {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  LoginMethod = 'loginMethod',
  Name = 'name',
  Status = 'status',
  Username = 'username'
}

/** A user's role at a school */
export type UserRole = Node & {
  __typename?: 'UserRole';
  id: Scalars['ID']['output'];
  /** The user's role at a particular school */
  role: Role;
  /** The school associated with this user role */
  school: School;
};

export type UserRoleConnection = Connection & {
  __typename?: 'UserRoleConnection';
  edges?: Maybe<Array<Maybe<UserRoleEdge>>>;
  nodes?: Maybe<Array<Maybe<UserRole>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserRoleEdge = Edge & {
  __typename?: 'UserRoleEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<UserRole>;
};

/** Fields for making a change to a user's role */
export type UserRoleInput = {
  /** The id for the Role being added to the user */
  roleId: Scalars['ID']['input'];
  /** The id for the School for the role being added to the user */
  schoolId: Scalars['ID']['input'];
};

export type UserRosterDashboardAssessment = {
  __typename?: 'UserRosterDashboardAssessment';
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  test: UserRosterDashboardTestFields;
};

export type UserRosterDashboardTestFields = {
  __typename?: 'UserRosterDashboardTestFields';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  outline: TestOutline;
  scoreType: TestScoreType;
  season: TestSeason;
  variant?: Maybe<TestVariant>;
};

/** The status of a user */
export enum UserStatus {
  Active = 'active',
  Deleted = 'deleted',
  Inactive = 'inactive'
}

export type UserStatusFilter = {
  contains?: InputMaybe<Array<UserStatus>>;
  doesNotContain?: InputMaybe<Array<UserStatus>>;
};

export type Vaccination = {
  __typename?: 'Vaccination';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type VaccinationExemption = {
  __typename?: 'VaccinationExemption';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum Visibility {
  All = 'ALL',
  Displayed = 'DISPLAYED',
  Hidden = 'HIDDEN'
}

export type AdvancedPopulationFilterRoleFieldsFragment = { __typename?: 'AdvancedPopulationFilterRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } };

export type AdvancedPopulationFilterFieldsFragment = { __typename?: 'AdvancedPopulationFilter', id: string, name?: string, districtRoles?: { __typename?: 'AdvancedPopulationFilterRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'AdvancedPopulationFilterRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles?: { __typename?: 'AdvancedPopulationFilterRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'AdvancedPopulationFilterRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } };

export type AssessmentFieldsFragment = { __typename?: 'Test', id: string, name: string, testRegion: TestRegion, primaryTestMethod: TestMethod };

export type ConfigurationFieldsFragment = { __typename?: 'Configuration', id: string, key: string, displayName: string, value: string };

export type InterdistrictExchangeConfigurationFieldsFragment = { __typename?: 'InterdistrictExchangeConfiguration', incomingAllTests?: boolean, incomingPermitted?: boolean, outgoingAllTests?: boolean, outgoingPermitted?: boolean, lastChangedDate?: Date, incomingTestList?: Array<{ __typename?: 'Test', id: string, name: string }>, outgoingTestList?: Array<{ __typename?: 'Test', id: string, name: string }>, lastChangedBy?: { __typename?: 'User', id: string, name?: string } };

export type OtpFieldsFragment = { __typename?: 'Otp', email: string, code: string, expiresAt: Date };

export type PermissionFieldsFragment = { __typename?: 'Permission', id: number, name: string };

export type ReportAttributeRoleFieldsFragment = { __typename?: 'AttributeRole', permitted?: boolean, role?: { __typename?: 'Role', id: string, name: string }, school?: { __typename?: 'School', id: string, name: string, level: string } };

export type ReportAttributeFieldsFragment = { __typename?: 'Attribute', id: string, name?: string, tableType?: string, districtRoles: { __typename?: 'AttributeRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'AttributeRole', permitted?: boolean, role?: { __typename?: 'Role', id: string, name: string }, school?: { __typename?: 'School', id: string, name: string, level: string } }> }, roles: { __typename?: 'AttributeRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'AttributeRole', permitted?: boolean, role?: { __typename?: 'Role', id: string, name: string }, school?: { __typename?: 'School', id: string, name: string, level: string } }> } };

export type ReportCategoryFieldsFragment = { __typename?: 'ReportCategory', id: string, name: string };

export type ReportDetailSectionRoleFieldsFragment = { __typename?: 'ReportDetailSectionRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } };

export type ReportDetailSectionFieldsFragment = { __typename?: 'ReportDetailSection', id: string, name?: string, report?: { __typename?: 'Report', id: string, name: string, category: { __typename?: 'ReportCategory', id: string, name: string }, type: { __typename?: 'ReportType', id: string, name: string } }, districtRoles?: { __typename?: 'ReportDetailSectionRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportDetailSectionRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles?: { __typename?: 'ReportDetailSectionRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportDetailSectionRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } };

export type ReportRoleFieldsFragment = { __typename?: 'ReportRole', favorite?: boolean, permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } };

export type ReportFieldsFragment = { __typename?: 'Report', id: string, name: string, category: { __typename?: 'ReportCategory', id: string, name: string }, type: { __typename?: 'ReportType', id: string, name: string }, districtRoles?: { __typename?: 'ReportRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRole', favorite?: boolean, permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles?: { __typename?: 'ReportRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRole', favorite?: boolean, permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } };

export type ReportRuleCategoryRoleFieldsFragment = { __typename?: 'ReportRuleCategoryRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } };

export type ReportRuleCategoryFieldsFragment = { __typename?: 'ReportRuleCategory', id: string, name?: string, districtRoles: { __typename?: 'ReportRuleCategoryRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRuleCategoryRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles: { __typename?: 'ReportRuleCategoryRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRuleCategoryRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } };

export type ReportTypeFieldsFragment = { __typename?: 'ReportType', id: string, name: string };

export type RoleFieldsFragment = { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }>, rolePermissions: Array<{ __typename?: 'RolePermission', defaultTab?: boolean, permission: { __typename?: 'Permission', id: number, name: string } }> };

export type RolePermissionFromRoleFieldsFragment = { __typename?: 'RolePermission', defaultTab?: boolean, permission: { __typename?: 'Permission', id: number, name: string } };

export type SchoolConfigurationFieldsFragment = { __typename?: 'SchoolConfiguration', id: string, key: string, displayName: string, value: string, school: { __typename?: 'School', id: string, name: string, level: string } };

export type SchoolFieldsFragment = { __typename?: 'School', id: string, name: string, level: string };

export type UserFieldsFragment = { __typename?: 'User', firstName?: string, email?: string, employeeCode?: string, id: string, isConfidentialityAgreementValid?: boolean, isPasswordValid?: boolean, isSystemAdmin?: boolean, lastName?: string, loginMethod: LoginMethod, name?: string, status: UserStatus, username: string, linkedEmployee?: { __typename?: 'Employee', employeeCode: string, id: number, name?: string }, roles?: { __typename?: 'UserRoleConnection', edges?: Array<{ __typename?: 'UserRoleEdge', node?: { __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, level: string, name: string } } }>, nodes?: Array<{ __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }> }, school: { __typename?: 'School', id: string, level: string, name: string } }> } };

export type AddOtpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  code: Scalars['String']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddOtpMutation = { __typename?: 'Mutation', addOtp?: { __typename?: 'Otp', email: string, code: string, expiresAt: Date } };

export type AddRoleMutationVariables = Exact<{
  role: RoleInput;
}>;


export type AddRoleMutation = { __typename?: 'Mutation', addRole: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }>, rolePermissions: Array<{ __typename?: 'RolePermission', defaultTab?: boolean, permission: { __typename?: 'Permission', id: number, name: string } }> } };

export type AddUserRoleMutationVariables = Exact<{
  input: AddUserRoleInput;
}>;


export type AddUserRoleMutation = { __typename?: 'Mutation', addUserRole?: { __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string } } };

export type DeleteRoleMutationVariables = Exact<{
  roleId: Scalars['ID']['input'];
}>;


export type DeleteRoleMutation = { __typename?: 'Mutation', deleteRole: boolean };

export type DeleteUserRoleMutationVariables = Exact<{
  input: DeleteUserRoleInput;
}>;


export type DeleteUserRoleMutation = { __typename?: 'Mutation', deleteUserRole: boolean };

export type DuplicateRoleMutationVariables = Exact<{
  sourceRoleId: Scalars['ID']['input'];
  targetRoleName: Scalars['String']['input'];
}>;


export type DuplicateRoleMutation = { __typename?: 'Mutation', duplicateRole?: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }>, rolePermissions: Array<{ __typename?: 'RolePermission', defaultTab?: boolean, permission: { __typename?: 'Permission', id: number, name: string } }> } };

export type InvalidateOtpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type InvalidateOtpMutation = { __typename?: 'Mutation', invalidateOtp?: boolean };

export type LinkUserToEmployeeMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  employeeCode: Scalars['String']['input'];
}>;


export type LinkUserToEmployeeMutation = { __typename?: 'Mutation', linkUserToEmployee: { __typename?: 'User', firstName?: string, email?: string, employeeCode?: string, id: string, lastName?: string, loginMethod: LoginMethod, name?: string, status: UserStatus, username: string, linkedEmployee?: { __typename?: 'Employee', employeeCode: string, id: number, name?: string }, roles?: { __typename?: 'UserRoleConnection', edges?: Array<{ __typename?: 'UserRoleEdge', node?: { __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, level: string, name: string } } }> } } };

export type LoginMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
  isPasswordHashed?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'User', firstName?: string, email?: string, employeeCode?: string, id: string, isConfidentialityAgreementValid?: boolean, isPasswordValid?: boolean, isSystemAdmin?: boolean, lastName?: string, loginMethod: LoginMethod, name?: string, status: UserStatus, username: string, linkedEmployee?: { __typename?: 'Employee', employeeCode: string, id: number, name?: string }, roles?: { __typename?: 'UserRoleConnection', edges?: Array<{ __typename?: 'UserRoleEdge', node?: { __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, level: string, name: string } } }>, nodes?: Array<{ __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }> }, school: { __typename?: 'School', id: string, level: string, name: string } }> } } };

export type UnlinkEmployeeFromUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type UnlinkEmployeeFromUserMutation = { __typename?: 'Mutation', unlinkEmployeeFromUser: { __typename?: 'User', firstName?: string, email?: string, id: string, lastName?: string, loginMethod: LoginMethod, name?: string, status: UserStatus, username: string, roles?: { __typename?: 'UserRoleConnection', edges?: Array<{ __typename?: 'UserRoleEdge', node?: { __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string } } }>, nodes?: Array<{ __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string } }> } } };

export type UpdateAdvancedPopulationFilterRoleSetMutationVariables = Exact<{
  input: Array<InputMaybe<AdvancedPopulationFilterRoleInput>> | InputMaybe<AdvancedPopulationFilterRoleInput>;
}>;


export type UpdateAdvancedPopulationFilterRoleSetMutation = { __typename?: 'Mutation', updateAdvancedPopulationFilterRoleSet: Array<{ __typename?: 'AdvancedPopulationFilterRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string } }> };

export type UpdateAttributeRoleSetMutationVariables = Exact<{
  input: Array<InputMaybe<AttributeRoleInput>> | InputMaybe<AttributeRoleInput>;
}>;


export type UpdateAttributeRoleSetMutation = { __typename?: 'Mutation', updateAttributeRoleSet: Array<{ __typename?: 'AttributeRole', permitted?: boolean, role?: { __typename?: 'Role', id: string, name: string } }> };

export type UpdateConfigurationSetMutationVariables = Exact<{
  input: Array<UpdateConfigurationValueInput> | UpdateConfigurationValueInput;
}>;


export type UpdateConfigurationSetMutation = { __typename?: 'Mutation', updateConfigurationSet: Array<{ __typename?: 'Configuration', id: string, key: string, displayName: string, value: string }> };

export type UpdateInterdistrictExchangeConfigurationMutationVariables = Exact<{
  input: UpdateInterdistrictExchangeConfigurationInput;
}>;


export type UpdateInterdistrictExchangeConfigurationMutation = { __typename?: 'Mutation', updateInterdistrictExchangeConfiguration: { __typename?: 'InterdistrictExchangeConfiguration', incomingAllTests?: boolean, incomingPermitted?: boolean, outgoingAllTests?: boolean, outgoingPermitted?: boolean, lastChangedDate?: Date, incomingTestList?: Array<{ __typename?: 'Test', id: string, name: string }>, outgoingTestList?: Array<{ __typename?: 'Test', id: string, name: string }>, lastChangedBy?: { __typename?: 'User', id: string, name?: string } } };

export type UpdateReportDetailSectionRoleSetMutationVariables = Exact<{
  input: Array<InputMaybe<ReportDetailSectionRoleInput>> | InputMaybe<ReportDetailSectionRoleInput>;
}>;


export type UpdateReportDetailSectionRoleSetMutation = { __typename?: 'Mutation', updateReportDetailSectionRoleSet: Array<{ __typename?: 'ReportDetailSectionRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string } }> };

export type UpdateReportRoleMutationVariables = Exact<{
  input: ReportRoleInput;
}>;


export type UpdateReportRoleMutation = { __typename?: 'Mutation', updateReportRole: { __typename?: 'ReportRole', favorite?: boolean, permitted: boolean, role: { __typename?: 'Role', id: string, name: string } } };

export type UpdateReportRoleSetMutationVariables = Exact<{
  input: Array<InputMaybe<ReportRoleSetItemInput>> | InputMaybe<ReportRoleSetItemInput>;
}>;


export type UpdateReportRoleSetMutation = { __typename?: 'Mutation', updateReportRoleSet: Array<{ __typename?: 'ReportRole', favorite?: boolean, permitted: boolean, role: { __typename?: 'Role', id: string, name: string } }> };

export type UpdateReportRuleCategoryRoleSetMutationVariables = Exact<{
  input: Array<InputMaybe<ReportRuleCategoryRoleInput>> | InputMaybe<ReportRuleCategoryRoleInput>;
}>;


export type UpdateReportRuleCategoryRoleSetMutation = { __typename?: 'Mutation', updateReportRuleCategoryRoleSet: Array<{ __typename?: 'ReportRuleCategoryRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string } }> };

export type UpdateRoleMutationVariables = Exact<{
  role: RoleInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', editRole: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }>, rolePermissions: Array<{ __typename?: 'RolePermission', defaultTab?: boolean, permission: { __typename?: 'Permission', id: number, name: string } }> } };

export type UpdateSchoolConfigurationSetMutationVariables = Exact<{
  input: Array<UpdateSchoolConfigurationValueInput> | UpdateSchoolConfigurationValueInput;
}>;


export type UpdateSchoolConfigurationSetMutation = { __typename?: 'Mutation', updateSchoolConfigurationSet: Array<{ __typename?: 'SchoolConfiguration', id: string, key: string, displayName: string, value: string, school: { __typename?: 'School', id: string, name: string, level: string } }> };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', firstName?: string, email?: string, id: string, lastName?: string, loginMethod: LoginMethod, name?: string, status: UserStatus, username: string, roles?: { __typename?: 'UserRoleConnection', edges?: Array<{ __typename?: 'UserRoleEdge', node?: { __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string } } }>, nodes?: Array<{ __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string } }> } } };

export type UpdateUserPasswordMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type UpdateUserPasswordMutation = { __typename?: 'Mutation', updateUserPassword: { __typename?: 'User', firstName?: string, email?: string, employeeCode?: string, id: string, isConfidentialityAgreementValid?: boolean, isPasswordValid?: boolean, isSystemAdmin?: boolean, lastName?: string, loginMethod: LoginMethod, name?: string, status: UserStatus, username: string, linkedEmployee?: { __typename?: 'Employee', employeeCode: string, id: number, name?: string }, roles?: { __typename?: 'UserRoleConnection', edges?: Array<{ __typename?: 'UserRoleEdge', node?: { __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, level: string, name: string } } }>, nodes?: Array<{ __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }> }, school: { __typename?: 'School', id: string, level: string, name: string } }> } } };

export type AdminDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminDashboardQuery = { __typename?: 'Query', activeUsers: { __typename?: 'UserConnection', totalCount: number }, inactiveUsers: { __typename?: 'UserConnection', totalCount: number }, users: { __typename?: 'UserConnection', totalCount: number } };

export type AdvancedPopulationFilterQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  schoolId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type AdvancedPopulationFilterQuery = { __typename?: 'Query', advancedPopulationFilter: { __typename?: 'AdvancedPopulationFilter', id: string, name?: string, districtRoles?: { __typename?: 'AdvancedPopulationFilterRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'AdvancedPopulationFilterRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles?: { __typename?: 'AdvancedPopulationFilterRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'AdvancedPopulationFilterRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } } };

export type AdvancedPopulationFiltersQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AdvancedPopulationFilterOrder>;
  permittedOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type AdvancedPopulationFiltersQuery = { __typename?: 'Query', advancedPopulationFilters: { __typename?: 'AdvancedPopulationFilterConnection', totalCount: number, nodes?: Array<{ __typename?: 'AdvancedPopulationFilter', id: string, name?: string, districtRoles?: { __typename?: 'AdvancedPopulationFilterRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'AdvancedPopulationFilterRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles?: { __typename?: 'AdvancedPopulationFilterRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'AdvancedPopulationFilterRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string } } };

export type AssessmentsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<TestFilter>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TestOrder>;
}>;


export type AssessmentsQuery = { __typename?: 'Query', tests: { __typename?: 'TestConnection', totalCount: number, nodes?: Array<{ __typename?: 'Test', id: string, name: string, testRegion: TestRegion, primaryTestMethod: TestMethod }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string } } };

export type ConfigurationQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type ConfigurationQuery = { __typename?: 'Query', configuration?: { __typename?: 'Configuration', id: string, key: string, displayName: string, value: string } };

export type ConfigurationsQueryVariables = Exact<{
  type: ConfigurationType;
}>;


export type ConfigurationsQuery = { __typename?: 'Query', configurations: { __typename?: 'ConfigurationConnection', nodes?: Array<{ __typename?: 'Configuration', id: string, key: string, displayName: string, value: string }> } };

export type ConfigurationsByKeysQueryVariables = Exact<{
  keys: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ConfigurationsByKeysQuery = { __typename?: 'Query', configurationsByKeys: Array<{ __typename?: 'Configuration', id: string, key: string, displayName: string, value: string }> };

export type EmployeeQueryVariables = Exact<{
  employeeCode: Scalars['String']['input'];
}>;


export type EmployeeQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', employeeCode: string, id: number, name?: string } };

export type InterdistrictExchangeConfigurationQueryVariables = Exact<{
  schoolId: Scalars['ID']['input'];
}>;


export type InterdistrictExchangeConfigurationQuery = { __typename?: 'Query', interdistrictExchangeConfiguration?: { __typename?: 'InterdistrictExchangeConfiguration', incomingAllTests?: boolean, incomingPermitted?: boolean, outgoingAllTests?: boolean, outgoingPermitted?: boolean, lastChangedDate?: Date, incomingTestList?: Array<{ __typename?: 'Test', id: string, name: string }>, outgoingTestList?: Array<{ __typename?: 'Test', id: string, name: string }>, lastChangedBy?: { __typename?: 'User', id: string, name?: string } } };

export type OtpQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type OtpQuery = { __typename?: 'Query', otp?: { __typename?: 'Otp', email: string, code: string, expiresAt: Date } };

export type PermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionsQuery = { __typename?: 'Query', listOfPermissions: Array<{ __typename?: 'Permission', id: number, name: string }> };

export type ReportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  schoolId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ReportQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, name: string, category: { __typename?: 'ReportCategory', id: string, name: string }, type: { __typename?: 'ReportType', id: string, name: string }, districtRoles?: { __typename?: 'ReportRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRole', favorite?: boolean, permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles?: { __typename?: 'ReportRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRole', favorite?: boolean, permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } }, reportCategories?: Array<{ __typename?: 'ReportCategory', id: string, name: string }>, reportTypes?: Array<{ __typename?: 'ReportType', id: string, name: string }> };

export type ReportAttributeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  schoolId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ReportAttributeQuery = { __typename?: 'Query', attribute: { __typename?: 'Attribute', id: string, name?: string, tableType?: string, districtRoles: { __typename?: 'AttributeRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'AttributeRole', permitted?: boolean, role?: { __typename?: 'Role', id: string, name: string }, school?: { __typename?: 'School', id: string, name: string, level: string } }> }, roles: { __typename?: 'AttributeRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'AttributeRole', permitted?: boolean, role?: { __typename?: 'Role', id: string, name: string }, school?: { __typename?: 'School', id: string, name: string, level: string } }> } } };

export type ReportAttributesQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AttributeOrder>;
  permittedOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ReportAttributesQuery = { __typename?: 'Query', attributes: { __typename?: 'AttributeConnection', totalCount: number, nodes?: Array<{ __typename?: 'Attribute', id: string, name?: string, tableType?: string, districtRoles: { __typename?: 'AttributeRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'AttributeRole', permitted?: boolean, role?: { __typename?: 'Role', id: string, name: string }, school?: { __typename?: 'School', id: string, name: string, level: string } }> }, roles: { __typename?: 'AttributeRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'AttributeRole', permitted?: boolean, role?: { __typename?: 'Role', id: string, name: string }, school?: { __typename?: 'School', id: string, name: string, level: string } }> } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string } } };

export type ReportDetailSectionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  schoolId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ReportDetailSectionQuery = { __typename?: 'Query', reportDetailSection: { __typename?: 'ReportDetailSection', id: string, name?: string, report?: { __typename?: 'Report', id: string, name: string, category: { __typename?: 'ReportCategory', id: string, name: string }, type: { __typename?: 'ReportType', id: string, name: string } }, districtRoles?: { __typename?: 'ReportDetailSectionRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportDetailSectionRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles?: { __typename?: 'ReportDetailSectionRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportDetailSectionRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } } };

export type ReportDetailSectionsQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportDetailSectionOrder>;
  permittedOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ReportDetailSectionsQuery = { __typename?: 'Query', reportDetailSections: { __typename?: 'ReportDetailSectionConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportDetailSection', id: string, name?: string, report?: { __typename?: 'Report', id: string, name: string, category: { __typename?: 'ReportCategory', id: string, name: string }, type: { __typename?: 'ReportType', id: string, name: string } }, districtRoles?: { __typename?: 'ReportDetailSectionRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportDetailSectionRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles?: { __typename?: 'ReportDetailSectionRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportDetailSectionRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string } } };

export type ReportRolesBySchoolQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportOrder>;
  permittedOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ReportRolesBySchoolQuery = { __typename?: 'Query', reports?: { __typename?: 'ReportConnection', totalCount: number, nodes?: Array<{ __typename?: 'Report', id: string, name: string, category: { __typename?: 'ReportCategory', id: string, name: string }, type: { __typename?: 'ReportType', id: string, name: string }, districtRoles?: { __typename?: 'ReportRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRole', favorite?: boolean, permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles?: { __typename?: 'ReportRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRole', favorite?: boolean, permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string } }, roles: Array<{ __typename?: 'Role', id: string, name: string }> };

export type ReportRuleCategoriesQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportRuleCategoryOrder>;
  permittedOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ReportRuleCategoriesQuery = { __typename?: 'Query', reportRuleCategories: { __typename?: 'ReportRuleCategoryConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRuleCategory', id: string, name?: string, districtRoles: { __typename?: 'ReportRuleCategoryRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRuleCategoryRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles: { __typename?: 'ReportRuleCategoryRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRuleCategoryRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string } } };

export type ReportRuleCategoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  schoolId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ReportRuleCategoryQuery = { __typename?: 'Query', reportRuleCategory: { __typename?: 'ReportRuleCategory', id: string, name?: string, districtRoles: { __typename?: 'ReportRuleCategoryRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRuleCategoryRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles: { __typename?: 'ReportRuleCategoryRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRuleCategoryRole', permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } } };

export type ReportsQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportOrder>;
  permittedOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ReportsQuery = { __typename?: 'Query', reports?: { __typename?: 'ReportConnection', totalCount: number, nodes?: Array<{ __typename?: 'Report', id: string, name: string, category: { __typename?: 'ReportCategory', id: string, name: string }, type: { __typename?: 'ReportType', id: string, name: string }, districtRoles?: { __typename?: 'ReportRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRole', favorite?: boolean, permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> }, roles?: { __typename?: 'ReportRoleConnection', totalCount: number, nodes?: Array<{ __typename?: 'ReportRole', favorite?: boolean, permitted: boolean, role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, name: string, level: string } }> } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string } } };

export type RoleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RoleQuery = { __typename?: 'Query', role?: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }>, rolePermissions: Array<{ __typename?: 'RolePermission', defaultTab?: boolean, permission: { __typename?: 'Permission', id: number, name: string } }> } };

export type RolePermissionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RolePermissionsQuery = { __typename?: 'Query', role?: { __typename?: 'Role', id: string, name: string, rolePermissions: Array<{ __typename?: 'RolePermission', defaultTab?: boolean, permission: { __typename?: 'Permission', id: number, name: string } }>, permissions: Array<{ __typename?: 'Permission', id: number, name: string }> } };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }>, rolePermissions: Array<{ __typename?: 'RolePermission', defaultTab?: boolean, permission: { __typename?: 'Permission', id: number, name: string } }> }> };

export type RolesPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesPermissionsQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }>, rolePermissions: Array<{ __typename?: 'RolePermission', defaultTab?: boolean, permission: { __typename?: 'Permission', id: number, name: string } }> }> };

export type SchoolQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SchoolQuery = { __typename?: 'Query', school?: { __typename?: 'School', id: string, name: string, level: string } };

export type SchoolConfigurationQueryVariables = Exact<{
  key: Scalars['String']['input'];
  schoolId: Scalars['ID']['input'];
}>;


export type SchoolConfigurationQuery = { __typename?: 'Query', schoolConfiguration?: { __typename?: 'SchoolConfiguration', id: string, key: string, displayName: string, value: string, school: { __typename?: 'School', id: string, name: string, level: string } } };

export type SchoolConfigurationsQueryVariables = Exact<{
  type: SchoolConfigurationType;
  schoolId: Scalars['ID']['input'];
}>;


export type SchoolConfigurationsQuery = { __typename?: 'Query', schoolConfigurations: { __typename?: 'SchoolConfigurationConnection', nodes?: Array<{ __typename?: 'SchoolConfiguration', id: string, key: string, displayName: string, value: string, school: { __typename?: 'School', id: string, name: string, level: string } }> } };

export type SchoolsQueryVariables = Exact<{ [key: string]: never; }>;


export type SchoolsQuery = { __typename?: 'Query', schools: Array<{ __typename?: 'School', id: string, name: string, level: string }> };

export type ServerStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type ServerStatusQuery = { __typename?: 'Query', serverStatus?: { __typename?: 'ServerStatus', database?: DatabaseStatus } };

export type UserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  statuses?: InputMaybe<Array<UserStatus> | UserStatus>;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', firstName?: string, email?: string, employeeCode?: string, id: string, isConfidentialityAgreementValid?: boolean, isPasswordValid?: boolean, isSystemAdmin?: boolean, lastName?: string, loginMethod: LoginMethod, name?: string, status: UserStatus, username: string, linkedEmployee?: { __typename?: 'Employee', employeeCode: string, id: number, name?: string }, roles?: { __typename?: 'UserRoleConnection', edges?: Array<{ __typename?: 'UserRoleEdge', node?: { __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, level: string, name: string } } }>, nodes?: Array<{ __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }> }, school: { __typename?: 'School', id: string, level: string, name: string } }> } } };

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type UserByEmailQuery = { __typename?: 'Query', userByEmail?: { __typename?: 'User', firstName?: string, email?: string, employeeCode?: string, id: string, isConfidentialityAgreementValid?: boolean, isPasswordValid?: boolean, isSystemAdmin?: boolean, lastName?: string, loginMethod: LoginMethod, name?: string, status: UserStatus, username: string, linkedEmployee?: { __typename?: 'Employee', employeeCode: string, id: number, name?: string }, roles?: { __typename?: 'UserRoleConnection', edges?: Array<{ __typename?: 'UserRoleEdge', node?: { __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, level: string, name: string } } }>, nodes?: Array<{ __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }> }, school: { __typename?: 'School', id: string, level: string, name: string } }> } } };

export type UserByUsernameQueryVariables = Exact<{
  username: Scalars['ID']['input'];
}>;


export type UserByUsernameQuery = { __typename?: 'Query', userByUsername?: { __typename?: 'User', firstName?: string, email?: string, employeeCode?: string, id: string, isConfidentialityAgreementValid?: boolean, isPasswordValid?: boolean, isSystemAdmin?: boolean, lastName?: string, loginMethod: LoginMethod, name?: string, status: UserStatus, username: string, linkedEmployee?: { __typename?: 'Employee', employeeCode: string, id: number, name?: string }, roles?: { __typename?: 'UserRoleConnection', edges?: Array<{ __typename?: 'UserRoleEdge', node?: { __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string }, school: { __typename?: 'School', id: string, level: string, name: string } } }>, nodes?: Array<{ __typename?: 'UserRole', role: { __typename?: 'Role', id: string, name: string, permissions: Array<{ __typename?: 'Permission', id: number, name: string }> }, school: { __typename?: 'School', id: string, level: string, name: string } }> } } };

export type UsersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<UserFilter>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrder>;
  status?: InputMaybe<UserStatus>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', totalCount: number, nodes?: Array<{ __typename?: 'User', firstName?: string, email?: string, id: string, lastName?: string, loginMethod: LoginMethod, name?: string, status: UserStatus, username: string, linkedEmployee?: { __typename?: 'Employee', employeeCode: string, id: number, name?: string }, roles?: { __typename?: 'UserRoleConnection', totalCount: number } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string } } };


export const AdvancedPopulationFilterRoleFieldsFragmentDoc = `
    fragment AdvancedPopulationFilterRoleFields on AdvancedPopulationFilterRole {
  permitted
  role {
    id
    name
  }
  school {
    id
    name
    level
  }
}
    `;
export const AdvancedPopulationFilterFieldsFragmentDoc = `
    fragment AdvancedPopulationFilterFields on AdvancedPopulationFilter {
  id
  name
  districtRoles {
    nodes {
      ...AdvancedPopulationFilterRoleFields
    }
    totalCount
  }
  roles(schoolId: $schoolId) {
    nodes {
      ...AdvancedPopulationFilterRoleFields
    }
    totalCount
  }
}
    ${AdvancedPopulationFilterRoleFieldsFragmentDoc}`;
export const AssessmentFieldsFragmentDoc = `
    fragment AssessmentFields on Test {
  id
  name
  testRegion
  primaryTestMethod
}
    `;
export const ConfigurationFieldsFragmentDoc = `
    fragment ConfigurationFields on Configuration {
  id
  key
  displayName
  value
}
    `;
export const InterdistrictExchangeConfigurationFieldsFragmentDoc = `
    fragment InterdistrictExchangeConfigurationFields on InterdistrictExchangeConfiguration {
  incomingAllTests
  incomingPermitted
  incomingTestList {
    id
    name
  }
  outgoingAllTests
  outgoingPermitted
  outgoingTestList {
    id
    name
  }
  lastChangedBy {
    id
    name
  }
  lastChangedDate
}
    `;
export const OtpFieldsFragmentDoc = `
    fragment OtpFields on Otp {
  email
  code
  expiresAt
}
    `;
export const ReportAttributeRoleFieldsFragmentDoc = `
    fragment ReportAttributeRoleFields on AttributeRole {
  permitted
  role {
    id
    name
  }
  school {
    id
    name
    level
  }
}
    `;
export const ReportAttributeFieldsFragmentDoc = `
    fragment ReportAttributeFields on Attribute {
  id
  name
  tableType
  districtRoles {
    nodes {
      ...ReportAttributeRoleFields
    }
    totalCount
  }
  roles(schoolId: $schoolId) {
    nodes {
      ...ReportAttributeRoleFields
    }
    totalCount
  }
}
    ${ReportAttributeRoleFieldsFragmentDoc}`;
export const ReportCategoryFieldsFragmentDoc = `
    fragment ReportCategoryFields on ReportCategory {
  id
  name
}
    `;
export const ReportDetailSectionRoleFieldsFragmentDoc = `
    fragment ReportDetailSectionRoleFields on ReportDetailSectionRole {
  permitted
  role {
    id
    name
  }
  school {
    id
    name
    level
  }
}
    `;
export const ReportDetailSectionFieldsFragmentDoc = `
    fragment ReportDetailSectionFields on ReportDetailSection {
  id
  name
  report {
    id
    name
    category {
      id
      name
    }
    type {
      id
      name
    }
  }
  districtRoles {
    nodes {
      ...ReportDetailSectionRoleFields
    }
    totalCount
  }
  roles(schoolId: $schoolId) {
    nodes {
      ...ReportDetailSectionRoleFields
    }
    totalCount
  }
}
    ${ReportDetailSectionRoleFieldsFragmentDoc}`;
export const ReportRoleFieldsFragmentDoc = `
    fragment ReportRoleFields on ReportRole {
  favorite
  permitted
  role {
    id
    name
  }
  school {
    id
    name
    level
  }
}
    `;
export const ReportFieldsFragmentDoc = `
    fragment ReportFields on Report {
  id
  name
  category {
    id
    name
  }
  type {
    id
    name
  }
  districtRoles {
    nodes {
      ...ReportRoleFields
    }
    totalCount
  }
  roles(schoolId: $schoolId) {
    nodes {
      ...ReportRoleFields
    }
    totalCount
  }
}
    ${ReportRoleFieldsFragmentDoc}`;
export const ReportRuleCategoryRoleFieldsFragmentDoc = `
    fragment ReportRuleCategoryRoleFields on ReportRuleCategoryRole {
  permitted
  role {
    id
    name
  }
  school {
    id
    name
    level
  }
}
    `;
export const ReportRuleCategoryFieldsFragmentDoc = `
    fragment ReportRuleCategoryFields on ReportRuleCategory {
  id
  name
  districtRoles {
    nodes {
      ...ReportRuleCategoryRoleFields
    }
    totalCount
  }
  roles(schoolId: $schoolId) {
    nodes {
      ...ReportRuleCategoryRoleFields
    }
    totalCount
  }
}
    ${ReportRuleCategoryRoleFieldsFragmentDoc}`;
export const ReportTypeFieldsFragmentDoc = `
    fragment ReportTypeFields on ReportType {
  id
  name
}
    `;
export const RoleFieldsFragmentDoc = `
    fragment RoleFields on Role {
  id
  name
  permissions {
    id
    name
  }
  rolePermissions {
    permission {
      id
      name
    }
    defaultTab
  }
}
    `;
export const PermissionFieldsFragmentDoc = `
    fragment PermissionFields on Permission {
  id
  name
}
    `;
export const RolePermissionFromRoleFieldsFragmentDoc = `
    fragment RolePermissionFromRoleFields on RolePermission {
  permission {
    ...PermissionFields
  }
  defaultTab
}
    ${PermissionFieldsFragmentDoc}`;
export const SchoolConfigurationFieldsFragmentDoc = `
    fragment SchoolConfigurationFields on SchoolConfiguration {
  id
  school {
    id
    name
    level
  }
  key
  displayName
  value
}
    `;
export const SchoolFieldsFragmentDoc = `
    fragment SchoolFields on School {
  id
  name
  level
}
    `;
export const UserFieldsFragmentDoc = `
    fragment UserFields on User {
  firstName
  email
  employeeCode
  id
  isConfidentialityAgreementValid
  isPasswordValid
  isSystemAdmin
  lastName
  linkedEmployee {
    employeeCode
    id
    name
  }
  loginMethod
  name
  roles {
    edges {
      node {
        role {
          id
          name
        }
        school {
          id
          level
          name
        }
      }
    }
    nodes {
      role {
        id
        name
        permissions {
          id
          name
        }
      }
      school {
        id
        level
        name
      }
    }
  }
  status
  username
}
    `;
export const AddOtpDocument = `
    mutation addOtp($email: String!, $code: String!, $createdBy: String) {
  addOtp(email: $email, code: $code, createdBy: $createdBy) {
    ...OtpFields
  }
}
    ${OtpFieldsFragmentDoc}`;

export const useAddOtpMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddOtpMutation, TError, AddOtpMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<AddOtpMutation, TError, AddOtpMutationVariables, TContext>(
      ['addOtp'],
      (variables?: AddOtpMutationVariables) => fetcher<AddOtpMutation, AddOtpMutationVariables>(client, AddOtpDocument, variables, headers)(),
      options
    )};

export const AddRoleDocument = `
    mutation AddRole($role: RoleInput!) {
  addRole(role: $role) {
    ...RoleFields
  }
}
    ${RoleFieldsFragmentDoc}`;

export const useAddRoleMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddRoleMutation, TError, AddRoleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<AddRoleMutation, TError, AddRoleMutationVariables, TContext>(
      ['AddRole'],
      (variables?: AddRoleMutationVariables) => fetcher<AddRoleMutation, AddRoleMutationVariables>(client, AddRoleDocument, variables, headers)(),
      options
    )};

export const AddUserRoleDocument = `
    mutation AddUserRole($input: AddUserRoleInput!) {
  addUserRole(input: $input) {
    role {
      id
      name
    }
    school {
      id
      name
    }
  }
}
    `;

export const useAddUserRoleMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddUserRoleMutation, TError, AddUserRoleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<AddUserRoleMutation, TError, AddUserRoleMutationVariables, TContext>(
      ['AddUserRole'],
      (variables?: AddUserRoleMutationVariables) => fetcher<AddUserRoleMutation, AddUserRoleMutationVariables>(client, AddUserRoleDocument, variables, headers)(),
      options
    )};

export const DeleteRoleDocument = `
    mutation DeleteRole($roleId: ID!) {
  deleteRole(roleId: $roleId)
}
    `;

export const useDeleteRoleMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteRoleMutation, TError, DeleteRoleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteRoleMutation, TError, DeleteRoleMutationVariables, TContext>(
      ['DeleteRole'],
      (variables?: DeleteRoleMutationVariables) => fetcher<DeleteRoleMutation, DeleteRoleMutationVariables>(client, DeleteRoleDocument, variables, headers)(),
      options
    )};

export const DeleteUserRoleDocument = `
    mutation DeleteUserRole($input: DeleteUserRoleInput!) {
  deleteUserRole(input: $input)
}
    `;

export const useDeleteUserRoleMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteUserRoleMutation, TError, DeleteUserRoleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteUserRoleMutation, TError, DeleteUserRoleMutationVariables, TContext>(
      ['DeleteUserRole'],
      (variables?: DeleteUserRoleMutationVariables) => fetcher<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>(client, DeleteUserRoleDocument, variables, headers)(),
      options
    )};

export const DuplicateRoleDocument = `
    mutation DuplicateRole($sourceRoleId: ID!, $targetRoleName: String!) {
  duplicateRole(sourceRoleId: $sourceRoleId, targetRoleName: $targetRoleName) {
    ...RoleFields
  }
}
    ${RoleFieldsFragmentDoc}`;

export const useDuplicateRoleMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DuplicateRoleMutation, TError, DuplicateRoleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DuplicateRoleMutation, TError, DuplicateRoleMutationVariables, TContext>(
      ['DuplicateRole'],
      (variables?: DuplicateRoleMutationVariables) => fetcher<DuplicateRoleMutation, DuplicateRoleMutationVariables>(client, DuplicateRoleDocument, variables, headers)(),
      options
    )};

export const InvalidateOtpDocument = `
    mutation invalidateOtp($email: String!, $code: String!) {
  invalidateOtp(email: $email, code: $code)
}
    `;

export const useInvalidateOtpMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InvalidateOtpMutation, TError, InvalidateOtpMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<InvalidateOtpMutation, TError, InvalidateOtpMutationVariables, TContext>(
      ['invalidateOtp'],
      (variables?: InvalidateOtpMutationVariables) => fetcher<InvalidateOtpMutation, InvalidateOtpMutationVariables>(client, InvalidateOtpDocument, variables, headers)(),
      options
    )};

export const LinkUserToEmployeeDocument = `
    mutation linkUserToEmployee($userId: ID!, $employeeCode: String!) {
  linkUserToEmployee(userId: $userId, employeeCode: $employeeCode) {
    firstName
    email
    employeeCode
    id
    lastName
    linkedEmployee {
      employeeCode
      id
      name
    }
    loginMethod
    name
    status
    username
    roles {
      edges {
        node {
          role {
            id
            name
          }
          school {
            id
            level
            name
          }
        }
      }
    }
  }
}
    `;

export const useLinkUserToEmployeeMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<LinkUserToEmployeeMutation, TError, LinkUserToEmployeeMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<LinkUserToEmployeeMutation, TError, LinkUserToEmployeeMutationVariables, TContext>(
      ['linkUserToEmployee'],
      (variables?: LinkUserToEmployeeMutationVariables) => fetcher<LinkUserToEmployeeMutation, LinkUserToEmployeeMutationVariables>(client, LinkUserToEmployeeDocument, variables, headers)(),
      options
    )};

export const LoginDocument = `
    mutation login($username: String!, $password: String!, $isPasswordHashed: Boolean) {
  login(
    username: $username
    password: $password
    isPasswordHashed: $isPasswordHashed
  ) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

export const useLoginMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      ['login'],
      (variables?: LoginMutationVariables) => fetcher<LoginMutation, LoginMutationVariables>(client, LoginDocument, variables, headers)(),
      options
    )};

export const UnlinkEmployeeFromUserDocument = `
    mutation unlinkEmployeeFromUser($userId: ID!) {
  unlinkEmployeeFromUser(userId: $userId) {
    firstName
    email
    id
    lastName
    loginMethod
    name
    status
    username
    roles {
      edges {
        node {
          role {
            id
            name
          }
          school {
            id
            name
          }
        }
      }
      nodes {
        role {
          id
          name
        }
        school {
          id
          name
        }
      }
    }
  }
}
    `;

export const useUnlinkEmployeeFromUserMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UnlinkEmployeeFromUserMutation, TError, UnlinkEmployeeFromUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UnlinkEmployeeFromUserMutation, TError, UnlinkEmployeeFromUserMutationVariables, TContext>(
      ['unlinkEmployeeFromUser'],
      (variables?: UnlinkEmployeeFromUserMutationVariables) => fetcher<UnlinkEmployeeFromUserMutation, UnlinkEmployeeFromUserMutationVariables>(client, UnlinkEmployeeFromUserDocument, variables, headers)(),
      options
    )};

export const UpdateAdvancedPopulationFilterRoleSetDocument = `
    mutation UpdateAdvancedPopulationFilterRoleSet($input: [AdvancedPopulationFilterRoleInput]!) {
  updateAdvancedPopulationFilterRoleSet(input: $input) {
    permitted
    role {
      id
      name
    }
  }
}
    `;

export const useUpdateAdvancedPopulationFilterRoleSetMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateAdvancedPopulationFilterRoleSetMutation, TError, UpdateAdvancedPopulationFilterRoleSetMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateAdvancedPopulationFilterRoleSetMutation, TError, UpdateAdvancedPopulationFilterRoleSetMutationVariables, TContext>(
      ['UpdateAdvancedPopulationFilterRoleSet'],
      (variables?: UpdateAdvancedPopulationFilterRoleSetMutationVariables) => fetcher<UpdateAdvancedPopulationFilterRoleSetMutation, UpdateAdvancedPopulationFilterRoleSetMutationVariables>(client, UpdateAdvancedPopulationFilterRoleSetDocument, variables, headers)(),
      options
    )};

export const UpdateAttributeRoleSetDocument = `
    mutation UpdateAttributeRoleSet($input: [AttributeRoleInput]!) {
  updateAttributeRoleSet(input: $input) {
    permitted
    role {
      id
      name
    }
  }
}
    `;

export const useUpdateAttributeRoleSetMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateAttributeRoleSetMutation, TError, UpdateAttributeRoleSetMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateAttributeRoleSetMutation, TError, UpdateAttributeRoleSetMutationVariables, TContext>(
      ['UpdateAttributeRoleSet'],
      (variables?: UpdateAttributeRoleSetMutationVariables) => fetcher<UpdateAttributeRoleSetMutation, UpdateAttributeRoleSetMutationVariables>(client, UpdateAttributeRoleSetDocument, variables, headers)(),
      options
    )};

export const UpdateConfigurationSetDocument = `
    mutation updateConfigurationSet($input: [UpdateConfigurationValueInput!]!) {
  updateConfigurationSet(input: $input) {
    ...ConfigurationFields
  }
}
    ${ConfigurationFieldsFragmentDoc}`;

export const useUpdateConfigurationSetMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateConfigurationSetMutation, TError, UpdateConfigurationSetMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateConfigurationSetMutation, TError, UpdateConfigurationSetMutationVariables, TContext>(
      ['updateConfigurationSet'],
      (variables?: UpdateConfigurationSetMutationVariables) => fetcher<UpdateConfigurationSetMutation, UpdateConfigurationSetMutationVariables>(client, UpdateConfigurationSetDocument, variables, headers)(),
      options
    )};

export const UpdateInterdistrictExchangeConfigurationDocument = `
    mutation updateInterdistrictExchangeConfiguration($input: UpdateInterdistrictExchangeConfigurationInput!) {
  updateInterdistrictExchangeConfiguration(input: $input) {
    ...InterdistrictExchangeConfigurationFields
  }
}
    ${InterdistrictExchangeConfigurationFieldsFragmentDoc}`;

export const useUpdateInterdistrictExchangeConfigurationMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateInterdistrictExchangeConfigurationMutation, TError, UpdateInterdistrictExchangeConfigurationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateInterdistrictExchangeConfigurationMutation, TError, UpdateInterdistrictExchangeConfigurationMutationVariables, TContext>(
      ['updateInterdistrictExchangeConfiguration'],
      (variables?: UpdateInterdistrictExchangeConfigurationMutationVariables) => fetcher<UpdateInterdistrictExchangeConfigurationMutation, UpdateInterdistrictExchangeConfigurationMutationVariables>(client, UpdateInterdistrictExchangeConfigurationDocument, variables, headers)(),
      options
    )};

export const UpdateReportDetailSectionRoleSetDocument = `
    mutation UpdateReportDetailSectionRoleSet($input: [ReportDetailSectionRoleInput]!) {
  updateReportDetailSectionRoleSet(input: $input) {
    permitted
    role {
      id
      name
    }
  }
}
    `;

export const useUpdateReportDetailSectionRoleSetMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateReportDetailSectionRoleSetMutation, TError, UpdateReportDetailSectionRoleSetMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateReportDetailSectionRoleSetMutation, TError, UpdateReportDetailSectionRoleSetMutationVariables, TContext>(
      ['UpdateReportDetailSectionRoleSet'],
      (variables?: UpdateReportDetailSectionRoleSetMutationVariables) => fetcher<UpdateReportDetailSectionRoleSetMutation, UpdateReportDetailSectionRoleSetMutationVariables>(client, UpdateReportDetailSectionRoleSetDocument, variables, headers)(),
      options
    )};

export const UpdateReportRoleDocument = `
    mutation UpdateReportRole($input: ReportRoleInput!) {
  updateReportRole(input: $input) {
    favorite
    permitted
    role {
      id
      name
    }
  }
}
    `;

export const useUpdateReportRoleMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateReportRoleMutation, TError, UpdateReportRoleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateReportRoleMutation, TError, UpdateReportRoleMutationVariables, TContext>(
      ['UpdateReportRole'],
      (variables?: UpdateReportRoleMutationVariables) => fetcher<UpdateReportRoleMutation, UpdateReportRoleMutationVariables>(client, UpdateReportRoleDocument, variables, headers)(),
      options
    )};

export const UpdateReportRoleSetDocument = `
    mutation UpdateReportRoleSet($input: [ReportRoleSetItemInput]!) {
  updateReportRoleSet(input: $input) {
    favorite
    permitted
    role {
      id
      name
    }
  }
}
    `;

export const useUpdateReportRoleSetMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateReportRoleSetMutation, TError, UpdateReportRoleSetMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateReportRoleSetMutation, TError, UpdateReportRoleSetMutationVariables, TContext>(
      ['UpdateReportRoleSet'],
      (variables?: UpdateReportRoleSetMutationVariables) => fetcher<UpdateReportRoleSetMutation, UpdateReportRoleSetMutationVariables>(client, UpdateReportRoleSetDocument, variables, headers)(),
      options
    )};

export const UpdateReportRuleCategoryRoleSetDocument = `
    mutation UpdateReportRuleCategoryRoleSet($input: [ReportRuleCategoryRoleInput]!) {
  updateReportRuleCategoryRoleSet(input: $input) {
    permitted
    role {
      id
      name
    }
  }
}
    `;

export const useUpdateReportRuleCategoryRoleSetMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateReportRuleCategoryRoleSetMutation, TError, UpdateReportRuleCategoryRoleSetMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateReportRuleCategoryRoleSetMutation, TError, UpdateReportRuleCategoryRoleSetMutationVariables, TContext>(
      ['UpdateReportRuleCategoryRoleSet'],
      (variables?: UpdateReportRuleCategoryRoleSetMutationVariables) => fetcher<UpdateReportRuleCategoryRoleSetMutation, UpdateReportRuleCategoryRoleSetMutationVariables>(client, UpdateReportRuleCategoryRoleSetDocument, variables, headers)(),
      options
    )};

export const UpdateRoleDocument = `
    mutation UpdateRole($role: RoleInput!) {
  editRole(role: $role) {
    ...RoleFields
  }
}
    ${RoleFieldsFragmentDoc}`;

export const useUpdateRoleMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateRoleMutation, TError, UpdateRoleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateRoleMutation, TError, UpdateRoleMutationVariables, TContext>(
      ['UpdateRole'],
      (variables?: UpdateRoleMutationVariables) => fetcher<UpdateRoleMutation, UpdateRoleMutationVariables>(client, UpdateRoleDocument, variables, headers)(),
      options
    )};

export const UpdateSchoolConfigurationSetDocument = `
    mutation updateSchoolConfigurationSet($input: [UpdateSchoolConfigurationValueInput!]!) {
  updateSchoolConfigurationSet(input: $input) {
    ...SchoolConfigurationFields
  }
}
    ${SchoolConfigurationFieldsFragmentDoc}`;

export const useUpdateSchoolConfigurationSetMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateSchoolConfigurationSetMutation, TError, UpdateSchoolConfigurationSetMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateSchoolConfigurationSetMutation, TError, UpdateSchoolConfigurationSetMutationVariables, TContext>(
      ['updateSchoolConfigurationSet'],
      (variables?: UpdateSchoolConfigurationSetMutationVariables) => fetcher<UpdateSchoolConfigurationSetMutation, UpdateSchoolConfigurationSetMutationVariables>(client, UpdateSchoolConfigurationSetDocument, variables, headers)(),
      options
    )};

export const UpdateUserDocument = `
    mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    firstName
    email
    id
    lastName
    loginMethod
    name
    status
    username
    roles {
      edges {
        node {
          role {
            id
            name
          }
          school {
            id
            name
          }
        }
      }
      nodes {
        role {
          id
          name
        }
        school {
          id
          name
        }
      }
    }
  }
}
    `;

export const useUpdateUserMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['updateUser'],
      (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(client, UpdateUserDocument, variables, headers)(),
      options
    )};

export const UpdateUserPasswordDocument = `
    mutation updateUserPassword($username: String!, $password: String!, $token: String!) {
  updateUserPassword(username: $username, password: $password, token: $token) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

export const useUpdateUserPasswordMutation = <
      TError = GraphQLError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserPasswordMutation, TError, UpdateUserPasswordMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateUserPasswordMutation, TError, UpdateUserPasswordMutationVariables, TContext>(
      ['updateUserPassword'],
      (variables?: UpdateUserPasswordMutationVariables) => fetcher<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(client, UpdateUserPasswordDocument, variables, headers)(),
      options
    )};

export const AdminDashboardDocument = `
    query adminDashboard {
  activeUsers: users(status: active) {
    totalCount
  }
  inactiveUsers: users(status: inactive) {
    totalCount
  }
  users {
    totalCount
  }
}
    `;

export const useAdminDashboardQuery = <
      TData = AdminDashboardQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: AdminDashboardQueryVariables,
      options?: UseQueryOptions<AdminDashboardQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<AdminDashboardQuery, TError, TData>(
      variables === undefined ? ['adminDashboard'] : ['adminDashboard', variables],
      fetcher<AdminDashboardQuery, AdminDashboardQueryVariables>(client, AdminDashboardDocument, variables, headers),
      options
    )};

useAdminDashboardQuery.getKey = (variables?: AdminDashboardQueryVariables) => variables === undefined ? ['adminDashboard'] : ['adminDashboard', variables];

export const AdvancedPopulationFilterDocument = `
    query AdvancedPopulationFilter($id: ID!, $schoolId: ID) {
  advancedPopulationFilter(id: $id) {
    ...AdvancedPopulationFilterFields
  }
}
    ${AdvancedPopulationFilterFieldsFragmentDoc}`;

export const useAdvancedPopulationFilterQuery = <
      TData = AdvancedPopulationFilterQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: AdvancedPopulationFilterQueryVariables,
      options?: UseQueryOptions<AdvancedPopulationFilterQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<AdvancedPopulationFilterQuery, TError, TData>(
      ['AdvancedPopulationFilter', variables],
      fetcher<AdvancedPopulationFilterQuery, AdvancedPopulationFilterQueryVariables>(client, AdvancedPopulationFilterDocument, variables, headers),
      options
    )};

useAdvancedPopulationFilterQuery.getKey = (variables: AdvancedPopulationFilterQueryVariables) => ['AdvancedPopulationFilter', variables];

export const AdvancedPopulationFiltersDocument = `
    query AdvancedPopulationFilters($schoolId: ID, $after: String, $before: String, $first: Int, $last: Int, $orderBy: AdvancedPopulationFilterOrder, $permittedOnly: Boolean) {
  advancedPopulationFilters(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    permittedOnly: $permittedOnly
  ) {
    nodes {
      ...AdvancedPopulationFilterFields
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${AdvancedPopulationFilterFieldsFragmentDoc}`;

export const useAdvancedPopulationFiltersQuery = <
      TData = AdvancedPopulationFiltersQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: AdvancedPopulationFiltersQueryVariables,
      options?: UseQueryOptions<AdvancedPopulationFiltersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<AdvancedPopulationFiltersQuery, TError, TData>(
      variables === undefined ? ['AdvancedPopulationFilters'] : ['AdvancedPopulationFilters', variables],
      fetcher<AdvancedPopulationFiltersQuery, AdvancedPopulationFiltersQueryVariables>(client, AdvancedPopulationFiltersDocument, variables, headers),
      options
    )};

useAdvancedPopulationFiltersQuery.getKey = (variables?: AdvancedPopulationFiltersQueryVariables) => variables === undefined ? ['AdvancedPopulationFilters'] : ['AdvancedPopulationFilters', variables];

export const AssessmentsDocument = `
    query assessments($after: String, $first: Int, $before: String, $filterBy: TestFilter, $last: Int, $orderBy: TestOrder) {
  tests(
    after: $after
    first: $first
    before: $before
    filterBy: $filterBy
    last: $last
    orderBy: $orderBy
  ) {
    nodes {
      ...AssessmentFields
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${AssessmentFieldsFragmentDoc}`;

export const useAssessmentsQuery = <
      TData = AssessmentsQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: AssessmentsQueryVariables,
      options?: UseQueryOptions<AssessmentsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<AssessmentsQuery, TError, TData>(
      variables === undefined ? ['assessments'] : ['assessments', variables],
      fetcher<AssessmentsQuery, AssessmentsQueryVariables>(client, AssessmentsDocument, variables, headers),
      options
    )};

useAssessmentsQuery.getKey = (variables?: AssessmentsQueryVariables) => variables === undefined ? ['assessments'] : ['assessments', variables];

export const ConfigurationDocument = `
    query configuration($key: String!) {
  configuration(key: $key) {
    ...ConfigurationFields
  }
}
    ${ConfigurationFieldsFragmentDoc}`;

export const useConfigurationQuery = <
      TData = ConfigurationQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: ConfigurationQueryVariables,
      options?: UseQueryOptions<ConfigurationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ConfigurationQuery, TError, TData>(
      ['configuration', variables],
      fetcher<ConfigurationQuery, ConfigurationQueryVariables>(client, ConfigurationDocument, variables, headers),
      options
    )};

useConfigurationQuery.getKey = (variables: ConfigurationQueryVariables) => ['configuration', variables];

export const ConfigurationsDocument = `
    query configurations($type: ConfigurationType!) {
  configurations(type: $type) {
    nodes {
      ...ConfigurationFields
    }
  }
}
    ${ConfigurationFieldsFragmentDoc}`;

export const useConfigurationsQuery = <
      TData = ConfigurationsQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: ConfigurationsQueryVariables,
      options?: UseQueryOptions<ConfigurationsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ConfigurationsQuery, TError, TData>(
      ['configurations', variables],
      fetcher<ConfigurationsQuery, ConfigurationsQueryVariables>(client, ConfigurationsDocument, variables, headers),
      options
    )};

useConfigurationsQuery.getKey = (variables: ConfigurationsQueryVariables) => ['configurations', variables];

export const ConfigurationsByKeysDocument = `
    query configurationsByKeys($keys: [String!]!) {
  configurationsByKeys(keys: $keys) {
    ...ConfigurationFields
  }
}
    ${ConfigurationFieldsFragmentDoc}`;

export const useConfigurationsByKeysQuery = <
      TData = ConfigurationsByKeysQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: ConfigurationsByKeysQueryVariables,
      options?: UseQueryOptions<ConfigurationsByKeysQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ConfigurationsByKeysQuery, TError, TData>(
      ['configurationsByKeys', variables],
      fetcher<ConfigurationsByKeysQuery, ConfigurationsByKeysQueryVariables>(client, ConfigurationsByKeysDocument, variables, headers),
      options
    )};

useConfigurationsByKeysQuery.getKey = (variables: ConfigurationsByKeysQueryVariables) => ['configurationsByKeys', variables];

export const EmployeeDocument = `
    query employee($employeeCode: String!) {
  employee(employeeCode: $employeeCode) {
    employeeCode
    id
    name
  }
}
    `;

export const useEmployeeQuery = <
      TData = EmployeeQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: EmployeeQueryVariables,
      options?: UseQueryOptions<EmployeeQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<EmployeeQuery, TError, TData>(
      ['employee', variables],
      fetcher<EmployeeQuery, EmployeeQueryVariables>(client, EmployeeDocument, variables, headers),
      options
    )};

useEmployeeQuery.getKey = (variables: EmployeeQueryVariables) => ['employee', variables];

export const InterdistrictExchangeConfigurationDocument = `
    query interdistrictExchangeConfiguration($schoolId: ID!) {
  interdistrictExchangeConfiguration(schoolId: $schoolId) {
    ...InterdistrictExchangeConfigurationFields
  }
}
    ${InterdistrictExchangeConfigurationFieldsFragmentDoc}`;

export const useInterdistrictExchangeConfigurationQuery = <
      TData = InterdistrictExchangeConfigurationQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: InterdistrictExchangeConfigurationQueryVariables,
      options?: UseQueryOptions<InterdistrictExchangeConfigurationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<InterdistrictExchangeConfigurationQuery, TError, TData>(
      ['interdistrictExchangeConfiguration', variables],
      fetcher<InterdistrictExchangeConfigurationQuery, InterdistrictExchangeConfigurationQueryVariables>(client, InterdistrictExchangeConfigurationDocument, variables, headers),
      options
    )};

useInterdistrictExchangeConfigurationQuery.getKey = (variables: InterdistrictExchangeConfigurationQueryVariables) => ['interdistrictExchangeConfiguration', variables];

export const OtpDocument = `
    query otp($email: String!) {
  otp(email: $email) {
    ...OtpFields
  }
}
    ${OtpFieldsFragmentDoc}`;

export const useOtpQuery = <
      TData = OtpQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: OtpQueryVariables,
      options?: UseQueryOptions<OtpQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<OtpQuery, TError, TData>(
      ['otp', variables],
      fetcher<OtpQuery, OtpQueryVariables>(client, OtpDocument, variables, headers),
      options
    )};

useOtpQuery.getKey = (variables: OtpQueryVariables) => ['otp', variables];

export const PermissionsDocument = `
    query permissions {
  listOfPermissions {
    ...PermissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;

export const usePermissionsQuery = <
      TData = PermissionsQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: PermissionsQueryVariables,
      options?: UseQueryOptions<PermissionsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<PermissionsQuery, TError, TData>(
      variables === undefined ? ['permissions'] : ['permissions', variables],
      fetcher<PermissionsQuery, PermissionsQueryVariables>(client, PermissionsDocument, variables, headers),
      options
    )};

usePermissionsQuery.getKey = (variables?: PermissionsQueryVariables) => variables === undefined ? ['permissions'] : ['permissions', variables];

export const ReportDocument = `
    query Report($id: ID!, $schoolId: ID) {
  report(id: $id) {
    ...ReportFields
  }
  reportCategories {
    ...ReportCategoryFields
  }
  reportTypes {
    ...ReportTypeFields
  }
}
    ${ReportFieldsFragmentDoc}
${ReportCategoryFieldsFragmentDoc}
${ReportTypeFieldsFragmentDoc}`;

export const useReportQuery = <
      TData = ReportQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: ReportQueryVariables,
      options?: UseQueryOptions<ReportQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ReportQuery, TError, TData>(
      ['Report', variables],
      fetcher<ReportQuery, ReportQueryVariables>(client, ReportDocument, variables, headers),
      options
    )};

useReportQuery.getKey = (variables: ReportQueryVariables) => ['Report', variables];

export const ReportAttributeDocument = `
    query ReportAttribute($id: ID!, $schoolId: ID) {
  attribute(id: $id) {
    ...ReportAttributeFields
  }
}
    ${ReportAttributeFieldsFragmentDoc}`;

export const useReportAttributeQuery = <
      TData = ReportAttributeQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: ReportAttributeQueryVariables,
      options?: UseQueryOptions<ReportAttributeQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ReportAttributeQuery, TError, TData>(
      ['ReportAttribute', variables],
      fetcher<ReportAttributeQuery, ReportAttributeQueryVariables>(client, ReportAttributeDocument, variables, headers),
      options
    )};

useReportAttributeQuery.getKey = (variables: ReportAttributeQueryVariables) => ['ReportAttribute', variables];

export const ReportAttributesDocument = `
    query ReportAttributes($schoolId: ID, $after: String, $before: String, $first: Int, $last: Int, $orderBy: AttributeOrder, $permittedOnly: Boolean) {
  attributes(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    permittedOnly: $permittedOnly
  ) {
    nodes {
      ...ReportAttributeFields
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${ReportAttributeFieldsFragmentDoc}`;

export const useReportAttributesQuery = <
      TData = ReportAttributesQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: ReportAttributesQueryVariables,
      options?: UseQueryOptions<ReportAttributesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ReportAttributesQuery, TError, TData>(
      variables === undefined ? ['ReportAttributes'] : ['ReportAttributes', variables],
      fetcher<ReportAttributesQuery, ReportAttributesQueryVariables>(client, ReportAttributesDocument, variables, headers),
      options
    )};

useReportAttributesQuery.getKey = (variables?: ReportAttributesQueryVariables) => variables === undefined ? ['ReportAttributes'] : ['ReportAttributes', variables];

export const ReportDetailSectionDocument = `
    query ReportDetailSection($id: ID!, $schoolId: ID) {
  reportDetailSection(id: $id) {
    ...ReportDetailSectionFields
  }
}
    ${ReportDetailSectionFieldsFragmentDoc}`;

export const useReportDetailSectionQuery = <
      TData = ReportDetailSectionQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: ReportDetailSectionQueryVariables,
      options?: UseQueryOptions<ReportDetailSectionQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ReportDetailSectionQuery, TError, TData>(
      ['ReportDetailSection', variables],
      fetcher<ReportDetailSectionQuery, ReportDetailSectionQueryVariables>(client, ReportDetailSectionDocument, variables, headers),
      options
    )};

useReportDetailSectionQuery.getKey = (variables: ReportDetailSectionQueryVariables) => ['ReportDetailSection', variables];

export const ReportDetailSectionsDocument = `
    query ReportDetailSections($schoolId: ID, $after: String, $before: String, $first: Int, $last: Int, $orderBy: ReportDetailSectionOrder, $permittedOnly: Boolean) {
  reportDetailSections(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    permittedOnly: $permittedOnly
  ) {
    nodes {
      ...ReportDetailSectionFields
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${ReportDetailSectionFieldsFragmentDoc}`;

export const useReportDetailSectionsQuery = <
      TData = ReportDetailSectionsQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: ReportDetailSectionsQueryVariables,
      options?: UseQueryOptions<ReportDetailSectionsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ReportDetailSectionsQuery, TError, TData>(
      variables === undefined ? ['ReportDetailSections'] : ['ReportDetailSections', variables],
      fetcher<ReportDetailSectionsQuery, ReportDetailSectionsQueryVariables>(client, ReportDetailSectionsDocument, variables, headers),
      options
    )};

useReportDetailSectionsQuery.getKey = (variables?: ReportDetailSectionsQueryVariables) => variables === undefined ? ['ReportDetailSections'] : ['ReportDetailSections', variables];

export const ReportRolesBySchoolDocument = `
    query ReportRolesBySchool($schoolId: ID, $after: String, $before: String, $first: Int, $last: Int, $orderBy: ReportOrder, $permittedOnly: Boolean) {
  reports(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    permittedOnly: $permittedOnly
  ) {
    nodes {
      ...ReportFields
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
  roles {
    id
    name
  }
}
    ${ReportFieldsFragmentDoc}`;

export const useReportRolesBySchoolQuery = <
      TData = ReportRolesBySchoolQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: ReportRolesBySchoolQueryVariables,
      options?: UseQueryOptions<ReportRolesBySchoolQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ReportRolesBySchoolQuery, TError, TData>(
      variables === undefined ? ['ReportRolesBySchool'] : ['ReportRolesBySchool', variables],
      fetcher<ReportRolesBySchoolQuery, ReportRolesBySchoolQueryVariables>(client, ReportRolesBySchoolDocument, variables, headers),
      options
    )};

useReportRolesBySchoolQuery.getKey = (variables?: ReportRolesBySchoolQueryVariables) => variables === undefined ? ['ReportRolesBySchool'] : ['ReportRolesBySchool', variables];

export const ReportRuleCategoriesDocument = `
    query ReportRuleCategories($schoolId: ID, $after: String, $before: String, $first: Int, $last: Int, $orderBy: ReportRuleCategoryOrder, $permittedOnly: Boolean) {
  reportRuleCategories(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    permittedOnly: $permittedOnly
  ) {
    nodes {
      ...ReportRuleCategoryFields
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${ReportRuleCategoryFieldsFragmentDoc}`;

export const useReportRuleCategoriesQuery = <
      TData = ReportRuleCategoriesQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: ReportRuleCategoriesQueryVariables,
      options?: UseQueryOptions<ReportRuleCategoriesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ReportRuleCategoriesQuery, TError, TData>(
      variables === undefined ? ['ReportRuleCategories'] : ['ReportRuleCategories', variables],
      fetcher<ReportRuleCategoriesQuery, ReportRuleCategoriesQueryVariables>(client, ReportRuleCategoriesDocument, variables, headers),
      options
    )};

useReportRuleCategoriesQuery.getKey = (variables?: ReportRuleCategoriesQueryVariables) => variables === undefined ? ['ReportRuleCategories'] : ['ReportRuleCategories', variables];

export const ReportRuleCategoryDocument = `
    query ReportRuleCategory($id: ID!, $schoolId: ID) {
  reportRuleCategory(id: $id) {
    ...ReportRuleCategoryFields
  }
}
    ${ReportRuleCategoryFieldsFragmentDoc}`;

export const useReportRuleCategoryQuery = <
      TData = ReportRuleCategoryQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: ReportRuleCategoryQueryVariables,
      options?: UseQueryOptions<ReportRuleCategoryQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ReportRuleCategoryQuery, TError, TData>(
      ['ReportRuleCategory', variables],
      fetcher<ReportRuleCategoryQuery, ReportRuleCategoryQueryVariables>(client, ReportRuleCategoryDocument, variables, headers),
      options
    )};

useReportRuleCategoryQuery.getKey = (variables: ReportRuleCategoryQueryVariables) => ['ReportRuleCategory', variables];

export const ReportsDocument = `
    query Reports($schoolId: ID, $after: String, $before: String, $first: Int, $last: Int, $orderBy: ReportOrder, $permittedOnly: Boolean) {
  reports(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    permittedOnly: $permittedOnly
  ) {
    nodes {
      ...ReportFields
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${ReportFieldsFragmentDoc}`;

export const useReportsQuery = <
      TData = ReportsQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: ReportsQueryVariables,
      options?: UseQueryOptions<ReportsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ReportsQuery, TError, TData>(
      variables === undefined ? ['Reports'] : ['Reports', variables],
      fetcher<ReportsQuery, ReportsQueryVariables>(client, ReportsDocument, variables, headers),
      options
    )};

useReportsQuery.getKey = (variables?: ReportsQueryVariables) => variables === undefined ? ['Reports'] : ['Reports', variables];

export const RoleDocument = `
    query role($id: ID!) {
  role(id: $id) {
    ...RoleFields
  }
}
    ${RoleFieldsFragmentDoc}`;

export const useRoleQuery = <
      TData = RoleQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: RoleQueryVariables,
      options?: UseQueryOptions<RoleQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<RoleQuery, TError, TData>(
      ['role', variables],
      fetcher<RoleQuery, RoleQueryVariables>(client, RoleDocument, variables, headers),
      options
    )};

useRoleQuery.getKey = (variables: RoleQueryVariables) => ['role', variables];

export const RolePermissionsDocument = `
    query rolePermissions($id: ID!) {
  role(id: $id) {
    ...RoleFields
    rolePermissions {
      ...RolePermissionFromRoleFields
    }
  }
}
    ${RoleFieldsFragmentDoc}
${RolePermissionFromRoleFieldsFragmentDoc}`;

export const useRolePermissionsQuery = <
      TData = RolePermissionsQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: RolePermissionsQueryVariables,
      options?: UseQueryOptions<RolePermissionsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<RolePermissionsQuery, TError, TData>(
      ['rolePermissions', variables],
      fetcher<RolePermissionsQuery, RolePermissionsQueryVariables>(client, RolePermissionsDocument, variables, headers),
      options
    )};

useRolePermissionsQuery.getKey = (variables: RolePermissionsQueryVariables) => ['rolePermissions', variables];

export const RolesDocument = `
    query roles {
  roles {
    ...RoleFields
  }
}
    ${RoleFieldsFragmentDoc}`;

export const useRolesQuery = <
      TData = RolesQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: RolesQueryVariables,
      options?: UseQueryOptions<RolesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<RolesQuery, TError, TData>(
      variables === undefined ? ['roles'] : ['roles', variables],
      fetcher<RolesQuery, RolesQueryVariables>(client, RolesDocument, variables, headers),
      options
    )};

useRolesQuery.getKey = (variables?: RolesQueryVariables) => variables === undefined ? ['roles'] : ['roles', variables];

export const RolesPermissionsDocument = `
    query rolesPermissions {
  roles {
    ...RoleFields
    permissions {
      ...PermissionFields
    }
  }
}
    ${RoleFieldsFragmentDoc}
${PermissionFieldsFragmentDoc}`;

export const useRolesPermissionsQuery = <
      TData = RolesPermissionsQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: RolesPermissionsQueryVariables,
      options?: UseQueryOptions<RolesPermissionsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<RolesPermissionsQuery, TError, TData>(
      variables === undefined ? ['rolesPermissions'] : ['rolesPermissions', variables],
      fetcher<RolesPermissionsQuery, RolesPermissionsQueryVariables>(client, RolesPermissionsDocument, variables, headers),
      options
    )};

useRolesPermissionsQuery.getKey = (variables?: RolesPermissionsQueryVariables) => variables === undefined ? ['rolesPermissions'] : ['rolesPermissions', variables];

export const SchoolDocument = `
    query school($id: ID!) {
  school(id: $id) {
    ...SchoolFields
  }
}
    ${SchoolFieldsFragmentDoc}`;

export const useSchoolQuery = <
      TData = SchoolQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: SchoolQueryVariables,
      options?: UseQueryOptions<SchoolQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<SchoolQuery, TError, TData>(
      ['school', variables],
      fetcher<SchoolQuery, SchoolQueryVariables>(client, SchoolDocument, variables, headers),
      options
    )};

useSchoolQuery.getKey = (variables: SchoolQueryVariables) => ['school', variables];

export const SchoolConfigurationDocument = `
    query schoolConfiguration($key: String!, $schoolId: ID!) {
  schoolConfiguration(key: $key, schoolId: $schoolId) {
    ...SchoolConfigurationFields
  }
}
    ${SchoolConfigurationFieldsFragmentDoc}`;

export const useSchoolConfigurationQuery = <
      TData = SchoolConfigurationQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: SchoolConfigurationQueryVariables,
      options?: UseQueryOptions<SchoolConfigurationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<SchoolConfigurationQuery, TError, TData>(
      ['schoolConfiguration', variables],
      fetcher<SchoolConfigurationQuery, SchoolConfigurationQueryVariables>(client, SchoolConfigurationDocument, variables, headers),
      options
    )};

useSchoolConfigurationQuery.getKey = (variables: SchoolConfigurationQueryVariables) => ['schoolConfiguration', variables];

export const SchoolConfigurationsDocument = `
    query schoolConfigurations($type: SchoolConfigurationType!, $schoolId: ID!) {
  schoolConfigurations(type: $type, schoolId: $schoolId) {
    nodes {
      ...SchoolConfigurationFields
    }
  }
}
    ${SchoolConfigurationFieldsFragmentDoc}`;

export const useSchoolConfigurationsQuery = <
      TData = SchoolConfigurationsQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: SchoolConfigurationsQueryVariables,
      options?: UseQueryOptions<SchoolConfigurationsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<SchoolConfigurationsQuery, TError, TData>(
      ['schoolConfigurations', variables],
      fetcher<SchoolConfigurationsQuery, SchoolConfigurationsQueryVariables>(client, SchoolConfigurationsDocument, variables, headers),
      options
    )};

useSchoolConfigurationsQuery.getKey = (variables: SchoolConfigurationsQueryVariables) => ['schoolConfigurations', variables];

export const SchoolsDocument = `
    query schools {
  schools {
    ...SchoolFields
  }
}
    ${SchoolFieldsFragmentDoc}`;

export const useSchoolsQuery = <
      TData = SchoolsQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: SchoolsQueryVariables,
      options?: UseQueryOptions<SchoolsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<SchoolsQuery, TError, TData>(
      variables === undefined ? ['schools'] : ['schools', variables],
      fetcher<SchoolsQuery, SchoolsQueryVariables>(client, SchoolsDocument, variables, headers),
      options
    )};

useSchoolsQuery.getKey = (variables?: SchoolsQueryVariables) => variables === undefined ? ['schools'] : ['schools', variables];

export const ServerStatusDocument = `
    query serverStatus {
  serverStatus {
    database
  }
}
    `;

export const useServerStatusQuery = <
      TData = ServerStatusQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: ServerStatusQueryVariables,
      options?: UseQueryOptions<ServerStatusQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<ServerStatusQuery, TError, TData>(
      variables === undefined ? ['serverStatus'] : ['serverStatus', variables],
      fetcher<ServerStatusQuery, ServerStatusQueryVariables>(client, ServerStatusDocument, variables, headers),
      options
    )};

useServerStatusQuery.getKey = (variables?: ServerStatusQueryVariables) => variables === undefined ? ['serverStatus'] : ['serverStatus', variables];

export const UserDocument = `
    query user($id: ID!, $statuses: [UserStatus!]) {
  user(id: $id, statuses: $statuses) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

export const useUserQuery = <
      TData = UserQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: UserQueryVariables,
      options?: UseQueryOptions<UserQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<UserQuery, TError, TData>(
      ['user', variables],
      fetcher<UserQuery, UserQueryVariables>(client, UserDocument, variables, headers),
      options
    )};

useUserQuery.getKey = (variables: UserQueryVariables) => ['user', variables];

export const UserByEmailDocument = `
    query userByEmail($email: String!) {
  userByEmail(email: $email) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

export const useUserByEmailQuery = <
      TData = UserByEmailQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: UserByEmailQueryVariables,
      options?: UseQueryOptions<UserByEmailQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<UserByEmailQuery, TError, TData>(
      ['userByEmail', variables],
      fetcher<UserByEmailQuery, UserByEmailQueryVariables>(client, UserByEmailDocument, variables, headers),
      options
    )};

useUserByEmailQuery.getKey = (variables: UserByEmailQueryVariables) => ['userByEmail', variables];

export const UserByUsernameDocument = `
    query userByUsername($username: ID!) {
  userByUsername(username: $username) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

export const useUserByUsernameQuery = <
      TData = UserByUsernameQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables: UserByUsernameQueryVariables,
      options?: UseQueryOptions<UserByUsernameQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<UserByUsernameQuery, TError, TData>(
      ['userByUsername', variables],
      fetcher<UserByUsernameQuery, UserByUsernameQueryVariables>(client, UserByUsernameDocument, variables, headers),
      options
    )};

useUserByUsernameQuery.getKey = (variables: UserByUsernameQueryVariables) => ['userByUsername', variables];

export const UsersDocument = `
    query Users($after: String, $first: Int, $before: String, $filterBy: UserFilter, $last: Int, $orderBy: UserOrder, $status: UserStatus) {
  users(
    after: $after
    first: $first
    before: $before
    filterBy: $filterBy
    last: $last
    orderBy: $orderBy
    status: $status
  ) {
    nodes {
      firstName
      email
      id
      lastName
      linkedEmployee {
        employeeCode
        id
        name
      }
      loginMethod
      name
      status
      username
      roles {
        totalCount
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;

export const useUsersQuery = <
      TData = UsersQuery,
      TError = GraphQLError
    >(
      client: GraphQLClient,
      variables?: UsersQueryVariables,
      options?: UseQueryOptions<UsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<UsersQuery, TError, TData>(
      variables === undefined ? ['Users'] : ['Users', variables],
      fetcher<UsersQuery, UsersQueryVariables>(client, UsersDocument, variables, headers),
      options
    )};

useUsersQuery.getKey = (variables?: UsersQueryVariables) => variables === undefined ? ['Users'] : ['Users', variables];
