'use client'

import { useTheme } from 'next-themes'
import { useMemo } from 'react'
import type Highcharts from 'highcharts'
import _ from 'lodash'
import colors from 'tailwindcss/colors'

export const defaultTheme: Highcharts.Options = {
  colors: [
    '#0066ff',
    '#434348',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
  ],
  chart: {
    backgroundColor: 'transparent',
    className: 'font-inter!',
    style: {
      fontFamily: '',
    },
    reflow: true,
  },
  credits: {
    enabled: false,
  },
  legend: {
    itemStyle: {
      color: colors.slate[700],
      cursor: 'pointer',
      fontSize: '12px',
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
    },
    itemHiddenStyle: { color: colors.slate[300] },
    itemHoverStyle: { color: colors.slate[900] },
    floating: false,
    layout: 'horizontal',
    align: 'right',
    verticalAlign: 'top',
    itemMarginTop: -16,
  },
  plotOptions: {
    line: {
      dataLabels: {
        style: {
          color: 'contrast',
        },
      },
      marker: {
        symbol: 'circle',
        enabled: false,
      },
    },
  },
  subtitle: {
    align: 'left',
  },
  tooltip: {
    backgroundColor: colors.slate[800],
    borderColor: 'transparent',
    borderRadius: 8,
    padding: 12,
    shared: true,
    style: {
      color: 'white',
      fontWeight: '400',
    },
  },
  title: {
    align: 'left',
    style: {
      fontWeight: '500',
    },
  },
  xAxis: {
    crosshair: {
      color: '#0066ff',
      dashStyle: 'Dash',
      snap: true,
      width: 1,
    },
    gridLineColor: colors.slate[300],
    gridLineDashStyle: 'Dash',
    gridLineWidth: 1,
    labels: {
      style: {
        color: colors.slate[600],
      },
    },
    title: {
      style: {
        color: colors.slate[800],
      },
    },
    tickmarkPlacement: 'on',
    lineWidth: 1,
  },
  yAxis: {
    labels: {
      style: { color: colors.slate[600] },
    },
    title: {
      style: {
        color: colors.slate[800],
      },
    },
  },
}

const defaultThemeCopy = JSON.parse(
  JSON.stringify(defaultTheme),
) as Highcharts.Options

const darkTheme = _.merge({}, defaultThemeCopy, {
  colors: [
    '#0066ff',
    '#94a3b8',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
  ],
  plotOptions: {
    line: {
      dataLabels: {
        style: {
          color: colors.white,
          textOutline: 'none',
          fontWeight: 'regular',
        },
      },
    },
  },
  legend: {
    itemStyle: { color: colors.slate[300] },
    itemHoverStyle: { color: colors.slate[400] },
    itemHiddenStyle: { color: colors.slate[600] },
  },
  tooltip: {
    backgroundColor: colors.slate[700],
    borderColor: colors.slate[600],
  },
  xAxis: {
    gridLineColor: colors.slate[600],
    labels: {
      style: {
        color: colors.slate[300],
      },
    },
    title: {
      style: {
        color: colors.slate[400],
      },
    },
  },
  yAxis: {
    labels: {
      style: { color: colors.slate[300] },
    },
    title: {
      style: {
        color: colors.slate[400],
      },
    },
  },
})

function getHighchartsTheme(theme?: string): Highcharts.Options | undefined {
  if (!theme) {
    return defaultTheme
  }

  const styledTheme = {
    light: defaultTheme,
    dark: darkTheme,
  }[theme]

  return styledTheme
}

export function useHighchartsTheme(): Highcharts.Options | undefined {
  const { resolvedTheme } = useTheme()

  const highchartsOptions = useMemo(() => {
    const options = getHighchartsTheme(resolvedTheme)
    return options
  }, [resolvedTheme])

  return highchartsOptions
}
