'use client'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export interface Dimensions {
  bottom: number
  height: number
  offsetLeft: number
  offsetRight: number
  left: number
  right: number
  top: number
  width: number
  x: number
  y: number
}

export function getDimensions(element: Element): Dimensions {
  const { bottom, height, left, right, top, width, x, y } =
    element.getBoundingClientRect()

  const parentX = element.parentElement?.getBoundingClientRect().x ?? 0

  const offsetLeft = parentX >= 0 ? x - parentX : x
  const offsetRight = parentX >= 0 ? x - parentX + width : x + width

  const dimensions = {
    bottom,
    height,
    left,
    right,
    top,
    width,
    x,
    y,
    offsetLeft,
    offsetRight,
  }

  return dimensions
}

function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Dimensions,
  HTMLCollection | undefined,
  Dimensions[],
  (element: Element) => Dimensions,
  () => void,
] {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>()
  const [dimensions, setDimensions] = useState<Dimensions>({
    bottom: 0,
    height: 0,
    offsetLeft: 0,
    offsetRight: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  })
  const [children, setChildren] = useState<HTMLCollection>()
  const childrenSizes = useMemo(() => {
    return children
      ? Array.from(children).map((child) => getDimensions(child))
      : []
  }, [children])

  const observer = useRef(
    new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const fetchedSize = getDimensions(entry.target)
        setDimensions(fetchedSize)

        setChildren(undefined)
        setChildren(entry.target.children)
      })
    }),
  )

  const updateDimensions = useCallback(() => {
    const element = ref

    if (!element) {
      return
    }

    setDimensions(getDimensions(element))
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
  }, [])

  useEffect(() => {
    ref && observer.current.observe(ref)

    // put observer?.current into a variable so the unobserver function runs properly in cleanup
    const cleanupCurrent = observer.current

    return () => {
      ref && cleanupCurrent.unobserve(ref)
    }
  }, [ref, observer])

  return [
    setRef,
    dimensions,
    children,
    childrenSizes,
    getDimensions,
    updateDimensions,
  ]
}

export { useElementSize }
