import { GraphQLClient } from 'graphql-request'
import type { RoleQueryVariables } from '@graphql-hooks'
import { useRoleQuery as gqlUseRoleQuery } from '@graphql-hooks'

export function useRoleQuery({
  endpoint,
  variables,
}: {
  endpoint: string
  variables: RoleQueryVariables
}) {
  const client = new GraphQLClient(endpoint, { headers: {} })

  const useQueryResult = gqlUseRoleQuery(client, variables, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  return useQueryResult
}
