import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'

import {
  RoleQuery,
  RolesQuery,
  useDeleteRoleMutation as gqlUseDeleteRoleMutation,
} from '@graphql-hooks'

export function useDeleteRoleMutation({ endpoint }: { endpoint: string }) {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()
  const updateRole = gqlUseDeleteRoleMutation<unknown, boolean>(client, {
    onMutate: async ({ roleId }) => {
      if (roleId) await queryClient.cancelQueries(['role', { id: roleId }])
      await queryClient.cancelQueries(['roles'])

      queryClient.setQueryData<RoleQuery>(
        [
          'rolePermissions',
          {
            roleId: roleId,
          },
        ],
        { role: undefined },
      )

      const roles = queryClient.getQueryData<RolesQuery>(['roles'])
      queryClient.setQueryData<RolesQuery>(
        ['roles'],
        roles && { roles: roles.roles.filter((role) => role.id != roleId) },
      )

      return true
    },
    onError: (_, __, ___) => undefined,
    onSettled: (_, __, { roleId }) => {
      if (roleId) {
        queryClient.invalidateQueries(['role', { id: roleId }])
        queryClient.invalidateQueries(['rolePermissions', { id: roleId }])
      }
      queryClient.invalidateQueries(['roles'])
    },
  })

  return updateRole
}
