import { GraphQLClient } from 'graphql-request'
import type { ReportRolesBySchoolQueryVariables } from '@graphql-hooks'
import { useReportRolesBySchoolQuery as gqlUseReportRolesBySchoolQuery } from '@graphql-hooks'

export function useReportRolesBySchoolQuery({
  enabled,
  endpoint,
  variables,
}: {
  enabled?: boolean
  endpoint: string
  variables: ReportRolesBySchoolQueryVariables
}) {
  const client = new GraphQLClient(endpoint, { headers: {} })

  const useQueryResult = gqlUseReportRolesBySchoolQuery(client, variables, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled,
  })

  return useQueryResult
}
