import { GraphQLClient } from 'graphql-request'
import { useEmployeeQuery as gqlUseEmployeeQuery } from '@graphql-hooks'

export function useEmployeeQuery({
  endpoint,
  employeeCode,
}: {
  endpoint: string
  employeeCode: string
}) {
  const client = new GraphQLClient(endpoint, { headers: {} })

  const useQueryResult = gqlUseEmployeeQuery(client, { employeeCode })

  return useQueryResult
}
