import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import type {
  ReportFieldsFragment,
  ReportRoleFieldsFragment,
  ReportRoleInput,
  ReportRolesBySchoolQuery,
  ReportRolesBySchoolQueryVariables,
} from '@graphql-hooks'
import { useUpdateReportRoleMutation as gqlUseUpdateReportRoleMutation } from '@graphql-hooks'

export type QueryReport = NonNullable<
  NonNullable<ReportRolesBySchoolQuery['reports']>['nodes']
>[number]

export function getUpdatedReport(
  input: ReportRoleInput,
  report: QueryReport | undefined,
): QueryReport | undefined {
  const { favorite, permitted, roleId, schoolId } = input
  let updatedRoleNodes

  const rolesContainUpdatedRole = Boolean(
    report?.roles?.nodes?.some(
      (role) => role.role.id === roleId && role.school.id === schoolId,
    ),
  )

  if (rolesContainUpdatedRole) {
    updatedRoleNodes = report?.roles?.nodes?.map((role) => {
      if (role.role.id === roleId && role.school.id === schoolId) {
        return {
          ...role,
          favorite,
          permitted,
        }
      }

      return role
    })
  }

  const newRole = {
    favorite: false,
    permitted: true,
    role: {
      id: roleId,
      name: '',
    },
    school: {
      id: schoolId,
      name: '',
      level: '',
    },
  } as ReportRoleFieldsFragment

  if (
    report?.roles?.nodes &&
    report.roles.nodes.length > 0 &&
    !rolesContainUpdatedRole
  ) {
    updatedRoleNodes = [...report.roles.nodes, newRole]
  }

  if (report?.roles?.nodes?.length === 0) {
    updatedRoleNodes = [newRole]
  }

  if (report?.id) {
    return {
      ...report,
      roles: {
        ...report.roles,
        nodes: updatedRoleNodes,
      },
    } as ReportFieldsFragment
  }

  return report
}

export function getUpdatedReportsData(
  input: ReportRoleInput,
  originalData?: ReportRolesBySchoolQuery,
): ReportRolesBySchoolQuery | undefined {
  const { reportId } = input
  const originalReport = originalData?.reports?.nodes?.find(
    (report) => report.id === reportId,
  )

  if (!originalReport || !originalData?.reports) {
    return
  }

  const updatedReport = getUpdatedReport(input, originalReport)

  const updatedReportNodes = originalData.reports.nodes?.map((report) =>
    report.id === updatedReport?.id ? updatedReport : report,
  )

  const updatedReports = {
    ...originalData,
    reports: {
      ...originalData.reports,
      nodes: updatedReportNodes,
    },
  }

  return updatedReports
}

export function useUpdateReportRoleMutation({
  endpoint,
  queryInput,
}: {
  endpoint: string
  queryInput: ReportRolesBySchoolQueryVariables
}): ReturnType<
  typeof gqlUseUpdateReportRoleMutation<
    unknown,
    {
      originalReports: ReportRolesBySchoolQuery | undefined
    }
  >
> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()
  const updateReports = gqlUseUpdateReportRoleMutation<
    unknown,
    {
      originalReports: ReportRolesBySchoolQuery | undefined
    }
  >(client, {
    onMutate: async () => {
      await queryClient.cancelQueries(['ReportRolesBySchool', queryInput])

      const reportsQueryData =
        queryClient.getQueryData<ReportRolesBySchoolQuery>([
          'ReportRolesBySchool',
          queryInput,
        ])

      return {
        originalReports: reportsQueryData,
      }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        ['ReportRolesBySchool', queryInput],
        context?.originalReports,
      )
    },
  })

  return updateReports
}
