import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import type { UserQuery } from '@graphql-hooks'
import {
  useUnlinkEmployeeFromUserMutation as gqlUseUnlinkEmployeeFromUserMutation,
  UserStatus,
} from '@graphql-hooks'

export function useUnlinkEmployeeFromUserMutation({
  endpoint,
}: {
  endpoint: string
}): ReturnType<
  typeof gqlUseUnlinkEmployeeFromUserMutation<
    unknown,
    {
      originalUser: UserQuery['user'] | undefined
      updatedUser: UserQuery['user'] | undefined
    }
  >
> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()
  const updateUser = gqlUseUnlinkEmployeeFromUserMutation<
    unknown,
    {
      originalUser: UserQuery['user'] | undefined
      updatedUser: UserQuery['user'] | undefined
    }
  >(client, {
    onMutate: async ({ userId }) => {
      await queryClient.cancelQueries([
        'user',
        { id: userId, statuses: [UserStatus.Active, UserStatus.Inactive] },
      ])

      const getUpdatedUser = (
        user: UserQuery['user'] | undefined,
      ): UserQuery['user'] => {
        if (!user) {
          return
        }

        // return everything but the linkedEmployee property, since we're removing it
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars -- we're removing the linkedEmployee property
        const { linkedEmployee, ...updatedUser } = user

        return updatedUser
      }

      const userQueryData = queryClient.getQueryData<UserQuery>([
        'user',
        { id: userId, statuses: [UserStatus.Active, UserStatus.Inactive] },
      ])
      const updatedUser = getUpdatedUser(userQueryData?.user)

      queryClient.setQueryData<UserQuery>(
        [
          'user',
          { id: userId, statuses: [UserStatus.Active, UserStatus.Inactive] },
        ],
        { user: updatedUser },
      )

      return { originalUser: userQueryData?.user, updatedUser }
    },
    onError: (_, { userId }, context) => {
      queryClient.setQueryData(
        [
          'user',
          { id: userId, statuses: [UserStatus.Active, UserStatus.Inactive] },
        ],
        context?.originalUser,
      )
    },
    onSettled: async (updatedUser) => {
      await queryClient.invalidateQueries([
        'user',
        {
          id: updatedUser?.unlinkEmployeeFromUser.id,
          statuses: [UserStatus.Active, UserStatus.Inactive],
        },
      ])
    },
  })

  return updateUser
}
