'use client'

import type { RefObject } from 'react'
import { useRef } from 'react'

/**
 * useFocus hook
 * Exposes a ref that can be set to an HTML element, and a 'setFocus' method to focus
 * or unfocus that element
 */

export function useFocus<T extends HTMLElement>(): [
  RefObject<T>,
  (focus: boolean) => void,
] {
  const ref = useRef<T>(null)
  const setFocus = (focus: boolean): void => {
    if (ref.current) {
      focus ? ref.current.focus() : ref.current.blur()
    }
  }

  return [ref, setFocus]
}
