/* eslint-disable tsdoc/syntax -- Examples can contain code */
'use client'

import type { ReactNode } from 'react'
import {
  useCallback,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

/**
 * The `Breakpoint` enum defines a maximum screen witdh.
 *
 * For example, anything less than 640px wide would be a `SM` breakpoint.
 * Anything between 641-768px would be a `MD` breakpoint, and so on.
 */
export enum Breakpoint {
  'SM' = 640,
  'MD' = 768,
  'LG' = 1024,
  'XL' = 1280,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- 2XL is a valid name
  '2XL' = 1536,
  'NONE' = -1,
}

const BreakpointContext = createContext<Breakpoint | null>(null)

/**
 * `getDeviceBreakpoint` converts a numeric pixel width to a `Breakpoint` enum.
 *
 * `Breakpoint` is can be imported from `@hooks`.
 */
const getDeviceBreakpoint = (width: number): Breakpoint | null => {
  if (width < 640) {
    return Breakpoint.SM
  } else if (width < 768) {
    return Breakpoint.MD
  } else if (width < 1024) {
    return Breakpoint.LG
  } else if (width < 1280) {
    return Breakpoint.XL
  } else if (width < 1536) {
    return Breakpoint['2XL']
  }
  return Breakpoint.NONE
}

/**
 * `BreakpointProvider` is a [Context.Provider](https://reactjs.org/docs/context.html#contextprovider) component.
 *
 * Any content that needs breakpoint context must be wrapped in a `<BreakpointContext>` tag.
 * This allows the content to subscribe to the context changes defined within the `BreakpointProvider`
 */
function BreakpointProvider({
  children,
}: {
  children?: ReactNode | undefined
}): React.ReactElement {
  const [breakpoint, setBreakpoint] = useState<Breakpoint | null>(null)
  const setCurrentBreakpoint = useCallback(
    (currentBreakpoint: Breakpoint | null) => {
      if (breakpoint !== currentBreakpoint) {
        setBreakpoint(currentBreakpoint)
      }
    },
    [breakpoint],
  )

  useEffect(() => {
    const initialBreakpoint = getDeviceBreakpoint(window.innerWidth)
    setCurrentBreakpoint(initialBreakpoint)

    const getInnerWidth = (): void => {
      const newBreakpoint = getDeviceBreakpoint(window.innerWidth)
      setCurrentBreakpoint(newBreakpoint)
    }

    window.addEventListener('resize', getInnerWidth)

    return () => {
      window.removeEventListener('resize', getInnerWidth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
  }, [])

  return (
    <BreakpointContext.Provider value={breakpoint}>
      {children}
    </BreakpointContext.Provider>
  )
}

/**
 * `useBreakpoint` exposes a hook to get the current screen breakpoint.
 * It returns a `Breakpoint` enum value
 * The value updates whenever the defined breakpoint limit changes.
 *
 * @example
 * import { useBreakpoint } from '@hooks'
 *
 * const breakpoint = useBreakpoint()
 */
function useBreakpoint(): Breakpoint {
  const context = useContext(BreakpointContext)

  if (context === null) {
    throw new Error('useBreakpoint must be used within a BreakpointProvider')
  }
  return context
}

export { BreakpointProvider, useBreakpoint }
