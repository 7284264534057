import { GraphQLClient } from 'graphql-request'
import { useRolesQuery as gqlUseRolesQuery } from '@graphql-hooks'

export function useRolesQuery({ endpoint }: { endpoint: string }) {
  const client = new GraphQLClient(endpoint, { headers: {} })

  const useQueryResult = gqlUseRolesQuery(client)

  return useQueryResult
}
