/* eslint-disable tsdoc/syntax -- Examples can contain code */
'use client'

import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { AcademicCapIcon, UsersIcon } from '@heroicons/react/24/solid'

// type definitions
type DeepPartial<T> = Partial<{ [P in keyof T]: DeepPartial<T[P]> }>

export interface Category {
  items: (CategoryItem | CategoryGroupItem)[]
  name: string
  pathname: string
  selected: boolean
}

export interface CategoryLink {
  href: string
  name: string
  selected: boolean
}

export enum CategoryItemType {
  LINK = 'LINK',
  GROUP = 'GROUP',
}

export interface CategoryItem {
  type: CategoryItemType.LINK
  href: string
  name: string
  selected: boolean
}

export interface CategoryGroupItem {
  type: CategoryItemType.GROUP
  icon: JSX.Element
  links: CategoryLink[]
  name: string
  selected: boolean
  slug: string
  toggleOpen: () => void
}

type ICategory = DeepPartial<Category>

/**
 * `useCategory` exposes information about application categories and sections.
 *
 * The initial selected category is determined from the category's `pathname`.
 * For a non-group item (the item doesn't contain a)
 *
 * @example
 * import { useCategory } from '@hooks/useCategory'
 *
 * const { categories, selectedCategory, openGroup, toggleOpenGroup } = useCategory()
 */
export function useCategory(): {
  categories: Category[]
  selectedCategory: Category
  openGroup: string
} {
  const router = useRouter()
  const route = router.pathname

  // define different sections (categories) of the application
  const categories: ICategory[] = [
    {
      name: 'Configuration',
      pathname: '/configuration',
      items: [
        {
          type: CategoryItemType.GROUP,
          name: 'Schools',
          icon: <AcademicCapIcon className="h-6 w-6" />,
          links: [
            {
              href: '/configuration/terms',
              name: 'Configure Terms',
            },
          ],
          slug: 'configuration-schools',
        },
        {
          type: CategoryItemType.GROUP,
          name: 'Users',
          icon: <UsersIcon className="h-6 w-6" />,
          links: [
            {
              href: '/configuration/users',
              name: 'Users',
            },
            {
              href: '/configuration/roles',
              name: 'Roles',
            },
          ],
          slug: 'configuration-user-management',
        },
      ],
    },
    {
      name: 'Dashboards',
      pathname: '/dashboards/overview',
      items: [
        {
          type: CategoryItemType.LINK,
          name: 'Overview',
          href: '/dashboards/overview',
        },
        {
          type: CategoryItemType.LINK,
          name: 'State Accountability',
          href: '/dashboards/state-accountability',
        },
      ],
    },
  ]

  function getInitialOpenCategoryItem(): string {
    const categoryName = route.split('/')[1]?.toLowerCase() || ''
    const selectedCategory =
      categories.find(
        (category) =>
          category.name?.toLowerCase() === categoryName.toLowerCase(),
      ) || categories[0]
    const items = selectedCategory.items?.filter(
      (item) => item && item.type === CategoryItemType.GROUP,
    ) as CategoryGroupItem[]

    const initialOpenTab =
      items.find((item) =>
        item.links.some((link) => route.includes(link.href || '')),
      )?.slug || ''

    return initialOpenTab
  }

  const [openGroup, setOpenGroup] = useState<string>(
    getInitialOpenCategoryItem(),
  )

  // add additional data such as initially selected `Category` and `CategoryItem` elements, and toggle functions
  const updatedCategories: Category[] = categories.map((category) => {
    const { name, pathname } = category
    const isCategorySelected =
      category.name?.toLowerCase() === route.split('/')[1]?.toLowerCase() ||
      false

    const updatedCategoryItems: (CategoryItem | CategoryGroupItem)[] =
      category.items && category.items.length > 0
        ? category.items
            .map((item) => {
              if (item?.type && item.type === CategoryItemType.GROUP) {
                const isItemInGroupSelected =
                  item.links &&
                  item.links.length > 0 &&
                  item.links.some((link) => route.includes(link?.href || ''))

                const toggleFn = (): void => {
                  if (item.slug) {
                    openGroup === item.slug
                      ? setOpenGroup('')
                      : setOpenGroup(item.slug)
                  }
                }

                const { type, icon, links, name: itemName, slug } = item

                const updatedLinks = links?.map((link) => ({
                  ...link,
                  selected: route.includes(link?.href || ''),
                }))

                return {
                  icon,
                  links: updatedLinks,
                  name: itemName,
                  selected: isItemInGroupSelected,
                  slug,
                  toggleOpen: toggleFn,
                  type,
                } as CategoryGroupItem
              } else if (item?.type && item.type === CategoryItemType.LINK) {
                const isItemSelected = item.href === route

                const { type, href, name: itemName } = item

                return {
                  href,
                  name: itemName,
                  selected: isItemSelected,
                  type,
                } as CategoryItem
              }
              return undefined
            })
            .filter((item): item is CategoryItem | CategoryGroupItem => {
              return item !== undefined
            })
        : []

    return {
      items: updatedCategoryItems,
      name: name || '',
      pathname: pathname || '',
      selected: isCategorySelected,
    }
  })

  return useMemo(() => {
    const categoryName = route.split('/')[1]?.toLowerCase() || ''

    const selectedCategory =
      updatedCategories.find(
        (category) =>
          category.name.toLowerCase() === categoryName.toLowerCase(),
      ) || updatedCategories[0]

    return {
      categories: updatedCategories,
      selectedCategory,
      openGroup,
    }
  }, [updatedCategories, openGroup, route])
}
