import { GraphQLClient } from 'graphql-request'
import { usePermissionsQuery as gqlUsePermissionsQuery } from '@graphql-hooks'

export function usePermissionsQuery({ endpoint }: { endpoint: string }) {
  const client = new GraphQLClient(endpoint, { headers: {} })

  const useQueryResult = gqlUsePermissionsQuery(client)

  return useQueryResult
}
