import { GraphQLClient } from 'graphql-request'
import type { Node } from '@graphql'
import { UserStatus } from '@graphql'
import { useUserQuery as gqlUseUserQuery } from '@graphql-hooks'

export function useUserQuery({
  endpoint,
  id,
}: {
  endpoint: string
  id: Node['id']
}) {
  const client = new GraphQLClient(endpoint, { headers: {} })

  const useQueryResult = gqlUseUserQuery(
    client,
    {
      id,
      statuses: [UserStatus.Active, UserStatus.Inactive],
    },
    { keepPreviousData: true },
  )

  return useQueryResult
}
