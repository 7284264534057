import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import _ from 'lodash'
import type { ConfigurationQuery } from '@graphql-hooks'
import { useUpdateConfigurationSetMutation as gqlUseUpdateConfigurationSetMutation } from '@graphql-hooks'

type ConfigurationQueryObject = ConfigurationQuery['configuration']

export function useUpdateConfigurationSetMutation({
  endpoint,
}: {
  endpoint: string
}): ReturnType<
  typeof gqlUseUpdateConfigurationSetMutation<
    unknown,
    {
      originalConfiguration: ConfigurationQueryObject[] | undefined
      updatedConfiguration: ConfigurationQueryObject[] | undefined
    }
  >
> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()
  const updateConfiguration = gqlUseUpdateConfigurationSetMutation<
    unknown,
    {
      originalConfiguration: ConfigurationQueryObject[] | undefined
      updatedConfiguration: ConfigurationQueryObject[] | undefined
    }
  >(client, {
    onMutate: async ({ input }) => {
      const mutateInput = !_.isArray(input) ? [input] : input

      await Promise.all(
        mutateInput.map((input) =>
          queryClient.cancelQueries(['configuration', { key: input.key }]),
        ),
      )
      await queryClient.cancelQueries(['Configurations'])

      const getUpdatedConfiguration = (
        configuration: ConfigurationQueryObject[] | undefined,
      ): ConfigurationQueryObject[] => {
        const trimmedUpdateValues: Partial<ConfigurationQueryObject>[] =
          JSON.parse(
            JSON.stringify(mutateInput),
          ) as Partial<ConfigurationQueryObject>[]

        const updatedConfiguration = trimmedUpdateValues
          .map((trimmedUpdateValues) => {
            const config = configuration?.find(
              (config) => config?.key === trimmedUpdateValues?.key,
            )

            if (!trimmedUpdateValues || !config) {
              return undefined
            }
            return {
              id: trimmedUpdateValues.id ? trimmedUpdateValues.id : config.id,
              key: trimmedUpdateValues.key
                ? trimmedUpdateValues.key
                : config.key,
              value: trimmedUpdateValues.value
                ? trimmedUpdateValues.value
                : config.value,
              displayName: trimmedUpdateValues.displayName
                ? trimmedUpdateValues.displayName
                : config.displayName,
            } as ConfigurationQueryObject
          })
          .filter(Boolean)

        return updatedConfiguration
      }

      const configurationQueryData = await Promise.all(
        mutateInput.map((input) =>
          queryClient.getQueryData<ConfigurationQuery>([
            'configuration',
            { key: input.key },
          ]),
        ),
      )
      configurationQueryData.filter((value) => value)
      const updatedConfiguration = getUpdatedConfiguration(
        configurationQueryData.filter(
          (value) => value,
        ) as ConfigurationQueryObject[],
      )
      await Promise.all(
        updatedConfiguration.map(
          (input) =>
            input &&
            queryClient.setQueryData<ConfigurationQuery>(
              ['configuration', { key: input.key }],
              {
                configuration: input,
              },
            ),
        ),
      )
      return {
        originalConfiguration: configurationQueryData.map(
          (config) => config?.configuration,
        ),
        updatedConfiguration,
      }
    },
    onError: async (__, { input }, context) => {
      const mutateInput = !_.isArray(input) ? [input] : input
      await Promise.all(
        mutateInput.map((input) =>
          queryClient.setQueryData(
            ['configuration', { key: input.key }],
            context?.originalConfiguration,
          ),
        ),
      )
    },
    onSettled: async (updatedConfiguration) => {
      if (updatedConfiguration)
        await Promise.all(
          updatedConfiguration.updateConfigurationSet.map((input) =>
            queryClient.invalidateQueries([
              'configuration',
              { key: input.key },
            ]),
          ),
        )
      await queryClient.invalidateQueries(['Configurations'])
    },
  })

  return updateConfiguration
}
