/* eslint-disable tsdoc/syntax -- Examples can contain code  */
'use client'

import { useCallback, useEffect, useMemo, useRef } from 'react'

interface Options {
  /**
   * Enable the useKey event listeners
   */
  enabled?: boolean
  /**
   * Keyboardevent types to listen for. Any of: keyDown, keyPress, keyUp. Default: 'keydown'
   */
  eventTypes?: ('keydown' | 'keypress' | 'keyup')[]
}

const defaultOptions: Options = {
  enabled: true,
  eventTypes: ['keydown'],
}

/**
 * Fires a callback on keyboard events like keyDown, keyPress and keyUp
 *
 * @param key - The key to listen for
 * @param handler - A function invoked on keypress
 * @param options - Options to configure the hook
 *
 *
 *
 * @example
 *  useKey('/', () => console.log('"/" pressed!'))
 */
export function useKey(
  key: string,
  handler: (e: KeyboardEvent) => void,
  options = { ...defaultOptions },
): void {
  const { enabled, eventTypes } = options
  const handlerRef = useRef<(e: KeyboardEvent) => void>(handler)

  const pressedKey = useMemo(() => key, [key])

  // Does an event match the key we're watching?
  const doesKeyMatchEvent = (event: KeyboardEvent, k: string): boolean =>
    k.toLowerCase() === event.key.toLowerCase()

  // set the handlerRef 'current' to the handler event parameter
  useEffect(() => {
    handlerRef.current = handler
  })

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (
        doesKeyMatchEvent(e, pressedKey) &&
        (e.target as HTMLElement).tagName !== 'INPUT' &&
        (e.target as HTMLElement).tagName !== 'TEXTAREA'
      ) {
        e.preventDefault()
        handlerRef.current(e)
      }
    },
    [pressedKey],
  )

  useEffect(() => {
    if (enabled && typeof window !== 'undefined') {
      eventTypes?.forEach((eventType) => {
        window.addEventListener(eventType, handleKeyPress)
      })
      return () => {
        eventTypes?.forEach((eventType) => {
          window.removeEventListener(eventType, handleKeyPress)
        })
      }
    }
  }, [enabled, eventTypes, pressedKey, handleKeyPress])
}
