import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import _ from 'lodash'
import type {
  ReportDetailSectionsQuery,
  ReportRolesBySchoolQuery,
  ReportRolesBySchoolQueryVariables,
} from '@graphql-hooks'
import { useUpdateReportDetailSectionRoleSetMutation as gqlUseUpdateReportDetailSectionRoleSetMutation } from '@graphql-hooks'

export type QueryReportSectionSet = NonNullable<
  NonNullable<ReportRolesBySchoolQuery['reports']>['nodes']
>[number]

export function useUpdateReportDetailSectionRoleSetMutation({
  endpoint,
  queryInput,
}: {
  endpoint: string
  queryInput: ReportRolesBySchoolQueryVariables
}): ReturnType<
  typeof gqlUseUpdateReportDetailSectionRoleSetMutation<
    unknown,
    {
      originalReports: ReportDetailSectionsQuery | undefined
    }
  >
> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()
  const updateReports = gqlUseUpdateReportDetailSectionRoleSetMutation<
    unknown,
    {
      originalReports: ReportDetailSectionsQuery | undefined
    }
  >(client, {
    onMutate: async ({ input }) => {
      await queryClient.cancelQueries(['ReportDetailSections'])
      const arrayedInput = Array.isArray(input) ? input : [input]
      await Promise.all(
        _.uniqWith(
          arrayedInput,
          (a, b) => a.sectionId === b.sectionId && a.schoolId === b.schoolId,
        ).map(async (sectionRole) => {
          await queryClient.cancelQueries([
            'ReportDetailSection',
            {
              id: sectionRole.sectionId,
              schoolId: sectionRole.schoolId,
            },
          ])
        }),
      )

      const sectionsQueryData =
        queryClient.getQueryData<ReportDetailSectionsQuery>([
          'ReportDetailSections',
          queryInput,
        ])

      arrayedInput.forEach((role) => {
        if (!sectionsQueryData?.reportDetailSections.nodes) return
        const section = sectionsQueryData.reportDetailSections.nodes.find(
          (s) => s.id === role.sectionId,
        )
        //Check if updated role record is at selected school.
        const sectionRole = section?.roles?.nodes?.find(
          (sr) => sr.role.id === role.roleId && sr.school.id === role.schoolId,
        )
        //Update Selected School Role Status.
        if (sectionRole) {
          sectionRole.permitted = role.permitted
        }
        //Check if updated role record is at district.
        const districtSectionRole = section?.districtRoles?.nodes?.find(
          (sr) => sr.role.id === role.roleId && sr.school.id === role.schoolId,
        )
        //Update District Role Status.
        if (districtSectionRole) {
          districtSectionRole.permitted = role.permitted
        }
      })

      return {
        originalReports: sectionsQueryData,
      }
    },
    onSettled: async (__, ___, { input }) => {
      //Reset All ReportRolesBySchool queries.
      await queryClient.invalidateQueries(['ReportDetailSections'])
      const arrayedInput = Array.isArray(input) ? input : [input]
      //Reset All Report queries
      _.uniqWith(arrayedInput, (a, b) => a.sectionId === b.sectionId).map(
        async (sectionRole) => {
          await queryClient.invalidateQueries([
            'ReportDetailSection',
            {
              id: sectionRole.sectionId,
            },
          ])
        },
      )
    },
  })

  return updateReports
}
