import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import type { PermissionsQuery, RoleQuery } from '@graphql-hooks'
import { useUpdateRoleMutation as gqlUseUpdateRoleMutation } from '@graphql-hooks'

type RoleQueryRole = RoleQuery['role']
type RolePermissions = NonNullable<RoleQueryRole>['rolePermissions']

export function useUpdateRoleMutation({
  endpoint,
}: {
  endpoint: string
}): ReturnType<
  typeof gqlUseUpdateRoleMutation<
    unknown,
    {
      originalRole: RoleQuery['role'] | undefined
      updatedRole: RoleQuery['role'] | undefined
    }
  >
> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()
  const updateRole = gqlUseUpdateRoleMutation<
    unknown,
    {
      originalRole: RoleQuery['role'] | undefined
      updatedRole: RoleQuery['role'] | undefined
    }
  >(client, {
    onMutate: async ({ role }) => {
      if (role.id) await queryClient.cancelQueries(['role', { id: role.id }])
      await queryClient.cancelQueries(['Roles'])
      //"Eagerly" update new version of the object.
      //As in, use only cached data to return the data. Don't forcefully wait until the SQL update returns to return the data.
      const getUpdatedRolePermissionSet = (
        cachedRole: RoleQueryRole,
      ): RoleQuery['role'] => {
        const trimmedPermissionValues = cachedRole?.permissions.filter(Boolean)
        const trimmedUpdateValues: RolePermissions =
          cachedRole?.rolePermissions || []

        //Grab cached data to fill in data gaps.
        const updatedRole: RoleQuery['role'] = {
          id: cachedRole?.id ?? '',
          name: cachedRole?.name ?? '',
          permissions: trimmedPermissionValues ?? [],
          rolePermissions: trimmedUpdateValues,
        }

        return updatedRole
      }
      //Retrieve the old cached version of the role.
      const roleQueryData =
        (role.id &&
          queryClient.getQueryData<RoleQuery>([
            'rolePermissions',
            { roleId: role.id },
          ])) ||
        {}
      //Retrieve the cached version of all permissions.
      const permissionsQueryData = queryClient.getQueryData<PermissionsQuery>([
        'permissions',
      ])

      roleQueryData.role?.rolePermissions.forEach((rolePerm) => {
        if (!rolePerm.permission.name)
          rolePerm.permission.name =
            permissionsQueryData?.listOfPermissions.find(
              (perm) => perm.id === rolePerm.permission.id,
            )?.name || ''
      })
      const updatedRole = getUpdatedRolePermissionSet(roleQueryData.role)

      queryClient.setQueryData<RoleQuery>(
        [
          'rolePermissions',
          {
            roleId: role.id,
            permissions:
              role.permissions || roleQueryData.role?.rolePermissions,
          },
        ],
        { role: updatedRole },
      )

      return { originalRole: roleQueryData.role, updatedRole }
    },
    onError: (_, { role }, context) => {
      if (role.id) {
        queryClient.setQueryData(
          ['rolePermissions', { roleId: role.id }],
          context?.originalRole,
        )
      }
    },
    onSettled: async (_, __, { role }) => {
      if (role.id) {
        await queryClient.invalidateQueries(['role', { id: role.id }])
        await queryClient.invalidateQueries([
          'rolePermissions',
          { id: role.id },
        ])
      }
      await queryClient.invalidateQueries(['Roles'])
    },
  })

  return updateRole
}
