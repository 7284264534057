'use client'

import { useEffect, useState } from 'react'

export type ColorScheme = 'light' | 'dark'

export function usePrefersColorScheme(): 'light' | 'dark' {
  const [preferredColorScheme, setPreferredColorScheme] = useState<
    'light' | 'dark'
  >('light')

  useEffect(() => {
    const query = window.matchMedia('(prefers-color-scheme: dark)')

    setPreferredColorScheme(query.matches ? 'dark' : 'light')

    const onQueryChange = (event: MediaQueryListEvent): void => {
      setPreferredColorScheme(event.matches ? 'dark' : 'light')
    }

    query.addEventListener('change', onQueryChange)

    return () => {
      query.removeEventListener('change', onQueryChange)
    }
  }, [])

  return preferredColorScheme
}
