/* eslint-disable tsdoc/syntax -- Code is fine in tsdoc */
import type { EffectCallback } from 'react'
import { useEffect, useRef } from 'react'

/**
 * `useEffectOnce` runs an effect only once.
 * It runs like a normal `useEffect` hook, but only on the first render.
 * When in development, it has additional logic to prevent running the effect twice when in react strict mode
 *
 * @example
 * import { useEffectOnce } from '@hooks'
 *
 * useEffectOnce(() => { // This effect will only run once })
 */
export function useEffectOnce(effect: EffectCallback): void {
  const initialized = useRef(false)

  useEffect(() => {
    if (!initialized.current && process.env.NODE_ENV === 'development') {
      initialized.current = true
      effect()
    } else {
      effect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only run once
  }, [])
}
