import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import type { UserQuery } from '@graphql-hooks'
import {
  useDeleteUserRoleMutation as gqlUseDeleteUserRoleMutation,
  UserStatus,
} from '@graphql-hooks'

export function useDeleteUserRoleMutation({
  endpoint,
}: {
  endpoint: string
}): ReturnType<
  typeof gqlUseDeleteUserRoleMutation<
    unknown,
    { originalUser: UserQuery['user'] | undefined }
  >
> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()

  const deleteUserRole = gqlUseDeleteUserRoleMutation<
    unknown,
    {
      originalUser: UserQuery['user'] | undefined
      updatedUser: UserQuery['user'] | undefined
    }
  >(client, {
    onMutate: ({ input }) => {
      const getUpdatedUser = (
        user: UserQuery['user'] | undefined,
      ): UserQuery['user'] => {
        if (!user) {
          return
        }

        return {
          ...user,
          roles: {
            ...user.roles,
            edges: user.roles?.edges?.filter(
              (edge) =>
                !(
                  edge.node?.role.id === input.input.roleId &&
                  edge.node.school.id === input.input.schoolId
                ),
            ),
          },
        }
      }

      const userQueryData = queryClient.getQueryData<UserQuery>([
        'user',
        {
          id: input.userId,
          statuses: [UserStatus.Active, UserStatus.Inactive],
        },
      ])
      const updatedUser = getUpdatedUser(userQueryData?.user)

      queryClient.setQueryData<UserQuery>(
        [
          'user',
          {
            id: input.userId,
            statuses: [UserStatus.Active, UserStatus.Inactive],
          },
        ],
        { user: updatedUser },
      )

      return { originalUser: userQueryData?.user, updatedUser }
    },
    onError: (_, { input }, context) => {
      queryClient.setQueryData(
        [
          'user',
          {
            id: input.userId,
            statuses: [UserStatus.Active, UserStatus.Inactive],
          },
        ],
        context?.originalUser,
      )
    },
    onSettled: async (_, __, { input }) => {
      await queryClient.invalidateQueries([
        'user',
        {
          id: input.userId,
          statuses: [UserStatus.Active, UserStatus.Inactive],
        },
      ])
    },
  })

  return deleteUserRole
}
