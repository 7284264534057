import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import type { RoleQuery } from '@graphql-hooks'
import { useDuplicateRoleMutation as gqlUseDuplicateRoleMutation } from '@graphql-hooks'

export function useDuplicateRoleMutation({ endpoint }: { endpoint: string }) {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()
  const updateRole = gqlUseDuplicateRoleMutation<
    unknown,
    {
      newRole: RoleQuery['role'] | undefined
    }
  >(client, {
    // onMutate: async ({ sourceRoleId, targetRoleName }) => {
    //   await queryClient.cancelQueries(['Roles'])

    //   //Retrieve the cached version of all permissions.
    //   const roleQueryData = queryClient.getQueryData<RoleQuery>([
    //     'role',
    //     {
    //       id: sourceRoleId,
    //     },
    //   ])

    //   const newRole = {
    //     id: '-1', //Use dummy role id, I guess.
    //     name: targetRoleName || '',
    //     rolePermissions: roleQueryData?.role?.rolePermissions || [],
    //   }

    //   return { newRole }
    // },
    onError: (_, __, ___) => undefined,
    onSettled: (_, __, ___) => {
      queryClient.invalidateQueries(['roles'])
    },
  })

  return updateRole
}
