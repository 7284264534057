import type { UseMutationResult } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import type { Node } from '@graphql'
import type {
  Exact,
  LinkUserToEmployeeMutation,
  UserQuery,
} from '@graphql-hooks'
import {
  useLinkUserToEmployeeMutation as gqlUseLinkUserToEmployeeMutation,
  UserStatus,
} from '@graphql-hooks'

export function useLinkUserToEmployeeMutation({
  endpoint,
}: {
  endpoint: string
}): UseMutationResult<
  LinkUserToEmployeeMutation,
  unknown,
  Exact<{
    userId: Node['id']
    employeeCode: string
  }>
> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()

  const updateUser = gqlUseLinkUserToEmployeeMutation(client, {
    onSuccess: async (data, { userId }) => {
      const updatedUser = data.linkUserToEmployee

      await queryClient.invalidateQueries([
        'user',
        {
          id: userId,
          statuses: [UserStatus.Active, UserStatus.Inactive],
        },
      ])

      queryClient.setQueryData<UserQuery>(
        [
          'user',
          { id: userId, statuses: [UserStatus.Active, UserStatus.Inactive] },
        ],
        {
          user: updatedUser,
        },
      )
    },
  })

  return updateUser
}
