import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import _ from 'lodash'
import type { SchoolConfigurationQuery, SchoolsQuery } from '@graphql-hooks'
import { useUpdateSchoolConfigurationSetMutation as gqlUseUpdateSchoolConfigurationSetMutation } from '@graphql-hooks'

type SchoolConfigurationQueryObject =
  SchoolConfigurationQuery['schoolConfiguration']

export function useUpdateSchoolConfigurationSetMutation({
  endpoint,
}: {
  endpoint: string
}): ReturnType<
  typeof gqlUseUpdateSchoolConfigurationSetMutation<
    unknown,
    {
      originalSchoolConfiguration: SchoolConfigurationQueryObject[] | undefined
      updatedSchoolConfiguration: SchoolConfigurationQueryObject[] | undefined
    }
  >
> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()
  const updateSchoolConfiguration = gqlUseUpdateSchoolConfigurationSetMutation<
    unknown,
    {
      originalSchoolConfiguration: SchoolConfigurationQueryObject[] | undefined
      updatedSchoolConfiguration: SchoolConfigurationQueryObject[] | undefined
    }
  >(client, {
    onMutate: async ({ input }) => {
      const mutateInput = !_.isArray(input) ? [input] : input
      const schoolIds = [
        ...new Set(mutateInput.map((config) => config.schoolId)),
      ]
      await Promise.all(
        mutateInput.map((input) =>
          queryClient.cancelQueries([
            'schoolConfiguration',
            { key: input.key },
          ]),
        ),
      )
      await Promise.all(
        schoolIds.map((schoolId) =>
          queryClient.invalidateQueries(['SchoolConfigurations', { schoolId }]),
        ),
      )

      const schoolList =
        queryClient.getQueryData<SchoolsQuery>(['schools'])?.schools || []

      const getUpdatedConfiguration = (
        schoolConfiguration: SchoolConfigurationQueryObject[] | undefined,
      ): SchoolConfigurationQueryObject[] => {
        const trimmedUpdateValues: SchoolConfigurationQueryObject[] =
          JSON.parse(
            JSON.stringify(mutateInput),
          ) as SchoolConfigurationQueryObject[]

        const updatedConfiguration: SchoolConfigurationQueryObject[] =
          trimmedUpdateValues.map((trimmedUpdateValues) => {
            const config = schoolConfiguration?.find(
              (config) => config?.key === trimmedUpdateValues?.key,
            )
            const school = schoolList.find(
              (school) =>
                school.id === trimmedUpdateValues?.school.id ||
                config?.school.id,
            )

            if (!trimmedUpdateValues || !config || !school) {
              return undefined
            }
            return {
              id: trimmedUpdateValues.id || config.id,
              key: trimmedUpdateValues.key || config.key,
              value: trimmedUpdateValues.value || config.value,
              displayName:
                trimmedUpdateValues.displayName || config.displayName,
              school,
            }
          })

        return updatedConfiguration
      }

      const schoolConfigurationQueryData = mutateInput.map((input) =>
        queryClient.getQueryData<SchoolConfigurationQuery>([
          'schoolConfiguration',
          { key: input.key },
        ]),
      )

      schoolConfigurationQueryData.filter((value) => value)
      const updatedSchoolConfiguration = getUpdatedConfiguration(
        schoolConfigurationQueryData.filter(
          (value) => value,
        ) as SchoolConfigurationQueryObject[],
      )
      await Promise.all(
        updatedSchoolConfiguration.map(
          (input) =>
            input &&
            queryClient.setQueryData<SchoolConfigurationQuery>(
              ['cschoolConfiguration', { key: input.key }],
              {
                schoolConfiguration: input,
              },
            ),
        ),
      )
      return {
        originalSchoolConfiguration: schoolConfigurationQueryData.map(
          (config) => config?.schoolConfiguration,
        ),
        updatedSchoolConfiguration,
      }
    },
    onError: async (__, { input }, context) => {
      const mutateInput = !_.isArray(input) ? [input] : input
      await Promise.all(
        mutateInput.map((input) =>
          queryClient.setQueryData(
            [
              'schoolConfiguration',
              { key: input.key, schoolId: input.schoolId },
            ],
            context?.originalSchoolConfiguration,
          ),
        ),
      )
    },
    onSettled: async (updatedConfiguration) => {
      const schoolIds = [
        ...new Set(
          updatedConfiguration?.updateSchoolConfigurationSet.map(
            (config) => config.school.id,
          ),
        ),
      ]
      if (updatedConfiguration)
        await Promise.all(
          updatedConfiguration.updateSchoolConfigurationSet.map((input) =>
            queryClient.invalidateQueries([
              'schoolConfiguration',
              { key: input.key, schoolId: input.school.id },
            ]),
          ),
        )
      await Promise.all(
        schoolIds.map((schoolId) =>
          queryClient.invalidateQueries(['SchoolConfigurations', { schoolId }]),
        ),
      )
    },
  })

  return updateSchoolConfiguration
}
