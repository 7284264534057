import { GraphQLClient } from 'graphql-request'
import type { UsersQueryVariables } from '@graphql-hooks'
import { useUsersQuery as gqlUseUsersQuery } from '@graphql-hooks'

export function useUsersQuery({
  endpoint,
  variables,
}: {
  endpoint: string
  variables: UsersQueryVariables
}) {
  const client = new GraphQLClient(endpoint, { headers: {} })

  const useQueryResult = gqlUseUsersQuery(client, variables, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  return useQueryResult
}
