import { GraphQLClient } from 'graphql-request'
import { useServerStatusQuery as gqlUseServerStatusQuery } from '@graphql-hooks'

export function useServerStatusQuery({ endpoint }: { endpoint: string }) {
  const client = new GraphQLClient(endpoint, { headers: {} })

  const useQueryResult = gqlUseServerStatusQuery(client, undefined, {
    keepPreviousData: true,
    retry: false,
  })

  return useQueryResult
}
