import { GraphQLClient } from 'graphql-request'
import type { ConfigurationsQueryVariables } from '@graphql-hooks'
import { useConfigurationsQuery as gqlUseConfigurationsQuery } from '@graphql-hooks'

export function useConfigurationsQuery({
  endpoint,
  variables,
}: {
  endpoint: string
  variables: ConfigurationsQueryVariables
}) {
  const client = new GraphQLClient(endpoint, { headers: {} })

  const useQueryResult = gqlUseConfigurationsQuery(client, variables)

  return useQueryResult
}
