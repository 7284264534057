import { GraphQLClient } from 'graphql-request'
import { useSchoolsQuery as gqlUseSchoolsQuery } from '@graphql-hooks'

export function useSchoolsQuery({ endpoint }: { endpoint: string }) {
  const client = new GraphQLClient(endpoint, { headers: {} })

  const useQueryResult = gqlUseSchoolsQuery(client)

  return useQueryResult
}
