import { GraphQLClient } from 'graphql-request'
import type { SchoolConfigurationsQueryVariables } from '@graphql-hooks'
import { useSchoolConfigurationsQuery as gqlUseSchoolConfigurationsQuery } from '@graphql-hooks'

export function useSchoolConfigurationsQuery({
  endpoint,
  variables,
}: {
  endpoint: string
  variables: SchoolConfigurationsQueryVariables
}) {
  const client = new GraphQLClient(endpoint, { headers: {} })

  const useQueryResult = gqlUseSchoolConfigurationsQuery(client, variables)

  return useQueryResult
}
