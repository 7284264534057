'use client'

import { useState } from 'react'

/**
 * Saves a value to local storage
 *
 * @param key - The key to access the value in local storage
 * @param defaultValue - The initial value to set
 *
 *
 *
 * @example
 *  const [username, setUsername] = useLocalStorage<string>('username', 'bob_johnson')
 */
export function useLocalStorage<T>(
  key: string,
  defaultValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return defaultValue
    }
    try {
      const item = window.localStorage.getItem(key)

      const calculatedItem = (item ? JSON.parse(item) : defaultValue) as T

      if (!item) {
        window.localStorage.setItem(key, JSON.stringify(defaultValue))
      }

      return calculatedItem ?? defaultValue
    } catch (error) {
      return defaultValue
    }
  })

  const setter: React.Dispatch<React.SetStateAction<T>> = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value

      setStoredValue(valueToStore)

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      /* Do nothing on error */
    }
  }

  return [storedValue, setter]
}
