import type { Node } from '@graphql'

export interface Edge<T extends Node> {
  cursor: string
  node: T
}

export function nodeIdToCursor(nodeId: string): string {
  return Buffer.from(nodeId).toString('base64')
}

export function cursorToNodeId(cursor: string): string {
  return Buffer.from(cursor, 'base64').toString('ascii')
}

export function calculateLimit(
  after?: string,
  before?: string,
  first?: number,
  last?: number,
): number | undefined {
  let limit

  if (first) {
    limit = first
  }

  if (last) {
    limit = last
  }

  return limit
}

export function calculateOffset(
  after?: string,
  before?: string,
  first?: number,
  last?: number,
  totalCount?: number,
  // getTotal?: () => Promise<number>,
): number {
  let offset = 0

  if (after) {
    const afterOffset = cursorToNodeId(after)
    offset = Number(afterOffset) + 1
  }

  if (before) {
    const beforeOffset = cursorToNodeId(before)
    const calculatedOffset = last
      ? Number(beforeOffset) - last
      : Number(beforeOffset)
    offset = calculatedOffset > 0 ? calculatedOffset : 0
  }

  if (!after && !before && !first && last && totalCount) {
    offset = totalCount - Number(last)
  }

  return offset
}

export function nodesToEdges<T extends Node>(nodes: T[]): Edge<T>[] {
  const edges = nodes.map((node) => {
    return {
      cursor: nodeIdToCursor(node.id),
      node,
    }
  })

  return edges
}

export function nodesToEdgesWithOffset<T extends Node>(
  nodes: T[],
  startingOffset: number,
): Edge<T>[] {
  const edges = nodes.map((node, idx) => {
    const cursor = nodeIdToCursor((startingOffset + idx).toString())

    return {
      cursor,
      node,
    }
  })

  return edges
}

function applyCursorsToEdges<T extends Node>(
  allEdges: Edge<T>[],
  before?: string,
  after?: string,
): Edge<T>[] {
  let edges = [...allEdges]

  if (after) {
    const afterIndex = edges.map((edge) => edge.cursor).indexOf(after)

    if (afterIndex !== -1) {
      edges = edges.slice(afterIndex + 1)
    }
  }

  if (before) {
    const beforeIndex = edges.map((edge) => edge.cursor).indexOf(before)

    if (beforeIndex !== -1) {
      edges = edges.slice(0, beforeIndex)
    }
  }

  return edges
}

export function getEdges<T extends Node>(
  edges: Edge<T>[],
  before?: string,
  after?: string,
  first?: number,
  last?: number,
): Edge<T>[] {
  const cursorFilteredEdges = applyCursorsToEdges(edges, after, before)
  let edgesToReturn = [...cursorFilteredEdges]
  if (first && first >= 0 && edgesToReturn.length > first) {
    edgesToReturn = edgesToReturn.slice(0, first)
  }
  if (last && last >= 0 && edgesToReturn.length > last) {
    edgesToReturn = edgesToReturn.slice(-last)
  }

  return edgesToReturn
}

export function getEdgesFromNodes<T extends Node>(
  nodes: T[],
  after?: string,
  before?: string,
  first?: number,
  last?: number,
): Edge<T>[] {
  const edges = nodesToEdges(nodes)
  const filteredEdges = getEdges(edges, after, before, first, last)

  return filteredEdges
}

export function getEdgesFromNodesWithOffset<T extends Node>(
  nodes: T[],
  after?: string,
  before?: string,
  first?: number,
  last?: number,
): Edge<T>[] {
  const offset = calculateOffset(after, before, first, last, nodes.length)

  const edges = nodesToEdgesWithOffset(nodes, offset)

  return edges
}

export function hasPreviousPage<T extends Node>(
  edges: Edge<T>[],
  after?: string,
  before?: string,
  first?: number,
  last?: number,
): boolean {
  if (edges.length === 0) {
    return false
  }

  const cursors = edges.map((edge) => edge.cursor)

  if (last) {
    const cursorFilteredEdges = applyCursorsToEdges(edges, after, before)
    if (cursorFilteredEdges.length > last) {
      return true
    }
    return false
  }

  if (after) {
    if (cursors.indexOf(after) > 0) {
      return true
    }
  }

  return false
}

export function hasPreviousPageOffset<T extends Node>(
  edges: Edge<T>[],
  totalCount: number,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
): boolean {
  if (edges.length === 0) {
    return false
  }

  if (last && !after && !before && !first) {
    if (totalCount > last) {
      return true
    }
  }

  const offset = calculateOffset(after, before, first, last, totalCount)

  if (last ?? after) {
    if (offset > 0) {
      return true
    }
    return false
  }

  return false
}

export function hasNextPage<T extends Node>(
  edges: Edge<T>[],
  after?: string,
  before?: string,
  first?: number,
): boolean {
  if (edges.length === 0) {
    return false
  }

  const cursors = edges.map((edge) => edge.cursor)

  if (first) {
    const cursorFilteredEdges = applyCursorsToEdges(edges, after, before)
    if (cursorFilteredEdges.length > first) {
      return true
    }
    return false
  }

  if (before) {
    if (cursors.indexOf(before) < cursors.length - 1) {
      return true
    }
  }

  return false
}

export function hasNextPageOffset<T extends Node>(
  edges: Edge<T>[],
  totalCount: number,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
): boolean {
  const offset = calculateOffset(after, before, first, last, totalCount)
  const limit = calculateLimit(after, before, first, last)

  if (edges.length === 0) {
    return false
  }

  if (first ?? before) {
    const max = limit ? offset + limit : offset
    if (max < totalCount) {
      return true
    }
    return false
  }

  return false
}
