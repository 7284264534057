import { GraphQLClient } from 'graphql-request'
import { useAdminDashboardQuery as gqlUseAdminDashboardQuery } from '@graphql-hooks'

export function useAdminDashboardQuery({ endpoint }: { endpoint: string }) {
  const client = new GraphQLClient(endpoint, { headers: {} })

  const useQueryResult = gqlUseAdminDashboardQuery(client)

  return useQueryResult
}
