import { GraphQLClient } from 'graphql-request'
import { useUpdateUserPasswordMutation as gqlUseUpdateUserPasswordMutation } from '@graphql-hooks'

export function useUpdateUserPasswordMutation(
  endpoint: string,
): ReturnType<typeof gqlUseUpdateUserPasswordMutation> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const updateUser = gqlUseUpdateUserPasswordMutation(client)

  return updateUser
}
