import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import type { UserQuery } from '@graphql-hooks'
import {
  useAddUserRoleMutation as gqlUseAddUserRoleMutation,
  UserStatus,
} from '@graphql-hooks'

export function useAddUserRoleMutation({
  endpoint,
}: {
  endpoint: string
}): ReturnType<
  typeof gqlUseAddUserRoleMutation<
    unknown,
    {
      originalUser: UserQuery['user'] | undefined
    }
  >
> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()

  const addUserRole = gqlUseAddUserRoleMutation<
    unknown,
    {
      originalUser: UserQuery['user'] | undefined
    }
  >(client, {
    onMutate: async ({ input }) => {
      const { userId } = input

      await queryClient.cancelQueries([
        'user',
        {
          id: userId,
          statuses: [UserStatus.Active, UserStatus.Inactive],
        },
      ])

      const userQueryData = queryClient.getQueryData<UserQuery>([
        'user',
        {
          id: input.userId,
          statuses: [UserStatus.Active, UserStatus.Inactive],
        },
      ])

      return { originalUser: userQueryData?.user }
    },
    onError: (_, { input }, context) => {
      queryClient.setQueryData(
        [
          'user',
          {
            id: input.userId,
            statuses: [UserStatus.Active, UserStatus.Inactive],
          },
        ],
        context?.originalUser,
      )
    },
    onSettled: async (_, __, { input }) => {
      await queryClient.invalidateQueries([
        'user',
        {
          id: input.userId,
          statuses: [UserStatus.Active, UserStatus.Inactive],
        },
      ])
    },
  })

  return addUserRole
}
