import { useQueryClient } from '@tanstack/react-query'
import { GraphQLClient } from 'graphql-request'
import type { PermissionsQuery, RoleQuery } from '@graphql-hooks'
import { useAddRoleMutation as gqlUseAddRoleMutation } from '@graphql-hooks'

export function useAddRoleMutation({
  endpoint,
}: {
  endpoint: string
}): ReturnType<
  typeof gqlUseAddRoleMutation<
    unknown,
    {
      newRole: RoleQuery['role'] | undefined
    }
  >
> {
  const client = new GraphQLClient(endpoint, { headers: {} })
  const queryClient = useQueryClient()
  const updateRole = gqlUseAddRoleMutation<
    unknown,
    {
      newRole: RoleQuery['role'] | undefined
    }
  >(client, {
    onMutate: async ({ role }) => {
      await queryClient.cancelQueries(['Roles'])

      //Retrieve the cached version of all permissions.
      const permissionsQueryData = queryClient.getQueryData<PermissionsQuery>([
        'permissions',
      ])

      const newRole = {
        id: '-1', //Use dummy role id, I guess.
        name: role.name,
        rolePermissions:
          role.permissions?.map((rolePerm) => {
            return {
              permission: {
                id: parseInt(rolePerm.permissionId),
                name:
                  permissionsQueryData?.listOfPermissions.find(
                    (perm) => perm.id === parseInt(rolePerm.permissionId),
                  )?.name ?? '',
              },
              defaultTab: rolePerm.defaultTab,
            }
          }) ?? [],
        permissions:
          role.permissions?.map((permission) => {
            return {
              id: parseInt(permission.permissionId),
              name:
                permissionsQueryData?.listOfPermissions.find(
                  (perm) => perm.id === parseInt(permission.permissionId),
                )?.name ?? '',
            }
          }) ?? [],
      }

      return { newRole }
    },
    onError: (_, __, ___) => undefined,
    onSettled: async (_, __, ___) => {
      await queryClient.invalidateQueries(['Roles'])
    },
  })

  return updateRole
}
