'use client'

import type { MutableRefObject } from 'react'
import { useEffect, useRef, useCallback } from 'react'

/**
 *  useOutsideClick hook
 * Checks for click events outside a referenced HTML element
 * @param ref - A mutable ref object (see https://reactjs.org/docs/hooks-reference.html#useref)
 * @param handler - A callback to trigger when clicking outside the ref object
 * @param enabled - A boolean toggle that enables or disables the 'useOutsideClick' hook
 */
const useOutsideClick = (
  ref: MutableRefObject<HTMLElement | null>,
  handler: (e: MouseEvent | TouchEvent) => void,
  enabled = true,
): void => {
  const savedHandler = useRef(handler)

  const memoizedCallback = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(e.target as Element)) {
        savedHandler.current(e)
      }
    },
    [ref],
  )

  useEffect(() => {
    savedHandler.current = handler
  })

  useEffect(() => {
    if (enabled) {
      document.addEventListener('click', memoizedCallback)
      // document.addEventListener('ontouchstart', memoizedCallback)
      return () => {
        document.removeEventListener('click', memoizedCallback)
        // document.removeEventListener('ontouchstart', memoizedCallback)
      }
    }
  }, [ref, handler, enabled, memoizedCallback])
}

export { useOutsideClick }
